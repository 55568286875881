import { useState, useEffect, useRef } from 'react'
import { Box } from '@mui/material'

import Hero from './Hero'
import Benefits from './Benefits'
import Highlights from './Highlights'
import Eye from './Eye'

const ROOT_MARGIN = '-100px'

const Home = () => {
  const ref = useRef(null)
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        return setIntersecting(entry.isIntersecting)
      },
      { rootMargin: ROOT_MARGIN },
    )

    observer.observe(ref.current)
    return () => observer.disconnect()
  }, [ref])

  return (
    <Box component='main' aria-label='home'>
      <Hero ref={ref} />
      <Highlights />
      <Benefits />
      <Eye isVisible={!isIntersecting} />
    </Box>
  )
}

export default Home
