import PropTypes from 'prop-types'
import { CardActionArea } from '@mui/material'

import * as PT from '../../propTypes'
import VerticalCard from './VerticalCard'
import HorizontalCard from './HorizontalCard'
import styles from './articleCard.styles'

const articleCardPropTypes = {
  orientation: PropTypes.oneOf(Object.values(PT.ARTICLE_ORIENTATION)),
  withoutImage: PropTypes.bool,
  withCategory: PropTypes.bool,
  withDescription: PropTypes.bool,
  isHighlighted: PropTypes.bool,
  article: PT.Article,
  isSkeleton: PropTypes.bool,
  isLoadingDetail: PropTypes.bool,
  onClick: PropTypes.func,
}

const withActionableArea = (WrappedComponent) => (props) => {
  if (!Boolean(props.onClick)) return <WrappedComponent {...props} />
  return (
    <CardActionArea
      onClick={() => props.onClick(props.article?.url)}
      sx={styles.cardActionArea}
    >
      <WrappedComponent {...props} />
    </CardActionArea>
  )
}

const ArticleCard = ({
  orientation = PT.ARTICLE_ORIENTATION.HORIZONTAL,
  ...restProps
}) => {
  const CardComponent =
    orientation === PT.ARTICLE_ORIENTATION.VERTICAL
      ? VerticalCard
      : HorizontalCard

  return <CardComponent {...restProps} />
}

ArticleCard.propTypes = articleCardPropTypes

export default withActionableArea(ArticleCard)
