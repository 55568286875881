import PropTypes from 'prop-types'
import { Box, Typography, Paper } from '@mui/material'

import { useTexts } from '../../../../texts'
import { OnboardingLayout } from '../../../components'
import styles from './onboardingAccess.styles'

const onboardingLayoutViewPropTypes = {
  children: PropTypes.node,
}

const OnboardingLayoutView = ({ children }) => {
  const texts = useTexts()

  const BULLET_ITEMS = [
    {
      icon: texts.onboardingStep4Bullet1Icon(),
      text: texts.onboardingStep4Bullet1Text(),
    },
    {
      icon: texts.onboardingStep4Bullet2Icon(),
      text: texts.onboardingStep4Bullet2Text(),
    },
    {
      icon: texts.onboardingStep4Bullet3Icon(),
      text: texts.onboardingStep4Bullet3Text(),
    },
  ]
  // const COMMENTS = [
  //   {
  //     text: texts.onboardingStep4Comment1Text(),
  //     author: texts.onboardingStep4Comment1Author(),
  //   },
  //   {
  //     text: texts.onboardingStep4Comment2Text(),
  //     author: texts.onboardingStep4Comment2Author(),
  //   },
  // ]

  return (
    <OnboardingLayout
      title={texts.onboardingStep4Title()}
      renderLeftSectionContent={() => (
        <>
          <Typography variant='body2' sx={styles.description}>
            {texts.onboardingStep4Description()}
          </Typography>

          <Box component='ul' sx={styles.checkList}>
            {BULLET_ITEMS.map((item, i) => (
              <Box key={i} component='li'>
                <Box key={i} aria-hidden>
                  <Typography sx={styles.bulletIcon}>{item.icon}</Typography>
                </Box>
                <Typography
                  variant='body2'
                  dangerouslySetInnerHTML={{
                    __html: item.text,
                  }}
                ></Typography>
              </Box>
            ))}
          </Box>
          {/* <Box sx={styles.separator} />
          <Box sx={styles.comments}>
            {COMMENTS.map((comment, i) => (
              <Box key={i}>
                <Typography variant='body2' sx={styles.comments_itemText}>
                  {comment.text}
                </Typography>
                <Typography variant='body2' sx={styles.comments_itemAuthor}>
                  {comment.author}
                </Typography>
              </Box>
            ))}
          </Box> */}
        </>
      )}
      renderRightSectionContent={() => (
        <Paper sx={styles.formContainer}>{children}</Paper>
      )}
    />
  )
}

OnboardingLayoutView.propTypes = onboardingLayoutViewPropTypes

export default OnboardingLayoutView
