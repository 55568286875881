const styles = {
  wrapFields: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 1,
  },
  groupFields: {
    display: 'grid',
    rowGap: 0.25,

    '& > .MuiFormControl-root:nth-of-type(1)': {
      '& .MuiInputBase-root': {
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
      },
    },
    '& > .MuiFormControl-root:nth-of-type(2)': {
      '& .MuiInputBase-root': {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      },
    },
  },
  groupErrors: {
    display: 'flex',
    alignItems: 'center',
    columnGap: (theme) => theme.spacing(0.625),
    marginTop: (theme) => theme.spacing(0.5),
    marginLeft: (theme) => theme.spacing(0.75),
    marginRight: (theme) => theme.spacing(0.75),
    fontSize: (theme) => theme.spacing(0.75),
    fontWeight: 500,
    color: 'error.main',

    '& > svg': {
      fontSize: (theme) => theme.spacing(1.25),
    },
  },
  separator: {
    display: 'flex',
    alignSelf: 'center',
  },
  collapsible: {
    display: 'flex',
    alignItems: 'center',
    columnGap: (theme) => theme.spacing(0.5),
  },
  collapsibleButton: {
    flex: (theme) => `0 0 ${theme.spacing(2.5)}`,
    height: (theme) => theme.spacing(2.5),
    color: 'neutral.700',
  },
  secondaryAction: {
    display: 'flex',
    justifyContent: 'center',
  },
  cta: {
    my: {
      xs: 1,
      md: 1,
      lg: 1.25,
    },
  },
}

export default styles
