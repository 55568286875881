const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    py: {
      xs: 0.625,
      md: 1,
    },
    px: {
      xs: 1,
      md: 1.5,
      lg: 2,
    },
    background: 'transparent',
    boxShadow: 0,
    backdropFilter: 'blur(10px)',
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  logo: {
    display: 'flex',
    width: (theme) => ({
      xs: theme.spacing(3.75),
      sm: 'auto',
    }),
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: {
      xs: 0.25,
      md: 1,
    },
    color: 'neutral.100',
  },
  button: {
    py: {
      xs: 0.5,
      md: 0.625,
    },
    px: {
      xs: 0.75,
      md: 2,
    },
    borderRadius: (theme) => theme.spacing(0.5),
    fontSize: (theme) => ({
      xs: theme.spacing(0.875),
      md: theme.spacing(1),
    }),
    whiteSpace: 'nowrap',
  },
  track: {
    width: '100%',
    height: (theme) => theme.spacing(0.25),
    position: 'absolute',
    left: 0,
    bottom: (theme) => theme.spacing(-0.25),
    backgroundColor: 'neutral.700',
    transform: 'translateX(var(--scrollPosition, -100%))',
    transition: 'transform 200ms linear',
  },
}

export default styles
