import PropTypes from 'prop-types'
import { Typography, CircularProgress, InputAdornment } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

import {
  Alert,
  FormSkinWrapper,
  TextField,
  Decorator,
} from '../../../../components'
import { EnvelopeSimple } from '../../../../../styleguide/icons'

import SocialLoginButtons from '../../SocialLoginButtons'
import { useTexts } from '../../../../../texts'
import styles from '../access.styles'

const emailStepViewPropTypes = {
  formId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  formError: PropTypes.string.isRequired,
  fieldErrors: PropTypes.object.isRequired,
  onClickGoogle: PropTypes.func.isRequired,
  onClickApple: PropTypes.func.isRequired,
  withoutSocialLogin: PropTypes.bool,
}

const EmailStepView = ({
  formId,
  onSubmit,
  isSubmitting,
  formError,
  fieldErrors,
  onClickGoogle,
  onClickApple,
  withoutSocialLogin,
}) => {
  const texts = useTexts()

  return (
    <>
      <Typography id='dialogTitle' sx={styles.heading}>
        {texts.getAccessTitle()}
      </Typography>
      {!Boolean(withoutSocialLogin) && (
        <>
          <SocialLoginButtons
            onClickGoogle={onClickGoogle}
            onClickApple={onClickApple}
          />
          <Decorator />
        </>
      )}

      <form id={formId} onSubmit={onSubmit} noValidate>
        <FormSkinWrapper skin='light' sx={styles.form}>
          <TextField
            id='email'
            name='email'
            label={texts.getLoginFormNameLabel()}
            type='email'
            autoComplete='email'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <EnvelopeSimple />
                </InputAdornment>
              ),
            }}
            helperText={fieldErrors.email}
            error={Boolean(fieldErrors.email)}
            inputProps={{ inputMode: 'email' }}
            required
          />
          {Boolean(formError) ? (
            <Alert variant='error' text={formError} />
          ) : null}
          <LoadingButton
            color='secondary'
            type='submit'
            fullWidth
            loading={isSubmitting}
            disabled={isSubmitting}
            loadingIndicator={<CircularProgress size={16} />}
            sx={styles.ctaBicolorLabel}
          >
            {texts.getContinueLabel()}
          </LoadingButton>
        </FormSkinWrapper>
      </form>
    </>
  )
}

EmailStepView.propTypes = emailStepViewPropTypes

export default EmailStepView
