import { Menu as MuiMenu } from '@mui/material'

const MenuContainer = ({ anchorEl, open, handleClose, children }) => {
  return (
    <MuiMenu
      id='user-menu'
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'user-button',
      }}
    >
      {children}
    </MuiMenu>
  )
}

export default MenuContainer
