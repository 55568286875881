import PropTypes from 'prop-types'
import { Card, Box, Typography, Skeleton } from '@mui/material'

import * as PT from '../../propTypes'
import styles from './articleCard.styles'
import PublishingDetail from './PublishingDetail'
import Category from './Category'
import GoToArticleFakeButton from './GoToArticleFakeButton'

const verticalCardPropTypes = {
  withoutImage: PropTypes.bool,
  withCategory: PropTypes.bool,
  withDescription: PropTypes.bool,
  article: PT.Article,
  isSkeleton: PropTypes.bool,
  isLoadingDetail: PropTypes.bool,
  onClick: PropTypes.func,
}

const VerticalCard = ({
  withoutImage,
  withCategory,
  withDescription,
  isSkeleton,
  isLoadingDetail,
  article,
  onClick,
}) => {
  return (
    <Card
      variant='plain'
      sx={{
        ...styles.card,
        ...styles.verticalCard,
      }}
    >
      {withoutImage || (!Boolean(article?.image) && !isSkeleton) ? null : (
        <Box sx={styles.highlightedImg}>
          {isSkeleton || isLoadingDetail ? (
            <Skeleton
              variant='rectangular'
              height={styles.highlightedImg.height}
            />
          ) : (
            <img src={article?.image} alt='Imagen del artículo' />
          )}
        </Box>
      )}

      {withCategory && (
        <Category
          category={article?.category}
          isLoading={isSkeleton || isLoadingDetail}
        />
      )}
      {isSkeleton ? (
        <Typography variant='title2'>
          <Skeleton height={56} />
        </Typography>
      ) : (
        <Typography variant='title2' component='h6' sx={styles.title}>
          {article?.title}
        </Typography>
      )}
      {!withDescription ? null : isLoadingDetail || isSkeleton ? (
        <Typography variant='title4'>
          <Skeleton height={44} />
        </Typography>
      ) : article?.description ? (
        <Typography variant='title4' component='p' sx={styles.description}>
          {article?.description}
        </Typography>
      ) : null}
      {!isSkeleton && Boolean(onClick) && <GoToArticleFakeButton />}

      <PublishingDetail
        article={article}
        isLoading={isSkeleton || isLoadingDetail}
      />
    </Card>
  )
}

VerticalCard.propTypes = verticalCardPropTypes

export default VerticalCard
