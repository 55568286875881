import { Box, Typography, Button } from '@mui/material'
import { useMediaQuery } from '@mui/material'

import theme from '../../../../theme'
import * as PT from '../../../propTypes'
import { ArticlesSkeleton, ArticleCardV2, Decorator } from '../../../components'
import { useTexts } from '../../../../texts'
import styles from './section.styles'

const getIsLastElem = (arr, i) => i === arr.length - 1

const SectionView = ({
  sectionId,
  sectionTitle,
  isFetching,
  articles,
  error,
  fetchNewPage,
  onClickArticle,
}) => {
  const widerThanTablet = useMediaQuery(theme.breakpoints.up('md'))
  const texts = useTexts()

  return (
    <Box sx={styles.root}>
      <Typography variant='h6' sx={styles.title}>
        {sectionTitle}
      </Typography>
      <Box>
        {articles.map((article, articleIndex, arr) => (
          <Box key={article.articleId}>
            <ArticleCardV2
              article={article}
              onClick={() => onClickArticle(article)}
            />
            {!getIsLastElem(arr, articleIndex) && <Decorator fullWidth />}
          </Box>
        ))}
      </Box>

      {isFetching && (
        <ArticlesSkeleton
          withCategory
          withDescription={widerThanTablet ? true : false}
          orientation={PT.ARTICLE_ORIENTATION.HORIZONTAL}
          isHighlighted={widerThanTablet ? true : false}
          numberOfArticles={widerThanTablet ? 2 : 3}
        />
      )}
      {error && <Typography>Algo ha ido mal</Typography>}

      <Box sx={styles.button}>
        <Button
          id={`show-more-${sectionId}`}
          size='small'
          color='primary'
          onClick={fetchNewPage}
          sx={styles.button}
        >
          {texts.getShowMoreLabel()}
        </Button>
      </Box>
    </Box>
  )
}

export default SectionView
