import { useForm, composeValidators, validators } from '../../../../forms'
import { useTexts } from '../../../../texts'
import { User } from '../../../../business'
import { isValidPassword } from '../../../../business/validate'

const useUpdatePasswordForm = (formId) => {
  const texts = useTexts()

  const FORM_VALIDATION_CONFIG = {
    oldPassword: composeValidators([
      [validators.isFilled, texts.getErrorRequired()],
      [isValidPassword, texts.getErrorPassword()],
    ]),
    newPassword: composeValidators([
      [validators.isFilled, texts.getErrorRequired()],
      [isValidPassword, texts.getErrorPassword()],
    ]),
  }

  const submitUpdatePassword = async (formValues) => {
    try {
      await User.changePassword(formValues)
    } catch (error) {
      if (error instanceof User.InvalidOldPasswordError) {
        throw new Error(texts.getUpdatePersDataInvalidOldPasswordError())
      }
      throw new Error(texts.getUnexpectedError())
    }
  }

  return useForm(formId, submitUpdatePassword, FORM_VALIDATION_CONFIG)
}

export default useUpdatePasswordForm
