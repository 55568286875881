import { BankCardChange } from '../../components'
import { useTexts } from '../../../texts'

const BankCardIntroduction = () => {
  const texts = useTexts()

  return (
    <BankCardChange
      title={texts.getIntroBankCardTitleLabel()}
      successTitle={texts.getIntroBankCardSuccessTitleLabel()}
      successSubtitle={texts.getIntroBankCardSuccessMessageLabel()}
    />
  )
}

export default BankCardIntroduction
