import { Outlet, useLocation } from 'react-router-dom'
import { Box } from '@mui/material'

import { ProductFruits } from '../../../thirdPartyIntegration'
import { useTexts } from '../../../texts'
import ScrollRevisionOnPageChange from '../../ScrollRevisionOnPageChange'
// import { CookiesLayer } from '../../components'
import { Session, User, Public } from '../../../business'

import Header from './Header'
import Breadcrumbs from './Breadcrumbs'
import Footer from './Footer'
import styles from './private.styles'

const PrivateLayout = ({ children }) => {
  const texts = useTexts()
  const location = useLocation()

  const BREADCRUMBS_LABEL_BY_PATH = {
    account: texts.account(),
    balance: texts.getBalanceLabel(),
    'top-up-balance': texts.getBreadcrumbsTopUpBalanceLabel(),
    'bank-card-edition': texts.getBreadcrumbsBankCardEditionLabel(),
    'bank-card-introduction': texts.getBreadcrumbsBankCardIntroLabel(),
    'update-password': texts.getUpdatePasswordAction(),
    'update-personal-data': texts.updatePersonalData(),
    purchases: texts.getPurchasesLabel(),
    section: texts.getSectionsTitle(),
  }

  const breadcrumbKey = Object.keys(BREADCRUMBS_LABEL_BY_PATH).find((pathKey) =>
    location.pathname.includes(pathKey),
  )

  const breadcrumbsLabel = breadcrumbKey
    ? BREADCRUMBS_LABEL_BY_PATH[breadcrumbKey]
    : undefined

  return (
    <>
      <ProductFruits.Component
        {...ProductFruits.getConfig(Session.getUserSub())}
      />
      <ScrollRevisionOnPageChange>
        <Public.ArticlesSectionsProvider>
          <Public.MediaProvsProvider>
            <User.PersonalDataProvider>
              <User.BalanceProvider>
                <User.BankCardProvider>
                  <Header />
                  <Box component='main' aria-label='dashboard' sx={styles.root}>
                    <Box sx={styles.mainContent}>
                      {Boolean(breadcrumbsLabel) && (
                        <Breadcrumbs currentPage={breadcrumbsLabel} />
                      )}

                      <Outlet />
                      {children}
                    </Box>
                  </Box>

                  <Footer />
                  {/* <CookiesLayer /> */}
                </User.BankCardProvider>
              </User.BalanceProvider>
            </User.PersonalDataProvider>
          </Public.MediaProvsProvider>
        </Public.ArticlesSectionsProvider>
      </ScrollRevisionOnPageChange>
    </>
  )
}

export default PrivateLayout
