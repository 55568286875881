import * as Sentry from '@sentry/react'

/**
 * Documentation: https://docs.sentry.io/platforms/javascript/guides/react
 */
export const configure = () => {
  Sentry.init({
    environment: process.env.REACT_APP_ENVIRONMENT,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: 'payper@' + process.env.REACT_APP_VERSION,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    // If tracePropagationTargets is not provided, trace data is attached to every outgoing request from the instrumented client.
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/dev.bepayper\.com/,
      /^https:\/\/pre.bepayper\.com/,
      /^https:\/\/dev-pro.bepayper\.com/,
      /^https:\/\/stg.bepayper\.com/,
      /^https:\/\/app.bepayper\.com/,
      /^https:\/api.bepayper\.com/,
      /^https:\/\/sis-t\.redsys\.es/,
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })

  Sentry.setTag('artifact', 'web')
}
