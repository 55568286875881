import PropTypes from 'prop-types'

import { useTexts } from '../../../../texts'
import Response from './Response'

const successViewProptypes = {
  onClickNewContact: PropTypes.func,
}

const SuccessView = ({ onClickNewContact }) => {
  const texts = useTexts()

  return (
    <Response
      title={texts.getContactFormSuccessTitleLabel()}
      message={texts.getContactFormSuccessDescriptionLabel()}
      ctaText={texts.getContactFormSendSuccessAction()}
      onClickCta={onClickNewContact}
    />
  )
}

SuccessView.propTypes = successViewProptypes

export default SuccessView
