import { useLocation, Navigate } from 'react-router-dom'

import { Session } from '../business'

const RequireNotAuthenticated = ({ children }) => {
  let location = useLocation()

  if (Session.isAuthenticated()) {
    return <Navigate to='/dashboard' state={{ from: location }} replace />
  }

  return children
}

export default RequireNotAuthenticated
