import { createContext, useContext, useState, useEffect } from 'react'

import { getPersonalData as getPersonalDataService } from './service'

const PersonalDataContext = createContext(null)

export const usePersonalData = () => {
  const context = useContext(PersonalDataContext)
  if (context === null) {
    throw new Error(
      'usePersonalData must be used within a PersonalDataProvider',
    )
  }
  return context
}

const initialState = {
  isLoading: true,
  data: undefined,
  error: false,
}

const PersonalDataProvider = ({ children }) => {
  const [state, setState] = useState(initialState)

  const getPersonalData = async () => {
    try {
      const personalData = await getPersonalDataService()
      setState({
        isLoading: false,
        data: personalData,
        error: false,
      })
    } catch (error) {
      setState({ isLoading: false, data: null, error })
    }
  }

  const optimisticUpdate = (newData) => {
    setState({
      isLoading: false,
      data: newData,
      error: false,
    })
  }

  useEffect(() => {
    ;(async () => await getPersonalData())()
  }, [])

  return (
    <PersonalDataContext.Provider value={{ state, optimisticUpdate }}>
      {children}
    </PersonalDataContext.Provider>
  )
}

export default PersonalDataProvider
