import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'

import { PayperLogoSymbol } from '../../../styleguide/icons'
import styles from './onboardingLayout.styles'

const onboardingLayoutPropTypes = {
  avatar: PropTypes.shape({
    image: PropTypes.string.isRequired,
    caption: PropTypes.string,
  }),
  title: PropTypes.string.isRequired,
  renderLeftSectionContent: PropTypes.func,
  renderRightSectionContent: PropTypes.func,
}

const OnboardingLayout = ({
  avatar,
  title,
  renderLeftSectionContent,
  renderRightSectionContent,
}) => {
  return (
    <Box
      component='main'
      aria-label='chrome-extension-onboarding'
      sx={styles.root}
    >
      <Box sx={styles.leftSection}>
        <Box>
          <Box sx={styles.leftSection_payperLogo}>
            <PayperLogoSymbol />
          </Box>
          {!!avatar && (
            <Box sx={styles.leftSection_avatar}>
              <Box>
                <img src={avatar.image} alt='avatar' />
              </Box>
              {!!avatar?.caption && (
                <Typography variant='body1'>{avatar.caption}</Typography>
              )}
            </Box>
          )}
          <Box>
            <Typography variant='h3'>{title}</Typography>
            <Box sx={styles.leftSection_titleUnderline} />
            {renderLeftSectionContent()}
          </Box>
        </Box>
      </Box>
      <Box sx={styles.rightSection}>{renderRightSectionContent()}</Box>
    </Box>
  )
}

OnboardingLayout.propTypes = onboardingLayoutPropTypes

export default OnboardingLayout
