import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Box, Button } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { useTexts } from '../../../../texts'
import { ReactComponent as CaretLeft } from './CaretLeft.svg'
import { ReactComponent as CaretRight } from './CaretRight.svg'
import styles from './breadcrumbs.styles'

const breadcrumbsPropTypes = {
  currentPage: PropTypes.string.isRequired,
}

const Breadcrumbs = ({ currentPage }) => {
  const theme = useTheme()
  const widerThanTablet = useMediaQuery(theme.breakpoints.up('md'))
  const texts = useTexts()

  return (
    <Box sx={styles.root}>
      <Box component='nav' sx={styles.content}>
        <Box component='ol' sx={styles.list}>
          {widerThanTablet ? (
            <>
              <Box component='li' sx={{ ...styles.item, ...styles.active }}>
                <Button sx={styles.link} component={Link} to='/dashboard'>
                  {texts.getBreadcrumbsHomeLabel()}
                </Button>
              </Box>
              <Box component='li' sx={styles.decorator} aria-hidden='true'>
                <CaretRight />
              </Box>
            </>
          ) : (
            <Box component='li' sx={styles.icon}>
              <Button sx={styles.iconLink} component={Link} to='/dashboard'>
                <CaretLeft />
              </Button>
            </Box>
          )}

          <Box component='li' sx={styles.item}>
            {currentPage}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

Breadcrumbs.propTypes = breadcrumbsPropTypes

export default Breadcrumbs
