const styles = {
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignSelf: 'center',
    width: (theme) => `min(100%, ${theme.spacing(19.5)})`,
    minHeight: (theme) => theme.spacing(12.5),
    borderRadius: (theme) => theme.spacing(1.25),
    boxShadow: 1,
  },
  mainData: {
    p: 1.5,
    pb: 1,
    flexGrow: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  bold: {
    fontSize: (theme) => theme.spacing(0.875),
    fontWeight: 600,
    color: 'neutral.500',
  },
  caption: {
    display: 'flex',
    justifyContent: 'flex-start',
    columnGap: 0.2,
    px: 1.5,
    pb: 1.5,
    fontWeight: 500,
    color: 'neutral.400',

    '& > svg': {
      fontSize: (theme) => theme.spacing(1.2),
      flexShrink: 0,
    },
    '& > p': {
      fontSize: (theme) => theme.spacing(0.75),
    },
  },
  cta: {
    display: 'flex',
    justifyContent: 'center',
    columnGap: 0.5,
    borderRadius: (theme) => theme.spacing(0, 0, 1.25, 1.25),

    '& > .MuiButton-startIcon': {
      mr: 0,
    },
  },
}

export default styles
