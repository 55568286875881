import { useMemo } from 'react'

import { useForm, composeValidators, validators } from '../../forms'
import { useTexts } from '../../texts'

import { isValidPassword } from '../validate'

import { UsernameAlreadyExistsError } from './errors'
import { register } from './service'

const CHECKBOX_FALSY_VALUE = undefined
const CHECKBOX_TRUTHY_VALUE = 'on'
const isCheckboxChecked = (value) => value === CHECKBOX_TRUTHY_VALUE

export const useUserDataForm = (formId, convertionSourceData = {}) => {
  const texts = useTexts()

  const { pipeValidators, isValidEmail, isChecked, isFilled, hasOnlyDigits } =
    validators

  const FORM_VALIDATION_CONFIG = {
    name: composeValidators([[isFilled, texts.getErrorRequired()]]),
    familyName: composeValidators([[isFilled, texts.getErrorRequired()]]),
    email: composeValidators([
      [isFilled, texts.getErrorRequired()],
      [isValidEmail, texts.getErrorEmail()],
    ]),
    phone: composeValidators([
      [
        pipeValidators(
          (v) => !isFilled(v) || hasOnlyDigits(v),
          (v) => !isFilled(v) || v.length === 9,
        ),
        texts.getErrorPhone(),
      ],
    ]),
    password: composeValidators([
      [isFilled, texts.getErrorRequired()],
      [isValidPassword, texts.getErrorPassword()],
    ]),
    conditionsAccepted: composeValidators([
      [isChecked, texts.getErrorRequired()],
    ]),
  }

  const filterEmptyStringValues = (formValues) => {
    let filteredValues = { ...formValues }
    for (const [key, value] of Object.entries(formValues)) {
      if (value === '') {
        delete filteredValues[key]
      }
    }
    return filteredValues
  }

  const formatFormValues = (formValues) => ({
    ...filterEmptyStringValues(formValues),
    conditionsAccepted: isCheckboxChecked(formValues?.conditionsAccepted),
    communicationsAccepted: isCheckboxChecked(
      formValues?.communicationsAccepted,
    ),
  })

  const submitRegisterData = async (formValues) => {
    try {
      const sub = await register(formValues, convertionSourceData)
      return { email: formValues.email, sub }
    } catch (error) {
      if (error instanceof UsernameAlreadyExistsError) {
        throw new Error(texts.getRegisterUserAlreadyExistsError())
      }
      throw new Error(texts.getUnexpectedError())
    }
  }

  const submitFn = (formValues) =>
    submitRegisterData(formatFormValues(formValues))

  const useFormData = useForm(formId, submitFn, FORM_VALIDATION_CONFIG)
  const { updateFieldForm } = useFormData

  const updateLegalCheckbox = (ev) => {
    updateFieldForm(
      ev.target.name,
      ev.target.checked ? CHECKBOX_TRUTHY_VALUE : CHECKBOX_FALSY_VALUE,
    )
  }

  return { ...useFormData, updateLegalCheckbox }
}

export const useUserDataFormWithAcceptAllLegalCTA = (...args) => {
  const useUserFormData = useUserDataForm(...args)

  const {
    status: { formValues, fieldErrors },
    updateFieldForm,
  } = useUserFormData

  const acceptAllLegalRequirements = () => {
    updateFieldForm('conditionsAccepted', CHECKBOX_TRUTHY_VALUE)
    updateFieldForm('communicationsAccepted', CHECKBOX_TRUTHY_VALUE)
  }

  const showsAcceptAllLegalCTA = useMemo(() => {
    const areConditionsAccepted = () =>
      isCheckboxChecked(formValues?.conditionsAccepted)
    const areCommunicationsAccepted = () =>
      isCheckboxChecked(formValues?.communicationsAccepted)
    const noErrorsHaveBeenRegistered = (errorsObj) =>
      JSON.stringify(errorsObj) === '{}'

    return (
      !areConditionsAccepted() &&
      !areCommunicationsAccepted() &&
      noErrorsHaveBeenRegistered(fieldErrors)
    )
  }, [
    formValues?.conditionsAccepted,
    formValues?.communicationsAccepted,
    fieldErrors,
  ])

  return {
    ...useUserFormData,
    showsAcceptAllLegalCTA,
    acceptAllLegalRequirements,
  }
}

export const useUserDataFormWithAutoAcceptAllLegalCTA = (...args) => {
  const texts = useTexts()

  const useUserFormData = useUserDataForm(...args)

  const {
    status: { formValues },
    submitForm: baseSubmitForm,
  } = useUserFormData

  const showsAcceptAllLegalCTA = false
  const acceptAllLegalRequirements = () => {}

  const noLegalCheckboxIsChecked = useMemo(() => {
    const areConditionsAccepted = () =>
      isCheckboxChecked(formValues?.conditionsAccepted)
    const areCommunicationsAccepted = () =>
      isCheckboxChecked(formValues?.communicationsAccepted)

    return !areConditionsAccepted() && !areCommunicationsAccepted()
  }, [formValues?.conditionsAccepted, formValues?.communicationsAccepted])

  const ctaLabel = noLegalCheckboxIsChecked
    ? texts.getRegisterAcceptAllAndContinueAction()
    : texts.getRegisterContinueAction()

  const getFormValuesWithCheckedLegalOpts = (formValues) => ({
    ...formValues,
    conditionsAccepted: CHECKBOX_TRUTHY_VALUE,
    communicationsAccepted: CHECKBOX_TRUTHY_VALUE,
  })

  const submitForm = (formValues) => {
    if (noLegalCheckboxIsChecked) {
      formValues = getFormValuesWithCheckedLegalOpts(formValues)
    }

    baseSubmitForm(formValues)
  }

  return {
    ...useUserFormData,
    showsAcceptAllLegalCTA,
    acceptAllLegalRequirements,
    ctaLabel,
    submitForm,
  }
}
