import { useRef, useEffect, useState } from 'react'
import { Box } from '@mui/material'

import { BankCardOrder } from '../../../business'
import CircularProgress from '../CircularProgress'

import { waitForIframeToPostMessage } from './redsysHelpers'
import { useTexts } from '../../../texts'

const Preauthorization = ({
  operationId,
  orderId,
  onPreauthorizationFinish,
  onError,
}) => {
  const texts = useTexts()
  const [form, setForm] = useState(null)
  const formRef = useRef(null)

  useEffect(() => {
    if (form !== null) {
      formRef.current.submit()
      return
    }

    ;(async () => {
      let preauthResult
      try {
        preauthResult = await BankCardOrder.requestPreauthorization(
          operationId,
          orderId,
        )
      } catch (error) {
        onError(texts.getErrorBankCardPreauth())
        return
      }

      if (preauthResult.threeDSMethodForm !== undefined) {
        // V1 METHOD
        setForm(preauthResult.threeDSMethodForm)

        const iframeResult = await waitForIframeToPostMessage(
          'notification-3ds-method',
          10000,
        )
        if (!iframeResult.success) {
          onError(iframeResult.message)
        }
      }

      onPreauthorizationFinish(preauthResult, operationId, orderId)
    })()
  })

  if (form === null) {
    return <CircularProgress />
  }

  return (
    <>
      <CircularProgress />
      <Box hidden>
        <iframe name='threeDSMethodIframe' title='threeDSMethodIframe' />
        <form
          ref={formRef}
          action={form.url}
          method='post'
          target='threeDSMethodIframe'
        >
          <input
            value={form.body.threeDSMethodData}
            name='threeDsMethodData'
            type='hidden'
          />
        </form>
      </Box>
    </>
  )
}

export default Preauthorization
