import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  Auth,
  Session,
  User,
  URL_SEARCH_PARAMS_AUTH_KEYS,
} from '../../../../business'
import { CircularProgress } from '../../../components'
import getConvertionParams from './getConvertionParams'

import useSSO from './useSSO'
import useSSOPartner from './useSSOPartner'

const isSSOFromMedia = (searchParams) =>
  Boolean(searchParams.get(URL_SEARCH_PARAMS_AUTH_KEYS.MEDIA_ID))
const isSSOFromPartner = (searchParams) =>
  Boolean(searchParams.get(URL_SEARCH_PARAMS_AUTH_KEYS.PARTNER_ID))

const SSOSuccessSocialLogin = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const sso = useSSO()
  const ssoPartner = useSSOPartner()

  useEffect(() => {
    ;(async () => {
      const urlSearchParams = new URLSearchParams(location.search)

      const code = urlSearchParams.get(
        URL_SEARCH_PARAMS_AUTH_KEYS.THIRD_PARTY_CODE,
      )
      urlSearchParams.delete(URL_SEARCH_PARAMS_AUTH_KEYS.THIRD_PARTY_CODE)

      const tokens = await Auth.getSSOJWTs(code)

      const { hasSystemUser } = await Auth.verifyCompleteRegistration(
        tokens.accessToken,
      )
      const isNewUser = !hasSystemUser

      Session.storeSession(tokens)

      if (isSSOFromMedia(urlSearchParams)) {
        if (isNewUser) {
          await Auth.thirdPartyIdpRegister(tokens, getConvertionParams())
        }
        if (sso.hasBonus()) {
          await User.createPayperBonus(sso.getBonusToken())
          sso.redirect({
            autoBuyOnRedirect: true,
            resetState: false,
          })
        } else if (isNewUser) {
          navigate(`/sso-3rd-party-idp-register?${urlSearchParams.toString()}`)
        } else {
          sso.redirect()
        }
      } else if (isSSOFromPartner(urlSearchParams)) {
        if (isNewUser) {
          await Auth.thirdPartyIdpRegister(tokens, getConvertionParams())
          if (ssoPartner.hasBonus()) {
            await User.createBonus(
              ssoPartner.getBonusToken(),
              ssoPartner.getPartnerId(),
            )
          }
        }

        ssoPartner.redirect()
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <CircularProgress height='80vh' />
}

export default SSOSuccessSocialLogin
