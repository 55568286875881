import { Dialog } from '@mui/material'

import { Modal } from '../../../components/Modal'
import EnterEmailStep from './EnterEmailStep'
import EnterCodeAndNewPasswordStep from './EnterCodeAndNewPasswordStep'

const ForgottenPasswordView = ({
  stepsLayoutProps,
  stepIndex,
  userEmail,
  onCancel,
  onGoToLogin,
  onContinueToSecondStep,
  onReturnToFirstStep,
  onResetPasswordSuccess,
}) => {
  return (
    <div aria-label='restablecer-contraseña'>
      <Dialog aria-labelledby='forgottenPassword' open>
        <Modal onCancel={onCancel} {...stepsLayoutProps}>
          {stepIndex === 0 ? (
            <EnterEmailStep
              emailDefaultValue={userEmail}
              onContinue={onContinueToSecondStep}
              onGoToLogin={onGoToLogin}
            />
          ) : (
            <EnterCodeAndNewPasswordStep
              email={userEmail}
              onClickResendCode={onReturnToFirstStep}
              onSuccess={onResetPasswordSuccess}
            />
          )}
        </Modal>
      </Dialog>
    </div>
  )
}

export default ForgottenPasswordView
