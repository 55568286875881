import theme from '../../../../theme'

const styles = {
  root: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      lg: 'row',
    },
    columnGap: {
      lg: 2,
      xl: 5,
      xxl: 8.75,
    },
    minHeight: '100vh',
    background:
      'linear-gradient(90deg, rgba(247,247,245,1) 50%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%)',
    overflow: 'hidden',
  },
  info: {
    flex: {
      lg: 1,
    },
    display: 'flex',
    justifyContent: {
      xs: 'center',
      lg: 'flex-end',
    },
    backgroundColor: 'neutral.200',
    zIndex: 2,
  },
  infoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: (theme) => `min(100%, ${theme.spacing(28.75)})`,
    pt: 7,
    px: 1.25,
  },
  infoHeader: {
    mb: 2,
    backgroundColor: 'neutral.200',
  },
  infoHeaderSmall: {
    p: 1.25,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 2,
  },
  infoHeaderContent: {
    width: (theme) => `min(100%, ${theme.spacing(28.75)})`,
    mx: 'auto',
  },
  withShadow: {
    '&::after': {
      content: '""',
      display: 'block',
      width: '100vw',
      height: 1,
      position: 'absolute',
      left: 'calc(50% - 50vw)',
      right: 0,
      bottom: 0,
      boxShadow: 2,
    },
  },
  infoImage: {
    display: 'block',
    width: 'auto',
    maxWidth: (theme) => `calc(100% - ${theme.spacing(4)})`,
    height: (theme) => ({
      xs: theme.spacing(1.75),
      lg: theme.spacing(2),
    }),
    mb: {
      xs: 0.7,
      lg: 2,
    },
    objectFit: 'contain',
  },
  infoSmall: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 0.25,
    fontSize: (theme) => theme.spacing(0.75),
    fontWeight: 500,
    lineHeight: 1.5,
    color: 'neutral.400',
  },
  closeButton: {
    position: {
      xs: 'absolute',
      md: 'absolute',
    },
    top: (theme) => theme.spacing(1.25),
    right: (theme) => ({
      xs: theme.spacing(1.25),
      lg: theme.spacing(2),
    }),

    '& .MuiIconButton-root': {
      p: 0.5,
      fontSize: (theme) => theme.spacing(1.5),
      color: 'neutral.500',
    },
  },
  infoHeading: {
    ...theme.typography.h4,
    color: 'neutral.700',
    mb: {
      xs: 1,
      lg: 1.5,
    },
  },
  infoList: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 0.125,
    mt: 0,
    pl: 0,
  },
  infoListItem: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 1,
    fontSize: (theme) => theme.spacing(1),
    fontWeight: 500,
    lineHeight: 1.5,
    color: 'neutral.600',
  },
  infoDescription: {
    mb: 2,
  },
  icon: {
    display: 'flex',
    fontSize: (theme) => theme.spacing(1),
  },
  access: {
    flex: 1,
    display: 'flex',
    justifyContent: {
      xs: 'center',
      lg: 'flex-start',
    },
    backgroundColor: 'neutral.100',

    // NOTE: These exceptions are needed since the components this layout renders
    // are also rendered within another component or context.
    '& > div > div': {
      minHeight: 'initial',
    },
    '& > div > div > div': {
      mt: 0,
    },
    '& [aria-labelledby="dialogTitle"]': {
      '--background': (theme) => theme.palette.neutral[100],
      overflow: 'initial',

      '& > div > div > div::before, & > div > div > div > div::before': {
        display: 'none',
      },
      '& > div > div > div, & > div > div > div > div': {
        backgroundColor: 'neutral.100',
      },
    },
  },
  accessWrapper: {
    width: (theme) => `min(100%, ${theme.spacing(28.75)})`,
    pt: {
      xs: 2.5,
      lg: 6.5,
    },
    pb: 2,
    px: 1.25,
  },
}

export default styles
