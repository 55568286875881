import {
  getRefreshToken,
  getIdToken,
  getAccessToken,
  logout,
  refreshSession,
} from './session'

export class InvalidTokenError extends Error {
  constructor(message) {
    super(message)
    this.name = 'InvalidTokenError'
  }
}

const buildHeaders = () => ({
  Identity: getIdToken(),
  Authorization: `Bearer ${getAccessToken()}`,
})

const fetchWithAuthorization = (fetchFn) =>
  async function () {
    try {
      return await fetchFn(buildHeaders(), ...arguments)
    } catch (error) {
      if (error instanceof InvalidTokenError) {
        const refreshToken = getRefreshToken()

        if (!refreshToken) {
          logout()
        } else {
          await refreshSession()
          return await fetchFn(buildHeaders(), ...arguments)
        }
      } else {
        throw error
      }
    }
  }

export default fetchWithAuthorization
