import { Box, Button, Typography, CircularProgress } from '@mui/material'
import { Link } from 'react-router-dom'

import { useTexts } from '../../../../texts'
import { BankCard } from '../../../components'
import ScrollToTop from '../../../Pages/useScrollToTop'
import { ReactComponent as ArrowLeft } from './ArrowLeft.svg'
import { ReactComponent as Coins } from './Coins.svg'
import styles from './successView.styles'

const View = ({ title, subtitle, bankCard }) => {
  const texts = useTexts()

  if (bankCard.isLoading) return <CircularProgress />
  return (
    <ScrollToTop>
      <Box sx={styles.root}>
        <Box sx={styles.content}>
          <Typography component='h1' sx={styles.title}>
            {title}
          </Typography>
          <Typography sx={styles.text}>{subtitle}</Typography>

          <Box sx={styles.wrapBankCard}>
            <BankCard bankCardData={bankCard.data} />
          </Box>

          <Box sx={styles.wrapActions}>
            <Button
              fullWidth
              color='secondary'
              startIcon={<Coins />}
              component={Link}
              to='/dashboard/top-up-balance'
              sx={styles.cta}
            >
              {texts.getBalanceTopUpActionLong()}
            </Button>
            <Button
              fullWidth
              startIcon={<ArrowLeft />}
              component={Link}
              to='/dashboard'
              sx={styles.altCta}
            >
              {texts.getGoToPurchasesList()}
            </Button>
          </Box>
        </Box>
      </Box>
    </ScrollToTop>
  )
}

export default View
