import PropTypes from 'prop-types'
import {
  Box,
  CircularProgress,
  Container,
  Typography,
  InputAdornment,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

import { useTexts } from '../../../../texts'
import { FormSkinWrapper, TextField } from '../../../components'
import { ReactComponent as ArrowRight } from './ArrowRight.svg'
import { ReactComponent as ChatTeardropText } from './ChatTeardropText.svg'

import styles from './footer.styles'

const contactFormProptypes = {
  formId: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool,
  fieldErrors: PropTypes.shape({
    name: PropTypes.string,
    familyName: PropTypes.string,
    email: PropTypes.string,
    message: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
}

const ContactFormView = ({ formId, isSubmitting, fieldErrors, onSubmit }) => {
  const texts = useTexts()

  return (
    <Box component='section' sx={{ ...styles.root, ...styles.dark }}>
      <Container>
        <Box sx={styles.content}>
          <Box sx={styles.info}>
            <Box sx={styles.wrapText}>
              <Typography variant='h2' sx={styles.heading}>
                {texts.getContactFormTitleLabel()}
              </Typography>
            </Box>
          </Box>
          <Box sx={styles.slot}>
            <form id={formId} onSubmit={onSubmit} noValidate>
              <FormSkinWrapper skin='dark' sx={styles.wrapFields}>
                <TextField
                  id='contactFormMessage'
                  name='message'
                  label={texts.getContactFormMessageLabel()}
                  multiline
                  rows={6}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <ChatTeardropText />
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(fieldErrors.message)}
                  helperText={fieldErrors.message}
                  required
                />
                <LoadingButton
                  type='submit'
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  endIcon={<ArrowRight />}
                  loadingIndicator={<CircularProgress size={16} />}
                >
                  {texts.getContactFormSendAction()}
                </LoadingButton>
              </FormSkinWrapper>
            </form>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

ContactFormView.propTypes = contactFormProptypes

export default ContactFormView
