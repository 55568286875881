import theme from '../../../../theme'

const styles = {
  root: {
    background:
      'linear-gradient(0deg, rgba(247,247,245,1) 30%, rgba(255,204,0,1) 30%, rgba(255,204,0,1) 100%)',

    '& > .MuiContainer-root': {
      pl: 0,
      pr: 0,
    },

    '& .isVisible': {
      '& > :is(li)': {
        opacity: 1,
        transform: 'none',
      },
    },
  },
  list: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3,1fr)',
    columnGap: 0.5,
    px: {
      xs: 1.25,
      xl: 4,
    },
    my: 0,
    listStyleType: 'none',
    overflowX: 'auto',

    [theme.mixins.hoverCondition()]: {
      pb: {
        xl: 4,
      },
    },
  },
  item: {
    minWidth: (theme) => ({
      xs: theme.spacing(18),
      md: theme.spacing(20),
      xl: theme.spacing(27),
    }),
    position: 'relative',

    [theme.mixins.hoverCondition()]: {
      '&:hover': {
        '& > div:nth-of-type(1)': {
          transform: 'scale(1.1)',
        },
        '& > div:nth-of-type(2)': {
          transform: 'translateY(-2rem)',
        },
      },
    },

    //
    transform: 'translateY(2rem)',
    opacity: 0,
    transition: 'transform 350ms ease-in, opacity 300ms ease-in',
    transitionDelay: 'var(--delay, 0)',
  },
  wrapPicture: {
    [theme.mixins.hoverCondition()]: {
      transformOrigin: 'top center',
      transition: 'transform 180ms ease-in',
    },
  },
  content: {
    pt: 2,
    px: {
      xs: 1.5,
      lg: 2,
    },
    pb: 2.5,
    borderRadius: (theme) => theme.spacing(0.5),
    backgroundColor: 'neutral.100',
    position: 'absolute',
    left: 0,
    right: 0,
    top: '50%',
    zIndex: 1,

    [theme.mixins.hoverCondition()]: {
      transition: 'transform 150ms ease-in 30ms',
    },
  },
  text: {
    fontSize: (theme) => ({
      xs: theme.spacing(1.25),
      xl: theme.spacing(1.5),
      xxl: theme.spacing(1.75),
    }),
    lineHeight: 1.3,
    fontWeight: 700,
    color: 'neutral.700',
  },
}

export default styles
