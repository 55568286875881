import { createContext, useContext, useState, useEffect } from 'react'

import {
  fetchBalance as fetchBalanceService,
  updateAccountBalance as updateBalanceService,
} from './service'

const BalanceContext = createContext(null)

export const useBalance = () => {
  const context = useContext(BalanceContext)
  if (context === null) {
    throw new Error('useBalance must be used within a BalanceProvider')
  }
  return context
}

const initialState = {
  isLoading: true,
  data: undefined,
  error: false,
}

const BalanceProvider = ({ children }) => {
  const [state, setState] = useState(initialState)

  const fetchBalance = async () => {
    try {
      const balance = await fetchBalanceService()
      setState({
        isLoading: false,
        data: balance,
        error: false,
      })
    } catch (error) {
      setState({ isLoading: false, data: null, error })
    }
  }

  const topUpBalance = async (amount) => {
    try {
      await updateBalanceService(amount)
      fetchBalance()
    } catch (error) {
      throw error
    }
  }

  useEffect(() => {
    ;(async () => await fetchBalance())()
  }, [])

  return (
    <BalanceContext.Provider value={{ state, topUpBalance }}>
      {children}
    </BalanceContext.Provider>
  )
}

export default BalanceProvider
