import View from './View'
import SuccessView from './SuccessView'
import useUpdatePasswordForm from './useUpdatePasswordForm'

const FORM_ID = 'update-password-form'

const UpdatePassword = () => {
  const { status, isSubmitSuccess, submitForm } = useUpdatePasswordForm(FORM_ID)

  const handleSubmit = async (ev) => {
    ev.preventDefault()
    const formData = new FormData(ev.target)
    const formValues = Object.fromEntries(formData)
    submitForm(formValues)
  }

  return (
    <>
      {isSubmitSuccess() ? (
        <SuccessView />
      ) : (
        <View formId={FORM_ID} onSubmit={handleSubmit} {...status} />
      )}
    </>
  )
}

export default UpdatePassword
