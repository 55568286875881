const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 1,
  },
  cta: {
    py: {
      xs: 1,
      md: 1,
      lg: 1.25,
    },
    px: 1.5,
  },
}

export default styles
