export const ASSOCIATED_MEDIAS_DATA = [
  {
    id: 'b4f9cc2f-92b1-4cb4-b888-3fdff5c0b90b',
    name: 'El Mundo',
    imageUrl:
      'https://s3.eu-west-1.amazonaws.com/www.bepayper.com/medios/el_mundo.svg',
    domain: 'https://www.elmundo.es/',
  },
  {
    id: '11610783-cecc-462f-ba69-68f066b9fa2f',
    name: 'El País',
    imageUrl:
      'https://s3.eu-west-1.amazonaws.com/www.bepayper.com/medios/el_pais.svg',
    domain: 'https://elpais.com',
  },
  {
    id: '92b378e2-74cf-4133-9df5-a7384cceca0b',
    name: 'La Vanguardia',
    imageUrl:
      'https://s3.eu-west-1.amazonaws.com/www.bepayper.com/medios/la-vanguardia-vector-logo.svg',
    domain: 'https://www.lavanguardia.com',
  },
  {
    id: 'a035d49f-bbf3-46e4-8595-64a1d7bdae28',
    name: 'El Español',
    imageUrl:
      'https://s3.eu-west-1.amazonaws.com/www.bepayper.com/medios/el_espanol.svg',
    domain: 'https://www.elespanol.com/',
  },
  {
    id: 'c137bedf-82af-4377-b740-8ae66e7db4bd',
    name: 'Cinco días',
    imageUrl:
      'https://s3.eu-west-1.amazonaws.com/www.bepayper.com/medios/cinco_dias.svg',
    domain: 'https://cincodias.elpais.com',
  },
  {
    id: 'f4355a7c-12b4-41d2-9f7d-85316ff5a427',
    name: 'Expansión',
    imageUrl:
      'https://s3.eu-west-1.amazonaws.com/www.bepayper.com/medios/expansion.svg',
    domain: 'https://www.expansion.com/'
  },
  {
    id: '5221211e-e070-46e1-a608-8af9913cf6b3',
    name: 'Consejeros',
    imageUrl:
      'https://s3.eu-west-1.amazonaws.com/www.bepayper.com/medios/consejeros.svg',
    domain: 'https://www.revistaconsejeros.com',
  },
]
