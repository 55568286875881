import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'

import { Account as AccountModule } from '../../modules'

const styles = {
  root: {
    px: 1,
  },
}

const AccountPage = () => {
  const navigate = useNavigate()

  const onClickTopUp = () => {
    navigate('/dashboard/top-up-balance')
  }
  const onClickEditBankCard = () => {
    navigate('/dashboard/bank-card-edition')
  }
  const onClickAddBankCard = () => {
    navigate('/dashboard/bank-card-introduction')
  }
  const onClickUpdatePersonalData = () => {
    navigate('/dashboard/update-personal-data')
  }
  const onClickUpdatePassword = () => {
    navigate('/dashboard/update-password')
  }

  return (
    <Box sx={styles.root}>
      <AccountModule
        onClickTopUp={onClickTopUp}
        onClickEditBankCard={onClickEditBankCard}
        onClickAddBankCard={onClickAddBankCard}
        onClickUpdatePersonalData={onClickUpdatePersonalData}
        onClickUpdatePassword={onClickUpdatePassword}
      />
    </Box>
  )
}

export default AccountPage
