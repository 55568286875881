import theme from '../../../../../theme'

const styles = {
  description: {
    ...theme.body2,
    color: 'neutral.500',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 2,
    pt: 1,
  },
  fieldsControl: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    rowGap: 0.5,
    py: 1.5,
    px: {
      xs: 0.5,
      md: 2,
    },
  },
  wrapFields: {
    display: 'inline-flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    columnGap: 0.5,

    '& > input': {
      width: (theme) => theme.spacing(2.25),
      maxWidth: (theme) => theme.spacing(2.25),
      height: (theme) => theme.spacing(2.5),
      px: 0.75,
      py: 0.625,
      fontSize: (theme) => theme.spacing(1),
      textAlign: 'center',
      border: 0,
      borderRadius: 1,
      color: 'neutral.500',
      backgroundColor: 'neutral.200',

      '&:hover': {
        boxShadow: 3,
        borderColor: 'neutral.300',
        backgroundColor: 'neutral.200',
      },
      '&:focus': {
        outline: 'none',
        backgroundColor: 'neutral.300',
      },

      '&.error': {
        color: 'error.main',
        backgroundColor: 'error.light',

        '&:hover': {
          borderColor: 'error.light',
          backgroundColor: 'error.light',
        },
      },
    },
  },
  errorMessage: {
    width: (theme) => theme.spacing(16),
    display: 'block',
    ml: 'auto',
    mr: 'auto',
    fontSize: (theme) => ({
      xs: theme.spacing(0.75),
      md: theme.spacing(0.75),
    }),
    color: 'error.main',
  },
  secondaryAction: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    rowGap: 0.5,
    mt: -0.5,
  },
}

export default styles
