import { useState } from 'react'

import { User } from '../../../../business'
import { useTexts } from '../../../../texts'
import View from './View'
import SuccessView from './SuccessView'

const TopUpBalance = () => {
  const texts = useTexts()

  const { state: bankCard } = User.useBankCard()
  const { topUpBalance } = User.useBalance()

  const [topUpAmount, setTopUpAmount] = useState(0)
  const [isToppingUp, setIsToppingUp] = useState(true)
  const [isRequesting, setIsRequesting] = useState(false)
  const [messageErrorOnRequest, setMessageErrorOnRequest] = useState('')

  const handleTopUpBalance = async (amount) => {
    setIsRequesting(true)
    try {
      await topUpBalance(amount)
      setIsToppingUp(false)
      setTopUpAmount(amount)
    } catch (error) {
      if (error instanceof User.RedsysError) {
        const errorMsg =
          User.getRedsysDSResponseMessageTranslation(error.message) ??
          texts.getBalanceTopUpErrorLabel()
        setMessageErrorOnRequest(errorMsg)
      } else {
        setMessageErrorOnRequest(texts.getBalanceTopUpErrorLabel())
      }
    }
    setIsRequesting(false)
  }

  return (
    <>
      {isToppingUp ? (
        <View
          bankCardData={bankCard?.data}
          onClickTopUp={handleTopUpBalance}
          isRequestingUpdate={isRequesting}
          messageErrorOnRequest={messageErrorOnRequest}
        />
      ) : (
        <SuccessView topUpAmount={topUpAmount} />
      )}
    </>
  )
}

export default TopUpBalance
