import { Box, Container, Typography } from '@mui/material'

import styles from './privacyPolicy.styles'

const Bold = ({ children }) => (
  <Box component='strong' sx={{ display: 'inline', fontWeight: 'bold' }}>
    {children}
  </Box>
)
const MailTo = ({ children }) => <a href={`mailto:${children}`}>{children}</a>

const PrivacyPolicy = () => {
  window.location = 'https://www.bepayper.com/politica-de-privacidad'

  return (
    <Box component='main'>
      <Container component='article' maxWidth='xl' sx={styles.container}>
        <Box component='header'>
          <Typography component='h1' variant='h3'>
            Política de privacidad
          </Typography>
        </Box>
        <Box component='section' id='company'>
          <Typography component='h2' variant='h4'>
            ¿Quién es el responsable del tratamiento de sus datos?{' '}
          </Typography>
          <Typography component='div'>
            El responsable del tratamiento de sus datos personales es MARTER
            878, S.L. (en adelante, “<Bold>PAYPER</Bold>”), con N.I.F.
            B-09762998, y domicilio en Paseo Doctor Marañón, nº 7-1º D, 20009,
            San Sebastián (Guipúzcoa).
          </Typography>
          <Typography>
            La presente Política de Privacidad regula el acceso y el uso de la
            Plataforma (en adelante, el "<Bold>Plataforma</Bold>") que PAYPER
            pone a disposición de los usuarios de Internet (en adelante, los "
            <Bold>Usuarios</Bold>") interesados en los contenidos (en adelante,
            los "<Bold>Contenidos</Bold>") y los servicios (en adelante, los "
            <Bold>Servicios</Bold>") ofertados por PAYPER en el mismo.
          </Typography>
          <Typography>
            Correo electrónico de contacto: <MailTo>info@bepayper.com</MailTo>
          </Typography>
        </Box>
        <Box component='section'>
          <Typography component='h2' variant='h4'>
            Recomendaciones
          </Typography>
          <Typography>
            Por favor, lea atentamente y siga las siguientes recomendaciones:{' '}
          </Typography>
          <Box component='ul'>
            <Box component='li'>
              Mantenga su equipo equipado con software antivirus debidamente
              actualizado contra software malicioso y aplicaciones de spyware
              que puedan poner en peligro su navegación por Internet y la
              información alojada en su equipo.{' '}
            </Box>
            <Box component='li'>
              Lea y revise esta Política de Privacidad y todos los textos
              legales puestos a disposición por PAYPER en la Plataforma.{' '}
            </Box>
          </Box>
        </Box>
        <Box component='section'>
          <Typography component='h2' variant='h4'>
            Información sobre los datos que PAYPER recopila a través de la
            Plataforma
          </Typography>
          <Typography>
            Para el correcto funcionamiento de la Plataforma, PAYPER puede tener
            acceso a los siguientes datos facilitados, en su caso, por parte de
            Usuario:{' '}
          </Typography>
          <Box component='ol'>
            <Box component='li'>
              Datos de identificación: nombre y apellidos del usuario.{' '}
            </Box>
            <Box component='li'>Datos de contacto: email, teléfono. </Box>
          </Box>
        </Box>
        <Box component='section' id='legitimacy'>
          <Typography component='h2' variant='h4'>
            ¿Por qué PAYPER está legitimada para procesar sus datos?{' '}
          </Typography>
          <Typography>
            La base de legitimación de PAYPER para el tratamiento de los datos
            de los Usuarios a través de esta Plataforma es el consentimiento de
            los Usuarios al tratamiento de sus datos personales.{' '}
          </Typography>
          <Typography>
            En este sentido, PAYPER trata los datos personales de los Usuarios
            con la finalidad de permitir la relación comercial solicitada por el
            usuario, así como también para la remisión de comunicaciones
            comerciales y/o Newsletters sobre sus propios productos y/o
            servicios.{' '}
          </Typography>
          <Typography>
            Aceptando la presente Política de Privacidad y marcando las casillas
            correspondientes, el Usuario presta su consentimiento a dicho
            tratamiento. {' '}
          </Typography>
          <Typography>
            PAYPER se toma muy en serio la protección de su privacidad y sus
            datos personales. Por esta razón, sus datos personales se conservan
            de forma segura y se tratan con el máximo cuidado, de conformidad
            con las disposiciones del Reglamento (UE) 2016/679 del Parlamento
            Europeo y del Consejo, de 27 de abril de 2016, relativo a la
            protección de las personas físicas en lo que respecta al tratamiento
            de datos personales y a la libre circulación de estos datos (el
            "Reglamento general de protección de datos" o "
            <Bold>Reglamento de protección de datos</Bold> o <Bold>RGPD</Bold>
            ").
          </Typography>
          <Typography>
            La presente Política de Privacidad regula el acceso y el uso del
            servicio que PAYPER pone a disposición del Usuario interesado en los
            servicios y contenidos alojados en la Plataforma.{' '}
          </Typography>
          <Typography>
            Por lo que respecta a este punto, el Usuario podrá retirar su
            consentimiento en cualquier momento mandando un mensaje a la
            siguiente dirección de correo electrónico:{' '}
            <MailTo>info@bepayper.com</MailTo> sin que ello afecte a la licitud
            del tratamiento basado en el consentimiento previo a su retirada.
          </Typography>
        </Box>
        <Box component='section' id='purpose'>
          <Typography component='h2' variant='h4'>
            ¿Cuáles son las finalidades del tratamiento de sus datos personales?{' '}
          </Typography>
          <Typography>
            Sus datos de carácter personal recabados por PAYPER podrán ser
            utilizados para las siguientes finalidades, según el consentimiento
            otorgado en el formulario de registro correspondiente:
          </Typography>
          <Box component='ol'>
            <Box component='li'>
              Permitir que la Plataforma cumpla su función de proporcionar los
              servicios solicitados.{' '}
            </Box>
            <Box component='li'>
              Recibir por teléfono, correo electrónico, SMS/MMS, o por otros
              medios de comunicación electrónica equivalentes, comunicaciones
              comerciales o información sobre productos y servicios de PAYPER.{' '}
            </Box>
            <Box component='li'>
              Ceder los datos a terceras empresas relacionadas con el sector de
              los medios de comunicación con la finalidad de que éstas puedan
              prestar servicios propios y envíen comunicaciones comerciales
              perfiladas a los titulares de los datos mediante teléfono, correo
              electrónico, SMS/MMS, o por otros medios de comunicación
              electrónica equivalentes, sobre sus productos y servicios.{' '}
            </Box>
          </Box>
        </Box>
        <Box component='section'>
          <Typography component='h2' variant='h4'>
            Veracidad de los datos facilitados por los Usuarios{' '}
          </Typography>
          <Typography>
            El Usuario garantiza la veracidad de los datos personales
            facilitados y se compromete a comunicar a PAYPER cualquier
            modificación de los mismos. El Usuario responderá, en cualquier
            caso, de la veracidad de los datos facilitados, reservándose PAYPER
            el derecho a excluir de los servicios a todo Usuario que haya
            facilitado datos falsos, sin perjuicio de las demás acciones que
            procedan en Derecho.{' '}
          </Typography>
          <Typography>
            Se recomienda que los Usuarios protejan sus datos con la máxima
            diligencia utilizando las herramientas de seguridad adecuadas.
            PAYPER no será responsable de ningún robo, modificación ilegal o
            pérdida de datos.
          </Typography>
          <Typography>
            Cualquier modificación o actualización de los datos deberá ser
            comunicada a PAYPER a través de los medios de comunicación previstos
            en la presente Política de Privacidad.{' '}
          </Typography>
        </Box>
        <Box component='section'>
          <Typography component='h2' variant='h4'>
            Conservación de datos{' '}
          </Typography>
          <Typography>
            La protección de la privacidad y de los datos personales de los
            Usuarios es muy importante para PAYPER. Por lo tanto, PAYPER hace
            todo lo posible para evitar que los datos del Usuario sean
            utilizados de manera inadecuada. Sólo el personal autorizado tiene
            acceso a los datos del Usuario.{' '}
          </Typography>
          <Typography>
            Los datos personales del Usuario se conservarán mientras permanezca
            su cuenta de PAYPER activa para los fines del tratamiento para los
            que han sido facilitados, siempre que el Usuario no haya revocado su
            consentimiento, solicitando la eliminación de su cuenta o el borrado
            de determinados datos personales de la Plataforma.{' '}
          </Typography>
          <Typography>
            Posteriormente, en caso de ser necesario, PAYPER mantendrá la
            información bloqueada los plazos legalmente establecidos.{' '}
          </Typography>
          <Typography>
            PAYPER se compromete al cumplimiento de su obligación de secreto y
            confidencialidad de los datos de carácter personal conforme a lo
            dispuesto en la legislación aplicable.{' '}
          </Typography>
        </Box>
        <Box component='section' id='rights'>
          <Typography component='h2' variant='h4'>
            Derechos de los usuarios{' '}
          </Typography>
          <Typography>Los usuarios tienen derecho a: </Typography>
          <Box component='ol'>
            <Box component='li'>
              <Bold>Acceder</Bold> a sus datos personales;{' '}
            </Box>
            <Box component='li'>
              Solicitar la <Bold>rectificación</Bold> de cualquier dato
              inexacto;{' '}
            </Box>
            <Box component='li'>
              Solicitar la <Bold>supresión</Bold> de sus datos;{' '}
            </Box>
            <Box component='li'>
              Solicitar que se <Bold>limite el tratamiento</Bold> de sus datos;{' '}
            </Box>
            <Box component='li'>
              <Bold>Oponerse</Bold> al tratamiento de sus datos;{' '}
            </Box>
            <Box component='li'>
              Solicitar la <Bold>portabilidad</Bold> de sus datos.{' '}
            </Box>
            <Box component='li'>
              Asimismo, los Usuarios tendrán derecho a{' '}
              <Bold>
                no ser objeto de decisiones basadas únicamente en el tratamiento
                automatizado de datos.
              </Bold>{' '}
            </Box>
          </Box>

          <Typography id='profile'>
            El Usuario puede ejercer todos estos derechos en la siguiente
            dirección de correo electrónico: <MailTo>dpo@bepayper.com</MailTo>{' '}
            indicando el motivo de su petición y aportando una copia de su
            D.N.I.
          </Typography>
          <Typography>
            El Usuario puede también enviar su petición por correo ordinario a
            la siguiente dirección: Paseo Doctor Marañón, nº 7-1º D, 20009, San
            Sebastián (Guipúzcoa).{' '}
          </Typography>
          <Typography>
            La comunicación dirigida a PAYPER deberá contener la siguiente
            información:{' '}
          </Typography>
          <Box component='ul'>
            <Box component='li'>
              Nombre y apellidos del Usuario; fotocopia de su documento nacional
              de identidad, o de su pasaporte u otro documento válido que lo
              identifique.{' '}
            </Box>
            <Box component='li'>Petición en que se concreta la solicitud. </Box>
          </Box>
          <Typography>
            Sin perjuicio de cualquier otro recurso administrativo o acción
            judicial, el Usuario tendrá derecho a presentar una reclamación ante
            una Autoridad de Control, en particular en el Estado miembro en el
            que tenga su residencia habitual, lugar de trabajo o lugar de la
            supuesta infracción, en caso de que considere que el tratamiento de
            sus datos personales no es adecuado a la normativa, así como en el
            caso de no ver satisfecho el ejercicio de sus derechos. La autoridad
            de control ante la que se haya presentado la reclamación informará
            al reclamante sobre el curso y el resultado de la reclamación.{' '}
          </Typography>
        </Box>
        <Box component='section'>
          <Typography component='h2' variant='h4'>
            Seguridad de datos{' '}
          </Typography>
          <Typography>
            La protección de la privacidad y de los datos personales de los
            Usuarios es muy importante para PAYPER. Por ello, PAYPER ha tomado
            todas las medidas a su alcance para evitar que sus datos sean
            utilizados de forma indebida, permitiendo el acceso a los mismos
            únicamente al personal autorizado.{' '}
          </Typography>
          <Typography>
            PAYPER mantiene la seguridad adecuada para la protección de los
            datos personales de acuerdo con la legislación aplicable y ha
            establecido todos los medios técnicos a su alcance para evitar la
            pérdida, mal uso, alteración, acceso no autorizado y robo de los
            datos facilitados por el Usuario a través de la Plataforma, sin
            perjuicio de informarle de que las medidas de seguridad en Internet
            no son inexpugnables.{' '}
          </Typography>
          <Typography>
            PAYPER se compromete a cumplir con el deber de secreto y
            confidencialidad respecto a los datos de carácter personal de
            acuerdo con la legislación aplicable.{' '}
          </Typography>
          <Typography>
            PAYPER no será responsable de los daños y perjuicios que pudieran
            derivarse del incumplimiento de esta obligación por parte del
            Usuario.{' '}
          </Typography>
        </Box>
        <Box component='section' id='transfer'>
          <Typography component='h2' variant='h4'>
            Transferencias y/o cesiones a terceros{' '}
          </Typography>
          <Typography>
            Se informa al Usuario de que sus datos de carácter personal podrán
            ser cedidos a terceras empresas o entidades del sector de los medios
            de comunicación.{' '}
          </Typography>
          <Typography>
            Los datos de los Usuarios únicamente podrán ser utilizados por
            PAYPER para cualquier uso que sirva para cumplir correctamente con
            las finalidades del tratamiento de datos de carácter personal
            recogidas en el punto 5 de esta Política de Privacidad. de que las
            medidas de seguridad en Internet no son inexpugnables.{' '}
          </Typography>
        </Box>
        <Box component='section'>
          <Typography component='h2' variant='h4'>
            Preguntas
          </Typography>
          <Typography>
            Si tiene alguna duda sobre nuestra Política de Privacidad, póngase
            en contacto con nosotros enviando un correo electrónico a{' '}
            <MailTo>info@bepayper.com</MailTo>
          </Typography>
          <Typography>
            Asimismo, si considera que sus derechos no están debidamente
            salvaguardados, tiene derecho a presentar una reclamación ante la
            Agencia Española de Protección de Datos.{' '}
          </Typography>
        </Box>
        <Box component='section'>
          <Typography component='h2' variant='h4'>
            Aceptación y Consentimiento{' '}
          </Typography>
          <Typography>
            El Usuario declara haber sido informado de las condiciones sobre
            protección de datos personales, aceptando y consintiendo el
            tratamiento de los mismos por parte de PAYPER, en la forma y para
            las finalidades indicadas en la presente Política de Privacidad{' '}
          </Typography>
        </Box>
      </Container>
    </Box>
  )
}
export default PrivacyPolicy
