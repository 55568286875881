import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import EVENTS_NAMES from '../../../../analytics/eventsNames.json'
import { decode } from '../../../format'
import { URL_SEARCH_PARAMS_AUTH_KEYS, Auth } from '../../../../business'
import { useTexts } from '../../../../texts'

const EVENT_NAME_MAP = {
  [Auth.AUTHORIZE_IDENTITY_PROVIDER.APPLE]:
    EVENTS_NAMES.PA_FORM_SUBMIT_SIGNUP_APPLE,
  [Auth.AUTHORIZE_IDENTITY_PROVIDER.GOOGLE]:
    EVENTS_NAMES.PA_FORM_SUBMIT_SIGNUP_GOOGLE,
}

const dispatchAnalyticsErrorEvent = (identityProvider) => {
  const name = EVENT_NAME_MAP[identityProvider]

  document.dispatchEvent(
    new CustomEvent('payperAnalyticsPublicEvent', {
      detail: {
        name,
        status: 'error',
      },
    }),
  )
}

const ERROR_DESCRIPTION_MAP = {
  user_cancelled_authorize: 'El usuario ha cancelado el proceso de registro',
}

const useSocialLoginCallback = (redirectionPathOnSuccess) => {
  const texts = useTexts()
  const navigate = useNavigate()

  const [errorDescription, setErrorDescription] = useState()

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const error = urlSearchParams.get(
      URL_SEARCH_PARAMS_AUTH_KEYS.THIRD_PARTY_ERROR,
    )
    const errorDescription = urlSearchParams.get(
      URL_SEARCH_PARAMS_AUTH_KEYS.THIRD_PARTY_ERROR_DESC,
    )
    const encodedState = urlSearchParams.get(URL_SEARCH_PARAMS_AUTH_KEYS.STATE)
    const { identityProvider, ...state } = decode(encodedState)

    if (!error) {
      const code = urlSearchParams.get(
        URL_SEARCH_PARAMS_AUTH_KEYS.THIRD_PARTY_CODE,
      )

      const mergedSearchParams = new URLSearchParams({
        ...state,
        code,
      }).toString()

      navigate(`${redirectionPathOnSuccess}?${mergedSearchParams}`, {
        state: { identityProvider },
      })
    } else {
      dispatchAnalyticsErrorEvent(identityProvider)
      setErrorDescription(
        ERROR_DESCRIPTION_MAP[error] ??
          texts.getSomethingWentWrontErrorSubtitle(),
      )
      console.error(
        'Error on social login \n'.concat(
          `type: ${error}\n`,
          Boolean(errorDescription) ? errorDescription : '',
        ),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { errorDescription }
}

export default useSocialLoginCallback
