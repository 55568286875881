import { Box } from '@mui/material'

import PrivateFooter from '../../../Layout/Private/Footer'
import { PayperLogo } from '../../../../styleguide/icons'
import styles from './account.styles'
import { Account as AccountModule } from '../../../modules'

const Account = () => {
  const onClickTopUp = () => {
    alert('onClickTopUp')
  }
  const onClickEditBankCard = () => {
    alert('onClickEditBankCard')
  }
  const onClickAddBankCard = () => {
    alert('onClickAddBankCard')
  }
  const onClickUpdatePassword = () => {
    alert('onClickUpdatePassword')
  }

  return (
    <Box sx={styles.root}>
      <Box sx={styles.mainContent}>
        <Box sx={styles.header}>
          <PayperLogo aria-label='Payper logo' color='#121212' />
        </Box>
        <AccountModule
          onClickTopUp={onClickTopUp}
          onClickEditBankCard={onClickEditBankCard}
          onClickAddBankCard={onClickAddBankCard}
          onClickUpdatePassword={onClickUpdatePassword}
        />
      </Box>
      <PrivateFooter />
    </Box>
  )
}

export default Account
