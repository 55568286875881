const VALID_PASSWORD_MIN_LENGTH = 8
// Regexp for asserting a string with at least one digit, one lowercase letter & one uppercase letter
const VALID_PASSWORD_REGEXP = /^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)$/

export const isValidPassword = (password) =>
  password.length >= VALID_PASSWORD_MIN_LENGTH &&
  VALID_PASSWORD_REGEXP.test(password)

const VALID_CODE_LENGTH = 6
// Regexp for asserting a string made of digits
const VALID_CODE_REGEXP = /^\d+$/

export const isValidCode = (code) =>
  code.length === VALID_CODE_LENGTH && VALID_CODE_REGEXP.test(code)
