import { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import image1 from '../../../assets/images/onboarding_aside_1.png'
import image2 from '../../../assets/images/onboarding_aside_2.png'
import image3 from '../../../assets/images/onboarding_aside_3.png'
import { Picture } from '../../components'
import styles from './onboarding.styles'

export const Picture1 = () => (
  <Picture width={536.4} height={460.8} fallback={image1} alt='payper-gif' />
)

export const Picture2 = () => {
  const theme = useTheme()
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('xxxl'))

  return (
    <Picture
      width={isLargeScreen ? 670.5 : 536.4}
      height={isLargeScreen ? 576 : 460.8}
      fallback={image2}
      alt='how-does-payper-work'
    />
  )
}

export const Picture3 = () => {
  const theme = useTheme()
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('xxxl'))

  const [isIndicator1Visible, setIsIndicator1Visible] = useState(false)
  const [isIndicator2Visible, setIsIndicator2Visible] = useState(false)

  useEffect(() => {
    const timer1 = setTimeout(() => {
      setIsIndicator1Visible(true)
    }, 0)

    const timer2 = setTimeout(() => {
      setIsIndicator2Visible(true)
    }, 1000)

    return () => {
      clearTimeout(timer1)
      clearTimeout(timer2)
    }
  }, [])

  return (
    <>
      <Box sx={styles.pinExtensionGuide}>
        <Box sx={styles.indicatorNumOne} id='indicator-number-one'>
          <Box
            sx={{
              ...styles.fadeInElement,
              ...(isIndicator1Visible ? styles.visible : {}),
            }}
          >
            <Box sx={styles.roundIndicator}>
              <Typography>1</Typography>
            </Box>
            <ArrowDownwardIcon color='primary' fontSize='large' />
            <Box
              sx={{
                ...styles.indicatorBorder,
                borderRadius: '50%',
              }}
            ></Box>
          </Box>
        </Box>
        <Box sx={styles.indicatorNumTwo} id='indicator-number-two'>
          <Box
            sx={{
              ...styles.fadeInElement,
              ...(isIndicator2Visible ? styles.visible : {}),
            }}
          >
            <Box sx={styles.indicatorBorder}></Box>
            <ArrowBackIcon color='primary' fontSize='large' />
            <Box sx={styles.roundIndicator}>
              <Typography>2</Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Picture
        width={isLargeScreen ? 670.5 : 536.4}
        height={isLargeScreen ? 576 : 460.8}
        fallback={image3}
        alt='how-to-pin-the-extension'
      />
    </>
  )
}
