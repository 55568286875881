import { useForm, composeValidators, validators } from '../../forms'
import { useTexts } from '../../texts'

import { sendEmail } from './service'

const useContactForm = (formId) => {
  const texts = useTexts()
  const { isValidEmail, isFilled, isChecked } = validators

  const FORM_VALIDATION_CONFIG = {
    name: composeValidators([[isFilled, texts.getErrorRequired()]]),
    familyName: composeValidators([[isFilled, texts.getErrorRequired()]]),
    email: composeValidators([
      [isFilled, texts.getErrorRequired()],
      [isValidEmail, texts.getErrorEmail()],
    ]),
    message: composeValidators([[isFilled, texts.getErrorRequired()]]),
    conditionsAccepted: composeValidators([
      [isChecked, texts.getErrorRequired()],
    ]),
  }

  const formatFormValues = (formValues) => ({
    ...formValues,
    conditionsAccepted: formValues?.conditionsAccepted === 'on',
    communicationsAccepted: formValues?.communicationsAccepted === 'on',
  })

  const submitFn = (formValues) => sendEmail(formatFormValues(formValues))

  return useForm(formId, submitFn, FORM_VALIDATION_CONFIG)
}

export default useContactForm
