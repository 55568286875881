import { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Box, CircularProgress, Typography, Button } from '@mui/material'

import { useTexts } from '../../../../../texts'
import { validators } from '../../../../../forms'
import { Alert } from '../../../index'

import ContinueButton from '../ContinueButton'

import styles from './confirmationCode.styles'

const CODE_LENGTH = 6

const fillArrayOfLength = (arrayLength) => (itemsValue) =>
  Array(arrayLength).fill(itemsValue)

const fillCodeLengthArrayWith = fillArrayOfLength(CODE_LENGTH)

const CODE_DEFAULT_VALUE = fillCodeLengthArrayWith('')
const INPUT_ERRORS_DEFAULT_VALUE = fillCodeLengthArrayWith(false)

const registerViewProptypes = {
  onSubmit: PropTypes.func,
  onClickResend: PropTypes.func,
  isSubmitting: PropTypes.bool,
  formError: PropTypes.string,
  showResendSucceed: PropTypes.bool,
}

const ConfirmationCodeView = ({
  onSubmit,
  isSubmitting,
  onClickResend,
  formError,
  showResendSucceed,
}) => {
  const texts = useTexts()

  const [code, setCode] = useState(CODE_DEFAULT_VALUE)
  const [inputsErrors, setInputsErrors] = useState(INPUT_ERRORS_DEFAULT_VALUE)

  const hasValidationErrors = (inputsErrors) =>
    inputsErrors.some((error) => error)

  const inputsReferences = fillCodeLengthArrayWith(null).map(useRef)
  const submitButtonRef = useRef()
  const formRef = useRef()

  const focusOnNextElement = (currentFocusedInputIndex, inputValue) => {
    if (inputValue === '') {
      return
    }

    const hypotheticalNextFocusedInput =
      currentFocusedInputIndex + inputValue.length
    const nextFocusedElementIndex = Math.min(
      hypotheticalNextFocusedInput,
      CODE_LENGTH,
    )
    if (nextFocusedElementIndex === CODE_LENGTH) {
      submitButtonRef.current.focus()
    } else {
      inputsReferences[hypotheticalNextFocusedInput].current.focus()
    }
  }

  const updateCodeValue = (inputValue, changedInputIndex) => {
    setCode((prevCode) => {
      const prevCodeMutableCopy = [...prevCode]
      if (inputValue === '') {
        prevCodeMutableCopy.splice(changedInputIndex, 1, '')
      } else {
        const inputValueDigits = inputValue.split('')

        prevCodeMutableCopy.splice(
          changedInputIndex,
          inputValueDigits.length,
          ...inputValueDigits,
        )
      }
      return prevCodeMutableCopy.slice(0, CODE_LENGTH)
    })
  }

  const resetErrorsOfUpdatedInputs = (inputValue, changedInputIndex) => {
    // NOTE: this function just resets the error value of the updated code-digits.
    // Their values validation is only made at the moment of form submission
    setInputsErrors((prevErrors) => {
      const inputErrorResetValue = false

      const prevErrorsMutableCopy = [...prevErrors]
      if (inputValue === '') {
        prevErrorsMutableCopy.splice(changedInputIndex, 1, inputErrorResetValue)
      } else {
        const inputValueDigits = inputValue.split('')
        const errorResetValues = Array(inputValueDigits.length).fill(
          inputErrorResetValue,
        )

        prevErrorsMutableCopy.splice(
          changedInputIndex,
          inputValueDigits.length,
          ...errorResetValues,
        )
      }
      return prevErrorsMutableCopy.slice(0, CODE_LENGTH)
    })
  }

  const handleChange = (changedInputIndex) => (event) => {
    const inputValue = event.target.value
    updateCodeValue(inputValue, changedInputIndex)
    resetErrorsOfUpdatedInputs(inputValue, changedInputIndex)
    focusOnNextElement(changedInputIndex, inputValue)
  }

  const validateFormInputs = () => {
    const newInputsErrors = code.map(
      (codeDigit) => !validators.isFilled(codeDigit),
    )
    setInputsErrors(newInputsErrors)

    return hasValidationErrors(newInputsErrors)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const hasValidationError = validateFormInputs()

    if (!hasValidationError) {
      onSubmit(code.join(''))
    }
  }

  const resetForm = () => {
    setCode(CODE_DEFAULT_VALUE)
    setInputsErrors(INPUT_ERRORS_DEFAULT_VALUE)
  }

  const handleResend = (event) => {
    event.preventDefault()
    resetForm()
    onClickResend()
  }

  return (
    <>
      <Alert text={texts.getRegisterCodeVerificationNoteLabel()} />

      <form
        id='register-confirm-email-form'
        onSubmit={handleSubmit}
        ref={formRef}
      >
        <Box sx={styles.form}>
          <Box
            sx={styles.fieldsControl}
            aria-label='Introduce tu código de confirmación'
          >
            <Box sx={styles.wrapFields}>
              {code.map((value, idx) => (
                <input
                  key={idx}
                  value={value}
                  inputMode='numeric'
                  autoFocus={idx === 0 ? true : false}
                  ref={inputsReferences[idx]}
                  className={inputsErrors[idx] ? 'error' : null}
                  onChange={handleChange(idx)}
                />
              ))}
            </Box>
            {hasValidationErrors(inputsErrors) ? (
              <Typography component='span' sx={styles.errorMessage}>
                {texts.getRegisterCodeVerificationRequired()}
              </Typography>
            ) : null}
          </Box>

          {formError !== '' ? <Alert variant='error' text={formError} /> : null}

          <ContinueButton
            id='register-confirm-email-form'
            type='submit'
            loading={isSubmitting}
            disabled={isSubmitting}
            loadingIndicator={<CircularProgress size={16} />}
            ref={submitButtonRef}
          >
            {texts.getRegisterContinueAction()}
          </ContinueButton>
        </Box>
      </form>

      <Box sx={styles.secondaryAction}>
        <Button
          id='register-resend-email-btn'
          variant='text'
          size='small'
          onClick={handleResend}
        >
          {texts.getRegisterResendEmailAction()}
        </Button>
        {showResendSucceed ? (
          <Alert
            variant='warning'
            text={texts.getRegisterCodeVerificationResendSuccedLabel()}
          />
        ) : null}
      </Box>
    </>
  )
}

ConfirmationCodeView.propTypes = registerViewProptypes

export default ConfirmationCodeView
