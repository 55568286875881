import {
  ListItemIcon,
  ListItemText,
  MenuItem as MuiMenuItem,
} from '@mui/material'

const MenuItem = ({ onClick, component, to, icon, text, ...others }) => (
  <MuiMenuItem onClick={onClick} component={component} to={to} {...others}>
    {Boolean(icon) && <ListItemIcon>{icon}</ListItemIcon>}
    <ListItemText>{text}</ListItemText>
  </MuiMenuItem>
)

export default MenuItem
