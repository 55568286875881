import PropTypes from 'prop-types'

import { useTexts } from '../../../../texts'
import Response from './Response'

const errorViewProptypes = {
  onClickRetry: PropTypes.func,
}

const ErrorView = ({ onClickRetry }) => {
  const texts = useTexts()

  return (
    <Response
      title={texts.getContactFormTitleError()}
      message={texts.getContactFormDescriptionError()}
      ctaText={texts.getContactFormSendSuccessAction()}
      onClickCta={onClickRetry}
    />
  )
}

ErrorView.propTypes = errorViewProptypes

export default ErrorView
