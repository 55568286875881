import { useMemo } from 'react'

import { useTexts } from '../../../../../texts'
import { useFormV2, composeValidators, validators } from '../../../../../forms'
import { Auth } from '../../../../../business'

const CHECKBOX_FALSY_VALUE = undefined
const CHECKBOX_TRUTHY_VALUE = 'on'

const isCheckboxChecked = (value) => value === CHECKBOX_TRUTHY_VALUE

const useUserDataForm = (formId, attribution = {}) => {
  const texts = useTexts()

  const { pipeValidators, isChecked, isFilled, hasOnlyDigits } = validators

  const FORM_VALIDATION_CONFIG = {
    email: composeValidators([[isFilled, texts.getErrorRequired()]]),
    name: composeValidators([[isFilled, texts.getErrorRequired()]]),
    familyName: composeValidators([[isFilled, texts.getErrorRequired()]]),
    phone: composeValidators([
      [
        pipeValidators(
          (v) => !isFilled(v) || hasOnlyDigits(v),
          (v) => !isFilled(v) || v.length === 9,
        ),
        texts.getErrorPhone(),
      ],
    ]),
    conditionsAccepted: composeValidators([
      [isChecked, texts.getErrorRequired()],
    ]),
  }

  const filterEmptyStringValues = (formValues) => {
    let filteredValues = { ...formValues }
    for (const [key, value] of Object.entries(formValues)) {
      if (value === '') {
        delete filteredValues[key]
      }
    }
    return filteredValues
  }

  const formatFormValues = (formValues) => ({
    ...filterEmptyStringValues(formValues),
    conditionsAccepted: isCheckboxChecked(formValues?.conditionsAccepted),
    communicationsAccepted: isCheckboxChecked(
      formValues?.communicationsAccepted,
    ),
  })

  const submitRegisterData = async (formValues) => {
    try {
      return await Auth.registerAndAutoLogin(formValues, attribution)
    } catch (error) {
      if (error instanceof Auth.UsernameAlreadyExistsError) {
        throw new Error(texts.getRegisterUserAlreadyExistsError())
      }
      throw new Error(texts.getUnexpectedError())
    }
  }

  const submitFn = (formValues) =>
    submitRegisterData(formatFormValues(formValues))

  const useFormData = useFormV2(formId, submitFn, FORM_VALIDATION_CONFIG)
  const {
    status: { formValues },
    submitForm: baseSubmitForm,
    updateFieldForm,
  } = useFormData

  const updateLegalCheckbox = (ev) => {
    updateFieldForm(
      ev.target.name,
      ev.target.checked ? CHECKBOX_TRUTHY_VALUE : CHECKBOX_FALSY_VALUE,
    )
  }

  const noLegalCheckboxIsChecked = useMemo(() => {
    const areConditionsAccepted = () =>
      isCheckboxChecked(formValues?.conditionsAccepted)
    const areCommunicationsAccepted = () =>
      isCheckboxChecked(formValues?.communicationsAccepted)

    return !areConditionsAccepted() && !areCommunicationsAccepted()
  }, [formValues?.conditionsAccepted, formValues?.communicationsAccepted])

  const ctaLabel = noLegalCheckboxIsChecked
    ? texts.getRegisterAcceptAllAndContinueAction()
    : texts.getRegisterContinueAction()

  const getFormValuesWithCheckedLegalOpts = (formValues) => ({
    ...formValues,
    conditionsAccepted: CHECKBOX_TRUTHY_VALUE,
    communicationsAccepted: CHECKBOX_TRUTHY_VALUE,
  })

  const submitForm = (formValues) => {
    if (noLegalCheckboxIsChecked) {
      formValues = getFormValuesWithCheckedLegalOpts(formValues)
    }

    baseSubmitForm(formValues)
  }

  return {
    ...useFormData,
    updateLegalCheckbox,
    ctaLabel,
    submitForm,
  }
}

export default useUserDataForm
