import { useLocation, useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'

import {
  Session,
  Public,
  URL_SEARCH_PARAMS_AUTH_KEYS,
} from '../../../../business'
import { encode, decode } from '../../../format'

const useSSOPartner = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const searchParams = new URLSearchParams(location.search)
  const bonus = searchParams.get(URL_SEARCH_PARAMS_AUTH_KEYS.PARTNER_BONUS)
  const partnerId = searchParams.get(URL_SEARCH_PARAMS_AUTH_KEYS.PARTNER_ID)
  const redirectUri = searchParams.get(
    URL_SEARCH_PARAMS_AUTH_KEYS.PARTNER_REDIRECT_URI,
  )
  const redirectOnUnauthUri = searchParams.get(
    URL_SEARCH_PARAMS_AUTH_KEYS.PARTNER_REDIRECT_ON_UNAUTH_URI,
  )

  const [partnerData, setPartnerData] = useState()

  useEffect(() => {
    ;(async () => {
      if (!Boolean(partnerId)) {
        return
      }

      const data = await Public.getPartnerById(partnerId)
      setPartnerData(data)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getPartnerId = () => partnerId
  const getPartnerName = () => (partnerData ? partnerData.name : '')
  const getPartnerLogoUrl = () => partnerData?.imageUrl
  const getPartnerTitle = () => partnerData?.accessClaimTitle
  const getPartnerDescription = () => partnerData?.accessClaimDescription

  const hasBonus = () => Boolean(bonus)
  const getBonusToken = () => Boolean(bonus) && decode(bonus)

  const getSSOThirdPartyIdpStateParam = (currentParams) => {
    const ssoState = {
      ...currentParams,
      [URL_SEARCH_PARAMS_AUTH_KEYS.PARTNER_BONUS]: bonus,
      [URL_SEARCH_PARAMS_AUTH_KEYS.PARTNER_ID]: partnerId,
      [URL_SEARCH_PARAMS_AUTH_KEYS.PARTNER_REDIRECT_URI]: redirectUri,
    }

    return encode(ssoState)
  }

  const redirect = () => {
    if (!Boolean(redirectUri) || redirectUri === 'null') {
      navigate('/dashboard')
    } else {
      window.location = Session.appendAccessParamsToURL(redirectUri)
    }
  }

  const redirectOnUnauth = () => {
    if (!Boolean(redirectOnUnauthUri) || redirectOnUnauthUri === 'null') {
      navigate('/')
    } else {
      window.location = redirectOnUnauthUri
    }
  }

  const getDataForAnalytics = () => ({
    partnerId: getPartnerId(),
    partnerName: getPartnerName(),
  })

  return {
    hasBonus,
    getBonusToken,
    getPartnerLogoUrl,
    getPartnerTitle,
    getPartnerDescription,
    getPartnerName,
    getPartnerId,
    getSSOThirdPartyIdpStateParam,
    redirect,
    redirectOnUnauth,
    getRedirectOnUnauthUri: () => redirectOnUnauthUri ?? '',
    getDataForAnalytics,
  }
}

export default useSSOPartner
