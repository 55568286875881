const breakpoints = {
  values: {
    xs: 0,
    sm: 380,
    md: 600,
    lg: 900,
    xl: 1120,
    xxl: 1440,
    xxxl: 1920,
  },
}

export default breakpoints
