import PropTypes from 'prop-types'
import { Avatar as MUIAvatar } from '@mui/material'

import { ReactComponent as UserCircle } from '../../styleguide/icons/UserCircle.svg'

const getInitial = (str) => (str?.length > 0 ? str[0].toUpperCase() : '')

const getUserInitials = (user) =>
  getInitial(user?.name).concat(getInitial(user?.familyName))

const AvatarPropTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    familyName: PropTypes.string,
    image: PropTypes.string,
  }),
  size: PropTypes.number,
}

const Avatar = ({ user, size = 30 }) => {
  const avatarStyles = {
    width: size,
    height: size,
    fontSize: (16 * size) / 30,
    color: 'primary.main',
    backgroundColor: 'neutral.700',
  }

  return (
    <>
      {!Boolean(user?.name) && !Boolean(user?.familyName) ? (
        <MUIAvatar alt='account' sx={avatarStyles}>
          <UserCircle />
        </MUIAvatar>
      ) : (
        <MUIAvatar alt='account' src={user?.image} sx={avatarStyles}>
          {getUserInitials(user)}
        </MUIAvatar>
      )}
    </>
  )
}

Avatar.propTypes = AvatarPropTypes

export default Avatar
