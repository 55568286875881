import PropTypes from 'prop-types'
import { Typography } from '@mui/material'

import styles from './chip.styles.js'

const chipPropTypes = {
  variant: PropTypes.oneOf(['default', 'info', 'error', 'success', 'warning'])
    .isRequired,
}

const Chip = ({ variant = 'default', text }) => (
  <Typography component='span' sx={{ ...styles.text, ...styles[variant] }}>
    {text}
  </Typography>
)

Chip.propTypes = chipPropTypes

export default Chip
