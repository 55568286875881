import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import RequireAuthenticated from './RequireAuthenticated'
import RequireNotAuthenticated from './RequireNotAuthenticated'
import { PrivateLayout, DynamicLayout, PublicLayout } from './Layout'
import {
  Account,
  Access,
  BalanceData,
  BankCardEdition,
  BankCardIntroduction,
  ChromeExtensionUninstalled,
  OnboardingChromeExtension,
  CookiesPolicy,
  DashboardHome,
  Error,
  ForgottenPassword,
  Home,
  HomeV2,
  LegalNotice,
  Login,
  OnboardingAccess,
  PrivacyPolicy,
  UpdatePassword,
  UpdatePersonalData,
  Purchases,
  Register,
  RegisterSuccess,
  Section,
  SocialLoginCallback,
  SuccessThirdPartyIdpAccess,
  SSOSuccessSocialLogin,
  SSOSocialLoginCallback,
  SSOThirdPartyIdpRegister,
  // SSOLogin,
  // SSOLoginPartner,
  // SSORegister,
  // SSORegisterPartner,
  SSOForgottenPassword,
  SSOForgottenPasswordPartner,
  TermsPolicy,
  TopUpBalance,
  WidgetPurchaseWithBankCardRegistration,
} from './Pages'
import * as BypassDemo from './Pages/BypassDemo'

const AccessRedirection = (
  <Navigate to={`/access${window.location.search}`} replace />
)

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter)

const router = sentryCreateBrowserRouter([
  {
    path: '/v2/',
    element: (
      <RequireAuthenticated>
        <BypassDemo.Layout />
      </RequireAuthenticated>
    ),
    children: [
      {
        path: 'discovery',
        element: <BypassDemo.Discovery />,
      },
      {
        path: 'library',
        element: <BypassDemo.Library />,
      },
      {
        path: 'account',
        element: <BypassDemo.Account />,
      },
    ],
    errorElement: <Error />,
  },
  {
    path: '/dashboard/',
    element: (
      <RequireAuthenticated>
        <PrivateLayout />
      </RequireAuthenticated>
    ),
    children: [
      {
        index: true,
        element: <DashboardHome />,
      },
      {
        path: 'account',
        element: <Account />,
      },
      {
        path: 'balance',
        element: <BalanceData />,
      },
      {
        path: 'top-up-balance',
        element: <TopUpBalance />,
      },
      {
        path: 'bank-card-edition',
        element: <BankCardEdition />,
      },
      {
        path: 'bank-card-introduction',
        element: <BankCardIntroduction />,
      },
      {
        path: 'update-password',
        element: <UpdatePassword />,
      },
      {
        path: 'update-personal-data',
        element: <UpdatePersonalData />,
      },
      {
        path: 'purchases',
        element: <Purchases />,
      },
      {
        path: 'section/:sectionId',
        element: <Section />,
      },
    ],
    errorElement: <Error />,
  },
  {
    path: '/access',
    element: <Access />,
    errorElement: <Error />,
  },
  {
    path: '/register-success',
    element: (
      <RequireAuthenticated>
        <RegisterSuccess />
      </RequireAuthenticated>
    ),
    errorElement: <Error />,
  },
  {
    path: '/register',
    element: (
      <RequireNotAuthenticated>
        <Register />
      </RequireNotAuthenticated>
    ),
    errorElement: <Error />,
  },
  {
    path: '/login',
    element: (
      <RequireNotAuthenticated>
        <Login />
      </RequireNotAuthenticated>
    ),
    errorElement: <Error />,
  },
  {
    path: '/forgotten-password',
    element: (
      <RequireNotAuthenticated>
        <ForgottenPassword />
      </RequireNotAuthenticated>
    ),
    errorElement: <Error />,
  },
  {
    path: '/purchase-with-bank-card-registration',
    element: <WidgetPurchaseWithBankCardRegistration />,
    errorElement: <Error />,
  },
  {
    path: '/sso-login',
    // element: <SSOLogin />,
    element: AccessRedirection,
    errorElement: <Error />,
  },
  {
    path: '/sso-login-partner',
    // element: <SSOLoginPartner />,
    element: AccessRedirection,
    errorElement: <Error />,
  },
  {
    path: '/sso-forgotten-password',
    element: <SSOForgottenPassword />,
    errorElement: <Error />,
  },
  {
    path: '/sso-forgotten-password-partner',
    element: <SSOForgottenPasswordPartner />,
    errorElement: <Error />,
  },
  {
    path: '/sso-register',
    // element: <SSORegister />,
    element: AccessRedirection,
    errorElement: <Error />,
  },
  {
    path: '/sso-register-partner',
    // element: <SSORegisterPartner />,
    element: AccessRedirection,
    errorElement: <Error />,
  },
  {
    path: '/sso-3rd-party-idp-register',
    element: <SSOThirdPartyIdpRegister />,
    errorElement: <Error />,
  },
  {
    path: '/success-3rd-party-idp-access',
    element: <SuccessThirdPartyIdpAccess />,
    errorElement: <Error />,
  },
  {
    path: '/social-login-callback',
    element: <SocialLoginCallback />,
    errorElement: <Error />,
  },
  {
    path: '/sso-success-social-login',
    element: <SSOSuccessSocialLogin />,
    errorElement: <Error />,
  },
  {
    path: '/sso-social-login-callback',
    element: <SSOSocialLoginCallback />,
    errorElement: <Error />,
  },
  {
    path: '/privacy-policy',
    element: (
      <DynamicLayout>
        <PrivacyPolicy />
      </DynamicLayout>
    ),
    errorElement: <Error />,
  },
  {
    path: '/terms-policy',
    element: (
      <DynamicLayout>
        <TermsPolicy />
      </DynamicLayout>
    ),
    errorElement: <Error />,
  },
  {
    path: '/legal-notice',
    element: (
      <DynamicLayout>
        <LegalNotice />
      </DynamicLayout>
    ),
    errorElement: <Error />,
  },
  {
    path: '/cookies-policy',
    element: (
      <DynamicLayout>
        <CookiesPolicy />
      </DynamicLayout>
    ),
    errorElement: <Error />,
  },
  {
    path: '/about',
    element: (
      <RequireNotAuthenticated>
        <PublicLayout>
          <Home />
        </PublicLayout>
      </RequireNotAuthenticated>
    ),
    errorElement: <Error />,
  },
  {
    path: '/onboarding-ce',
    element: <OnboardingChromeExtension />,
    errorElement: <Error />,
  },
  {
    path: '/onboarding-access',
    element: <OnboardingAccess />,
    errorElement: <Error />,
  },
  {
    path: '/chrome-extension-uninstalled',
    element: <ChromeExtensionUninstalled />,
    errorElement: <Error />,
  },
  {
    path: '/',
    element: (
      <RequireNotAuthenticated>
        <PublicLayout>
          <HomeV2 />
        </PublicLayout>
      </RequireNotAuthenticated>
    ),
    errorElement: <Error />,
  },
])

const App = () => {
  document.dispatchEvent(new Event('appLoad'))

  return <RouterProvider router={router} />
}

export default App
