import { useState } from 'react'

import { Auth, Session } from '../../../../../business'
import { useTexts } from '../../../../../texts'

import View from './View'

const DEFAULT_FORM_STATE = {
  isSubmitting: false,
  formError: '',
  fieldErrors: {},
  showResendSucceed: false,
  isSubmittingResend: false,
}

const ConfirmationCodeStep = ({ onContinue, email, password }) => {
  const [formState, setFormState] = useState(DEFAULT_FORM_STATE)

  const texts = useTexts()

  const handleSubmit = async (code) => {
    setFormState((state) => ({ ...state, isSubmitting: true }))

    try {
      await Auth.verifyCode(email, code)
      const userSub = await Session.login(email, password)

      document.dispatchEvent(
        new CustomEvent('registerConfirmEmailForm', {
          detail: {
            formId: 'register-confirm-email-form',
            userSub,
            status: 'success',
          },
        }),
      )

      onContinue()
    } catch (error) {
      let formError = texts.getUnexpectedError()

      if (error instanceof Auth.CodeMismatchError) {
        formError = texts.getCodeMismatchError()
      }
      if (error instanceof Auth.ExpiredCodeError) {
        formError = texts.getExpiredCodeError()
      }

      document.dispatchEvent(
        new CustomEvent('registerConfirmEmailForm', {
          detail: {
            formId: 'register-confirm-email-form',
            status: 'error',
            formError,
          },
        }),
      )

      setFormState({
        ...DEFAULT_FORM_STATE,
        formError,
      })
    }
  }

  const handleResendCode = async () => {
    setFormState((state) => ({
      ...state,
      isSubmittingResend: true,
      formError: '',
      fieldErrors: {},
    }))

    try {
      await Auth.resendCode(email)
      setFormState((state) => ({
        ...state,
        showResendSucceed: true,
        isSubmittingResend: false,
      }))
    } catch (e) {
      setFormState((state) => ({
        isSubmitting: false,
        formError: texts.getUnexpectedError(),
        isSubmittingResend: false,
      }))
    }
  }

  return (
    <View
      onSubmit={handleSubmit}
      onClickResend={handleResendCode}
      {...formState}
    />
  )
}

export default ConfirmationCodeStep
