import { Box, Container, Typography } from '@mui/material'

import styles from '../PrivacyPolicy/privacyPolicy.styles'

const Bold = ({ children }) => (
  <Box component='strong' sx={{ display: 'inline', fontWeight: 'bold' }}>
    {children}
  </Box>
)

const index = () => (
  <Box component='main'>
    <Container component='article' maxWidth='xl' sx={styles.container}>
      <Box component='header'>
        <Typography component='h1' variant='h3'>
          Política de cookies
        </Typography>
      </Box>
      <Box component='section'>
        <Typography>
          Marter 878, S.L. (en adelante, “<Bold>Payper</Bold>”) informa, a
          través de la presente Política de Cookies, acerca de la utilización de
          dispositivos de almacenamiento y recuperación de datos en equipos
          terminales de los Usuarios.
        </Typography>
        <Typography component='h2' variant='h4'>
          ¿Qué son las cookies?
        </Typography>
        <Typography>
          Las cookies son archivos o ficheros que se descargan en el
          ordenador/smartphone/tablet del Usuario al acceder a determinadas
          páginas web y aplicaciones que permiten almacenar preferencias de la
          navegación del Usuario, haciendo que la interacción entre el Usuario y
          la Plataforma sea más rápida y fácil.
        </Typography>
        <Typography>
          La información recogida por las cookies es anónima y no contiene
          ninguna información sensible ya que no recogen datos que puedan
          identificar personalmente al Usuario. En cualquier caso, el Usuario
          podrá acceder a la configuración de su navegador para modificar y/o
          bloquear la instalación de las Cookies enviadas desde la Plataforma,
          sin que ello impida al acceso a los contenidos.
        </Typography>
      </Box>
      <Box component='section'>
        <Typography component='h2' variant='h4'>
          ¿Para qué utiliza Payper las cookies?
        </Typography>
        <Typography>
          De conformidad con el artículo 22 de la Ley de Servicios de la
          Sociedad de la Información y Comercio Electrónico, así como en el
          Considerando (30) del Reglamento General de Protección de Datos (en
          adelante, “RGPD”), la presente Plataforma utiliza cookies u otros
          dispositivos de almacenamiento y recuperación de información para
          realizar un seguimiento de las interacciones de los Usuarios con los
          servicios ofrecidos en la Plataforma.{' '}
        </Typography>
        <Typography>
          Las cookies permiten reconocer el navegador de un Usuario, así como el
          tipo de dispositivo desde el que se accede a la Plataforma, y se
          utilizan para facilitar la próxima visita del Usuario y hacer que la
          Plataforma resulte más útil.{' '}
        </Typography>
        <Typography>
          De conformidad con el artículo 22 de la Ley de Servicios de la
          Sociedad de la Información y Comercio Electrónico, así como en el
          Considerando (30) del Reglamento General de Protección de Datos (en
          adelante, “RGPD”), la presente Plataforma utiliza cookies u otros
          dispositivos de almacenamiento y recuperación de información para
          realizar un seguimiento de las interacciones de los Usuarios con los
          servicios ofrecidos en la Plataforma.{' '}
        </Typography>
        <Typography>Payper utiliza las cookies para:</Typography>
        <Box component='ol'>
          <Box component='li'>
            Optimizar la navegación del Usuario mediante el seguimiento de la
            sesión.{' '}
          </Box>
          <Box component='li'>
            Recopilar información para optimizar la navegación y mejorar la
            Plataforma.{' '}
          </Box>
        </Box>
        <Typography>
          La Plataforma utiliza los siguientes tipos de cookies:{' '}
        </Typography>
        <Box component='ul'>
          <Box component='li'>
            <Bold>Obligatorias:</Bold> Cookies estrictamente necesarias para
            prestar un servicio o contenido solicitado por el usuario. Éstas se
            encargan de aspectos técnicos como identificar registros y gestionar
            preferencias de herramientas en el uso, interacción y localización
            del acceso a los contenidos.
          </Box>
          <Box component='li'>
            <Bold>Estadísticas/analíticas:</Bold> Estas cookies recogen
            información relativa al uso de la página como la permanencia en
            página y los contenidos visitados por los lectores para medir su
            rendimiento y mejorar la navegación{' '}
            <Box sx={{ mt: 2 }}>
              <Typography>
                Los objetivos principales que se persiguen con este tipo de
                cookies son:
              </Typography>
              <Box component='ul'>
                <Box component='li'>
                  Permitir la identificación anónima de los Usuarios navegantes
                  y por lo tanto la contabilización aproximada del número de
                  visitantes.
                </Box>
                <Box component='li'>
                  Identificar de forma anónima los contenidos más visitados.{' '}
                </Box>
                <Box component='li'>
                  Saber si el Usuario que está accediendo es nuevo o repite
                  visita.{' '}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box component='section'>
        <Typography component='h2' variant='h4'>
          ¿Cómo deshabilitar las cookies?{' '}
        </Typography>
        <Typography>
          Aquellas cookies que no sean obligatorias para la navegación de la
          presente Plataforma podrán deshabilitarse seleccionando la opción
          'Rechazar' del modal de cookies.
        </Typography>
      </Box>
      <Box component='section'>
        <Typography component='h2' variant='h4'>
          Aceptación de cookies
        </Typography>
        <Typography>
          La presente Plataforma no instala nuestras cookies en los dispositivos
          de los usuarios hasta que estos hayan aceptado la instalación de
          éstas.{' '}
        </Typography>
        <Typography>
          Le informamos que en el caso de no aceptar la instalación de cookies o
          de deshabilitar alguna de estas en la configuración, es posible que
          ciertos servicios no estén disponibles sin la utilización de estas o
          que no pueda acceder a determinados servicios ni tampoco aprovechar
          por completo todo lo que esta Plataforma le ofrece.{' '}
        </Typography>
      </Box>
    </Container>
  </Box>
)
export default index
