const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 0,
    color: 'neutral.700',
    position: 'relative',
    backgroundColor: 'neutral.100',

    '&::before': {
      content: '""',
      display: 'block',
      width: '100vw',
      height: '100vh',
      position: 'absolute',
      top: (theme) => theme.spacing(-1.5),
      left: 'calc(50% - 50vw)',
      zIndex: -1,
      backgroundColor: 'inherit',
    },

    '& > form': {
      position: 'relative',
    },
    'apple-pay-button': {
      '--apple-pay-button-width': 'calc(100% - 32px)',
      '--apple-pay-button-height': '40px',
      '--apple-pay-button-border-radius': '8px',
      '--apple-pay-button-padding': '8px 16px',
    },
  },
  form: {
    zIndex: 3,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 2,
    backgroundColor: 'neutral.200',
  },
  topUpCards: {
    backgroundColor: 'neutral.100',
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 0.5,
    backgroundColor: 'neutral.200',

    '&::before': {
      content: '""',
      display: 'block',
      width: '100vw',
      height: '20vh',
      position: 'absolute',
      top: (theme) => theme.spacing(4),
      left: 'calc(50% - 50vw)',
      zIndex: -1,
      backgroundColor: 'inherit',
    },
  },
}

export default styles
