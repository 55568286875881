import { Box, Typography } from '@mui/material'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward'

import { useTexts } from '../../../texts'
import styles from './articleCard.styles'

const GoToArticleFakeButton = () => {
  const texts = useTexts()
  return (
    <Box sx={styles.fakeButton} className='fakeButton'>
      <Typography variant='body2'>{texts.getReadLabel()}</Typography>
      <ArrowOutwardIcon />
    </Box>
  )
}

export default GoToArticleFakeButton
