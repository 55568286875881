import { useNavigate } from 'react-router-dom'
import { CircularProgress } from '@mui/material'

import { BalanceCard } from '../../../components'
import { useTexts } from '../../../../texts'
import { Coins } from '../../../../styleguide/icons'
import { User } from '../../../../business'
import CardWrapper from './CardWrapper'

const Balance = ({ withTopUp }) => {
  const texts = useTexts()
  const navigate = useNavigate()

  const { state: balance } = User.useBalance()

  return (
    <>
      {balance.isLoading ? (
        <CircularProgress />
      ) : balance.error ? (
        <p>{texts.getError()}</p>
      ) : (
        <CardWrapper
          shrink
          title={texts.getBalanceCurrentLabel()}
          icon={<Coins />}
        >
          <BalanceCard
            balanceData={balance.data}
            withTopUp={withTopUp}
            onClickTop={() => {
              navigate('/dashboard/top-up-balance')
            }}
          />
        </CardWrapper>
      )}
    </>
  )
}

export default Balance
