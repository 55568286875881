import { Box, Typography } from '@mui/material'

import { BankCard } from '../../components'
import styles from './bankCardEdition.styles'

const BankCardChangeView = ({ title, bankCardData, children }) => {
  return (
    <Box
      sx={{
        ...styles.root,
        ...(bankCardData?.isExpired ? styles.isOutdated : null),
      }}
    >
      <Box sx={styles.content}>
        {bankCardData ? <BankCard bankCardData={bankCardData} /> : null}
        <Box sx={styles.wrap}>
          <Typography component='h1' sx={styles.title}>
            {title}
          </Typography>
          {children}
        </Box>
      </Box>
    </Box>
  )
}

export default BankCardChangeView
