import { useState } from 'react'
import { useLocation } from 'react-router-dom'

import { User } from '../../../../business'

import HeaderActions from './Actions'
import Cta from './Cta'
import HeaderContainer from './Container'
import { LogoutDialog } from '../../../components'

const PATHS_WITH_TOPUP_CTA_DISABLED = [
  '/dashboard/top-up-balance',
  '/dashboard/bank-card-edition',
  '/dashboard/bank-card-introduction',
]

const isBankCardNotRegistered = (bankCardData) =>
  bankCardData.isLoading ||
  (!bankCardData.isLoading && !Boolean(bankCardData.data))

const DashboardHeader = () => {
  const { state: balanceData } = User.useBalance()
  const { state: bankCardData } = User.useBankCard()

  const [showLogoutDialog, setShowLogoutDialog] = useState(false)

  const handleClickLogout = () => setShowLogoutDialog(true)

  const { pathname } = useLocation()

  const isTopUpBalanceCTADisabled =
    PATHS_WITH_TOPUP_CTA_DISABLED.includes(pathname) ||
    isBankCardNotRegistered(bankCardData)

  return (
    <>
      <LogoutDialog
        isOpen={showLogoutDialog}
        onClose={() => setShowLogoutDialog(false)}
      />
      <HeaderContainer
        renderActions={() => (
          <HeaderActions onClickLogout={handleClickLogout} />
        )}
        renderBalanceCta={() => (
          <Cta
            balance={balanceData}
            disabled={isTopUpBalanceCTADisabled}
            onButtonClickTo='/dashboard/top-up-balance'
          />
        )}
      />
    </>
  )
}

export default DashboardHeader
