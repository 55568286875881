import baseTheme from '../base'

const MuiPaper = {
  styleOverrides: {
    root: {
      borderRadius: baseTheme.spacing(1),
    },
  },
}

export default MuiPaper
