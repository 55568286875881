import { useNavigate } from 'react-router-dom'
import { Box, Typography, Button } from '@mui/material'

import { Public } from '../../../../business'
import articleCardStyles from '../../../components/ArticleCard/articleCard.styles'
import { useTexts } from '../../../../texts'

const styles = {
  root: {
    px: articleCardStyles.card.px,
  },
  title: {
    textAlign: {
      md: 'center',
    },
  },
  buttonsContainer: {
    width: (theme) => `min(100%, ${theme.spacing(40)})`,

    mx: 'auto',
    px: (theme) => ({
      xs: theme.spacing(1),
      md: theme.spacing(3),
      lg: theme.spacing(8, 4, 9),
      xl: theme.spacing(8, 8.75, 9),
    }),
    pt: (theme) => ({
      xs: theme.spacing(1),
      md: theme.spacing(1.5),
    }),
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: {
      xs: 'space-around',
      md: 'center',
    },
    columnGap: (theme) => ({
      xs: theme.spacing(1),
      md: theme.spacing(2),
    }),
    rowGap: 1,
  },
  button: {
    maxWidth: 'calc(45%)',
    width: 152,
    height: 50,

    p: (theme) => ({
      xs: theme.spacing(0.5),
      lg: theme.spacing(1),
    }),

    '&:hover': {
      backgroundColor: 'neutral.200',
    },
    '& span': {
      fontWeight: 500,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
}

const Sections = () => {
  const texts = useTexts()
  const navigate = useNavigate()

  const sections = Public.useArticlesSections()

  const navigateToSection = (sectionId) => {
    navigate(`/dashboard/section/${sectionId}`)
  }

  if (sections.isLoading || !sections.data) {
    return null
  }
  return (
    <Box sx={styles.root}>
      <Typography component='h2' variant='h4' sx={styles.title}>
        {texts.getSectionsTitle()}
      </Typography>
      <Box sx={styles.buttonsContainer} id='section_buttons'>
        {Object.entries(sections.data).map(([sectionKey, sectionLabel]) => (
          <Button
            key={sectionKey}
            variant='outlined'
            onClick={() => navigateToSection(sectionKey)}
            sx={styles.button}
          >
            <Typography variant='title4'>{sectionLabel}</Typography>
          </Button>
        ))}
      </Box>
    </Box>
  )
}

export default Sections
