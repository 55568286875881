import { useNavigate } from 'react-router-dom'

import { CircularProgress } from '../../../components'
import useSearchParams from '../useSearchParams'
import useAccessLoad from '../useAccessLoad'
import useAccessFinish from '../useAccessFinish'
import RegistrationFlow from './RegistrationFlow'

const Register = () => {
  const navigate = useNavigate()

  const paramsData = useSearchParams()
  const accessLoad = useAccessLoad(paramsData)
  const accessFinish = useAccessFinish(paramsData)

  const navigateWithSearchParams = (to) => navigate(to + window.location.search)

  if (!Boolean(accessLoad.unauthenticationIsConfirmed))
    return <CircularProgress height='80vh' />
  return (
    <RegistrationFlow
      attribution={paramsData?.attribution}
      onGoToLogin={() => navigateWithSearchParams('/login')}
      onFinish={() => accessFinish.handleFinishAuth({ isNewUser: true })}
      onCancel={() =>
        accessFinish.handleFinishUnauth('User cancelled the process')
      }
    />
  )
}

export default Register
