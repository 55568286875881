import { Auth } from '../../../business'
import { encode } from '../../format'

import { SocialLoginButtons as SocialLoginButtonsView } from '../../components'

const getCurrentParamsObject = () => {
  const currentSearchParams = new URLSearchParams(window.location.search)
  return Object.fromEntries(currentSearchParams.entries())
}

const getStateParam = (identityProvider) => {
  const currentParams = getCurrentParamsObject()
  return encode({ ...currentParams, identityProvider })
}

const SocialLoginButtons = (props) => {
  return (
    <SocialLoginButtonsView
      {...props}
      appleHref={Auth.getThirdPartyIdpAuthorizedEndpoint({
        identityProvider: Auth.AUTHORIZE_IDENTITY_PROVIDER.APPLE,
        isSSO: false,
        stateParam: getStateParam(Auth.AUTHORIZE_IDENTITY_PROVIDER.APPLE),
      })}
      googleHref={Auth.getThirdPartyIdpAuthorizedEndpoint({
        identityProvider: Auth.AUTHORIZE_IDENTITY_PROVIDER.GOOGLE,
        isSSO: false,
        stateParam: getStateParam(Auth.AUTHORIZE_IDENTITY_PROVIDER.GOOGLE),
      })}
    />
  )
}

export default SocialLoginButtons
