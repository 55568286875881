import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, IconButton, Typography } from '@mui/material'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { useTexts } from '../../../../texts'
import Picture from '../../Picture'

import { ReactComponent as X } from './X.svg'
import { ReactComponent as Check } from './Check.svg'
import LogoPayper from './LogoPayper'
import imageAvif from './joyful-young-woman-jumping-with-arms-raised.avif'
import imageWebp from './joyful-young-woman-jumping-with-arms-raised.webp'
import imagePng from './joyful-young-woman-jumping-with-arms-raised.png'
import styles from './loginWidget.styles'

const sponsoredModalWrapperProptypes = {
  logoUrl: PropTypes.string,
  customTitle: PropTypes.string,
  customDescription: PropTypes.string,
  onCancel: PropTypes.func,
  children: PropTypes.node,
}

const SponsoredModalWrapper = ({
  logoUrl,
  customTitle,
  customDescription,
  onCancel,
  children,
}) => {
  const theme = useTheme()
  const texts = useTexts()

  const [hasScrolledDown, setHasScrolledDown] = useState(false)
  const widerThanMobile = useMediaQuery(theme.breakpoints.up('md'))
  const widerThanDesktop = useMediaQuery(theme.breakpoints.up('lg'))

  const ITEMS = [
    texts.getLoginWidgetItemOneLabel(),
    texts.getLoginWidgetItemTwoLabel(),
    texts.getLoginWidgetItemThreeLabel(),
  ]

  useEffect(() => {
    function onScroll(e) {
      setHasScrolledDown(e.target.documentElement.scrollTop > 0)
    }
    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  return (
    <Box component='main' sx={styles.root}>
      <Box component='section' sx={styles.info}>
        <Box sx={styles.infoWrapper}>
          <Box
            sx={{
              ...styles.infoHeader,
              ...(!widerThanMobile && hasScrolledDown
                ? styles.withShadow
                : null),
              ...(!widerThanMobile ? styles.infoHeaderSmall : null),
            }}
          >
            <Box sx={styles.infoHeaderContent}>
              {Boolean(logoUrl) ? (
                <Box
                  component='img'
                  alt=''
                  src={logoUrl}
                  sx={styles.infoImage}
                />
              ) : null}
              <Typography component='small' sx={styles.infoSmall}>
                {texts.getPayperMarketingLabel()}
                <LogoPayper width={62} height={16} />
              </Typography>
              {Boolean(onCancel) && (
                <Box sx={styles.closeButton}>
                  <IconButton
                    aria-label={texts.getCloseAction()}
                    onClick={onCancel}
                  >
                    <X />
                  </IconButton>
                </Box>
              )}
            </Box>
          </Box>
          <Box pr={1}>
            {Boolean(customTitle) ? (
              <Typography component='h1' sx={styles.infoHeading}>
                {customTitle}
              </Typography>
            ) : (
              <Typography
                component='h1'
                sx={styles.infoHeading}
                dangerouslySetInnerHTML={{
                  __html: texts.getLoginWidgetHeadingLabel(),
                }}
              />
            )}
            <Box sx={styles.infoDescription}>
              {Boolean(customDescription) ? (
                <Typography>{customDescription}</Typography>
              ) : (
                <Box component='ul' sx={styles.infoList}>
                  {ITEMS.map((item, idx) => (
                    <Box key={idx} component='li' sx={styles.infoListItem}>
                      <Box key={idx} sx={styles.icon} aria-hidden>
                        <Check />
                      </Box>
                      {item}
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
            {widerThanDesktop ? (
              <Picture
                width={349}
                height={442}
                avif={imageAvif}
                webp={imageWebp}
                fallback={imagePng}
              />
            ) : null}
          </Box>
        </Box>
      </Box>
      <Box component='section' sx={styles.access}>
        <Box sx={styles.accessWrapper}>{children} </Box>
      </Box>
    </Box>
  )
}

SponsoredModalWrapper.propTypes = sponsoredModalWrapperProptypes

export default SponsoredModalWrapper
