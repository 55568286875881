import { useRef, useEffect } from 'react'
import { Box, Container, Typography } from '@mui/material'

import { useTexts } from '../../../../texts'
import useIntersectionObserver from '../../../useIntersectionObserver'
import { Picture } from '../../../components'
import imageAvif from './young-man-with-a-mobile-device-in-his-hands.avif'
import imageWebp from './young-man-with-a-mobile-device-in-his-hands.webp'
import imagePng from './young-man-with-a-mobile-device-in-his-hands.png'
import imageAvif2 from './young-man.avif'
import imageWebp2 from './young-man.webp'
import imagePng2 from './young-man.png'
import imageAvif3 from './joyful-young-woman-jumping-with-arms-raised.avif'
import imageWebp3 from './joyful-young-woman-jumping-with-arms-raised.webp'
import imagePng3 from './joyful-young-woman-jumping-with-arms-raised.png'
import styles from './highlights.styles'

const Highlights = () => {
  const texts = useTexts()

  const HIGHLIGHTS = [
    {
      text: texts.getHomepageHightlightsItemOneLabel(),
      imageAvif: imageAvif,
      imageWebp: imageWebp,
      imagePng: imagePng,
    },
    {
      text: texts.getHomepageHightlightsItemTwoLabel(),
      imageAvif: imageAvif2,
      imageWebp: imageWebp2,
      imagePng: imagePng2,
    },
    {
      text: texts.getHomepageHightlightsItemThreeLabel(),
      imageAvif: imageAvif3,
      imageWebp: imageWebp3,
      imagePng: imagePng3,
    },
  ]

  const targets = useRef(new Set())
  const [entries, setObservedNodes] = useIntersectionObserver({})

  useEffect(() => {
    setObservedNodes(() => [...targets.current])
  }, [setObservedNodes])

  useEffect(() => {
    for (const entry of entries) {
      if (entry.isIntersecting) {
        entry.target.classList.add('isVisible')

        setObservedNodes((observedNodes) =>
          observedNodes.filter((node) => node !== entry.target),
        )
      }
    }
  }, [entries, setObservedNodes])

  return (
    <Box component='section' sx={styles.root}>
      <Container>
        <Box
          ref={(element) => targets.current.add(element)}
          component='ul'
          sx={styles.list}
        >
          {HIGHLIGHTS.map((highlight, idx) => (
            <Box
              key={highlight.text}
              component='li'
              sx={styles.item}
              style={{ '--delay': `${350 * idx}ms` }}
            >
              <Box sx={styles.wrapPicture}>
                <Picture
                  width={432}
                  height={538}
                  avif={highlight.imageAvif}
                  webp={highlight.imageWebp}
                  fallback={highlight.imagePng}
                />
              </Box>
              <Box sx={styles.content}>
                <Typography
                  sx={styles.text}
                  dangerouslySetInnerHTML={{ __html: highlight.text }}
                />
              </Box>
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  )
}

export default Highlights
