import { useNavigate } from 'react-router-dom'

import View from './View'

const Benefits = () => {
  const navigate = useNavigate()
  const navigateWithSearchParams = (to) => navigate(to + window.location.search)

  return <View openRegister={() => navigateWithSearchParams('/access')} />
}

export default Benefits
