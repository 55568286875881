import theme from '../../../../theme'

const styles = {
  root: {
    pt: {
      xs: 5,
      lg: 11,
      xl: 14,
    },
    pb: {
      xs: 5,
      lg: 11,
    },
    backgroundColor: 'neutral.200',

    '& .isVisible': {
      opacity: 1,
      transform: 'none',

      '& > :is(li)': {
        opacity: 1,
        transform: 'none',
      },
    },

    [theme.mixins.hoverCondition()]: {
      pt: {
        xl: 10,
      },
    },
  },
  content: {
    display: {
      xs: 'flex',
      md: 'grid',
    },
    flexDirection: 'column',
    alignItems: {
      xs: 'center',
      md: 'start',
    },
    justifyContent: {
      md: 'center',
    },
    gridTemplateColumns: (theme) => ({
      md: `min(${theme.spacing(22)}, 38.5%) 1fr`,
      lg: `min(${theme.spacing(22)}, 38.5%) max(${theme.spacing(28.5)}, 10%)`,
    }),
    gridTemplateRows: (theme) => ({
      md: `${theme.spacing(4)} auto ${theme.spacing(4)}`,
      lg: `${theme.spacing(6.5)} auto ${theme.spacing(4)}`,
    }),
    gridTemplateAreas: {
      md: '"i t" "i l" "i c"',
    },
    rowGap: {
      xs: 2.5,
      md: 1.5,
      lg: 2.5,
    },
    columnGap: (theme) => ({
      md: `min(${theme.spacing(2.5)}, 5%)`,
      lg: '8%',
    }),
  },
  heading: {
    width: 'min(12ch, 100%)',
    mx: {
      xs: 'auto',
      md: 'initial',
    },
    //
    transform: 'translateY(1rem)',
    opacity: 0,
    transition: 'transform 250ms ease-in, opacity 300ms ease-in',
    transitionDelay: 'var(--delay, 0)',
  },
  wrapPicture: {
    width: '100%',
    height: 'auto',
    maxWidth: (theme) => ({
      xs: theme.spacing(15.5),
      md: '100%',
    }),
    gridArea: {
      md: 'i',
    },
    //
    transform: 'translateY(1rem)',
    opacity: 0,
    transition: 'transform 250ms ease-in, opacity 300ms ease-in',
    transitionDelay: 'var(--delay, 0)',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 0.125,
    my: 0,
    pl: 0,
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 1,
    fontSize: (theme) => ({
      xs: theme.spacing(0.875),
      lg: theme.spacing(1.25),
    }),
    fontWeight: {
      xs: 500,
      lg: 400,
    },
    //
    transform: 'translateY(1rem)',
    opacity: 0,
    transition: 'transform 250ms ease-in, opacity 300ms ease-in',
    transitionDelay: 'var(--delay, 0)',
  },
  icon: {
    display: 'flex',
    fontSize: (theme) => ({
      xs: theme.spacing(1.5),
      lg: theme.spacing(2),
    }),
  },
  wrapCta: {
    //
    opacity: 0,
    transition: 'opacity 300ms ease-in',
    transitionDelay: 'var(--delay, 0)',
  },
}

export default styles
