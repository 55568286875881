import { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'

import useTexts from '../../../../../texts/useTexts'
import CircularProgressWithPercentage from '../../../CircularProgressWithPercentage'
import ContinueButton from '../ContinueButton'
import styles from './successStepWithTimeout.styles'
import mediasData from './mediasData.json'

const successStepWithTimeoutPropTypes = {
  onContinue: PropTypes.func.isRequired,
}

const SuccessStepWithTimeout = ({ onContinue }) => {
  const texts = useTexts()

  const [isCountdownOver, setIsCountdownOver] = useState(false)

  return (
    <Box sx={styles.root}>
      <Typography sx={styles.description}>
        {!isCountdownOver
          ? texts.getSuccessRegisterLoadingSubtitle()
          : texts.getSuccessRegisterSubtitle()}
      </Typography>
      <CircularProgressWithPercentage
        height={styles.loader.height}
        onFinishedCountdown={() => setIsCountdownOver(true)}
      />
      <ContinueButton
        fullWidth
        withoutArrow={!isCountdownOver}
        disabled={!isCountdownOver}
        onClick={onContinue}
      >
        {texts.getAcceptCTA()}
      </ContinueButton>

      <Box sx={styles.mediasContainer}>
        <Typography sx={styles.description}>
          {texts.getSuccessRegisterMediaText1()}
        </Typography>
        <Typography sx={styles.description}>
          {texts.getSuccessRegisterMediaText2()}
        </Typography>
        <Box component='ul' sx={styles.mediasLogos}>
          {mediasData.mediaProviders.map((mediaData) => (
            <Box component='li' key={mediaData.id}>
              <img
                src={mediaData.imageUrl}
                alt={mediaData.name}
                width={120}
                height={28}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

SuccessStepWithTimeout.propTypes = successStepWithTimeoutPropTypes

export default SuccessStepWithTimeout
