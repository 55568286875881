import { useLocation, Navigate } from 'react-router-dom'

import { Session } from '../business'

const RequireAuthenticated = ({ children }) => {
  let location = useLocation()

  if (!Session.isAuthenticated()) {
    return <Navigate to='/' state={{ from: location }} replace />
  }
  return children
}

export default RequireAuthenticated
