export class UsernameAlreadyExistsError extends Error {
  constructor(message) {
    super(message)
    this.name = 'UsernameAlreadyExistsError'
  }
}

export class InvalidCredentialsError extends Error {
  constructor(message) {
    super(message)
    this.name = 'InvalidCredentialsError'
  }
}

export class CodeMismatchError extends Error {
  constructor(message) {
    super(message)
    this.name = 'CodeMismatchError'
  }
}

export class ExpiredCodeError extends Error {
  constructor(message) {
    super(message)
    this.name = 'ExpiredCodeError'
  }
}

export class LimitExceeded extends Error {
  constructor(message) {
    super(message)
    this.name = 'LimitExceeded'
  }
}
