import { Box, Typography } from '@mui/material'

import styles from './library.styles'

const Library = () => {
  return (
    <Box sx={styles.root}>
      <Typography variant='h1' sx={{ color: 'primary.main' }}>
        Librería
      </Typography>
    </Box>
  )
}

export default Library
