import { Session } from '../../business'
import PrivateLayout from './Private'
import PublicLayout from './Public'

const DynamicLayout = ({ children }) => {
  const Layout = Session.isAuthenticated() ? PrivateLayout : PublicLayout
  return <Layout>{children}</Layout>
}

export default DynamicLayout
