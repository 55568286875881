import { Box } from '@mui/material'
import { Player } from '@lottiefiles/react-lottie-player'

import animationSource from './logo-animation.json'
import styles from './eye.styles'

const Eye = ({ isVisible }) => {
  return (
    <Box
      aria-hidden
      sx={{
        ...styles.root,
        ...(isVisible ? styles.visible : styles.nonVisible),
      }}
    >
      <Player autoplay loop src={animationSource} />
    </Box>
  )
}

export default Eye
