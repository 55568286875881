import EnterEmailStep from './EnterEmailStep'
import EnterCodeAndNewPasswordStep from './EnterCodeAndNewPasswordStep'
import useForgottenPasswordFlow from './useForgottenPasswordFlow'
import Layout from './Layout'
import SSOLayout from './SSOLayout'

const ForgottenPasswordComponentWithVariableLayout = ({
  onCancel,
  goToLogin,
  isSSOLayout,
  layoutProps = {},
}) => {
  const {
    stepIndex,
    userEmail,
    handleContinueToSecondStep,
    handleReturnToFirstStep,
    stepsLayoutProps,
  } = useForgottenPasswordFlow()

  const LayoutComponent = isSSOLayout ? SSOLayout : Layout

  const handleCancle = () => {
    document.dispatchEvent(
      new CustomEvent('leaveForgottenPassword', {
        detail: stepsLayoutProps.stepName,
      }),
    )

    onCancel()
  }

  return (
    <div aria-label='restablecer-contraseña'>
      <LayoutComponent
        onCancel={handleCancle}
        stepsLayoutProps={stepsLayoutProps}
        {...layoutProps}
      >
        {stepIndex === 0 ? (
          <EnterEmailStep
            isDarkMode={!isSSOLayout}
            emailDefaultValue={userEmail}
            onContinue={handleContinueToSecondStep}
            goToLogin={goToLogin}
          />
        ) : (
          <EnterCodeAndNewPasswordStep
            isDarkMode={!isSSOLayout}
            email={userEmail}
            onClickResendCode={handleReturnToFirstStep}
            onSuccess={goToLogin}
          />
        )}
      </LayoutComponent>
    </div>
  )
}

// NOTE: this equivalence is possible thanks to the default values of ForgottenPasswordComponentWithVariableLayout's props:
// isSSOLayout= false, layoutProps= {}
export const ForgottenPasswordComponent =
  ForgottenPasswordComponentWithVariableLayout

export const SSOForgottenPasswordComponent = ({
  loading,
  logoUrl,
  customTitle,
  customDescription,
  ...restProps
}) => (
  <ForgottenPasswordComponentWithVariableLayout
    {...restProps}
    isSSOLayout
    layoutProps={{ loading, logoUrl, customTitle, customDescription }}
  />
)
