import { Box } from '@mui/material'

import styles from './footer.styles'

const Wrapper = ({ isSended, children }) => {
  return (
    <Box
      component='footer'
      sx={isSended ? styles.light : styles.dark}
      aria-live='polite'
    >
      {children}
    </Box>
  )
}

export default Wrapper
