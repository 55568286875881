const handleRedsysMessageEvent = (event) => {
  // NOTE: Redsys-defined method with the signature expecting the following params:
  // - event returned when Redsys deliver the 'message' event to the window
  // - id of the element where the value of the operation-id will be stored
  // - id of the element where the value of the error-code will be stored
  window.storeIdOper(event, 'token', 'errorCode', () => true)

  //   if (Boolean(event.data) && event.data === 'merchantValidation') {
  //     // handle merchant validation
  //   }
  if (
    Boolean(event.data) &&
    (Boolean(event.data?.idOper) || Boolean(event.data?.error))
  ) {
    return event.data
  }
  return {}
}

export default handleRedsysMessageEvent
