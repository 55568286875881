import { useLayoutEffect } from 'react'

const ScrollToTop = ({ children }) => {
  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })

    return () => null
  }, [])

  return <>{children}</>
}

export default ScrollToTop
