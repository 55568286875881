import theme from '../../../../theme'

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',

    '&::after': {
      content: '""',
      display: 'block',
      width: '100vw',
      height: (theme) => theme.spacing(21),
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: -1,
      backgroundColor: 'neutral.200',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: (theme) => `min(100%, ${theme.spacing(28.75)})`,
    pt: {
      xs: 2.5,
      md: 3.5,
    },
    px: {
      xs: 1.25,
      md: 3,
    },
    pb: {
      xs: 4,
      md: 5,
    },
  },
  title: {
    ...theme.typography.h5,
    mb: 1,
    color: 'neutral.700',
  },
  text: {
    ...theme.typography.body2,
    color: 'neutral.500',
  },
  wrapBankCard: {
    display: 'flex',
    justifyContent: 'center',
    my: 2,
  },
  wrapActions: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 0.5,
  },
  //
  cta: {},
}

export default styles
