import { DocumentCookies } from '../App/utils'

const MAGIC_URL = process.env.REACT_APP_PAYPER_MAGIC_URL
const PAYPER_MAGIC_COOKIE = {
  key: 'payper_magic_auth',
}

export const isActive = () =>
  Boolean(new DocumentCookies().get(PAYPER_MAGIC_COOKIE.key))

export const redirect = (mediaUrl) => {
  const magicUrl = MAGIC_URL
    ? `${MAGIC_URL}/magic-browse?url=${encodeURI(mediaUrl)}`
    : mediaUrl
  window.location = magicUrl
}
