import { useState, useCallback } from 'react'

import { useTexts } from '../texts'
import { Auth } from '../business'

const getFieldsErrors = (formValues, formValidationConfig) => {
  let fieldErrors = {}

  for (const [fieldName, fieldConfig] of Object.entries(formValidationConfig)) {
    fieldErrors[fieldName] = fieldConfig(formValues[fieldName])
  }
  return fieldErrors
}

const hasFieldErrors = (fieldErrors) =>
  Object.values(fieldErrors).some((value) => value !== '')

const DEFAULT_STATE = {
  isSubmitting: false,
  formValues: {},
  formError: '',
  fieldErrors: {},
  submitStatus: null,
  result: null,
}

const useFormV2 = (
  formId,
  fetchFn,
  formValidationConfig,
  initialValues = {},
) => {
  const texts = useTexts()
  const [status, setStatus] = useState({
    ...DEFAULT_STATE,
    formValues: initialValues,
  })

  const isSubmitSuccess = useCallback(() => {
    return status.submitStatus === 'success'
  }, [status.submitStatus])

  const isSubmitDone = useCallback(() => {
    return status.submitStatus !== null
  }, [status.submitStatus])

  const submitForm = async (formValues) => {
    setStatus((status) => ({
      ...DEFAULT_STATE,
      isSubmitting: true,
      formValues,
    }))

    const fieldErrors = getFieldsErrors(formValues, formValidationConfig)

    if (hasFieldErrors(fieldErrors)) {
      document.dispatchEvent(
        new CustomEvent('payperAnalyticsPublicEvent', {
          detail: {
            name: formId,
            status: 'error',
            field_errors: fieldErrors,
          },
        }),
      )
      setStatus((status) => ({ ...status, fieldErrors, isSubmitting: false }))
      return
    }

    try {
      const result = await fetchFn(formValues)
      setStatus((status) => ({
        ...status,
        submitStatus: 'success',
        isSubmitting: false,
        result,
      }))

      const detail = {
        name: formId,
        status: 'success',
      }
      if (Boolean(result?.sub)) {
        detail.userSub = result.sub
      }
      document.dispatchEvent(
        new CustomEvent('payperAnalyticsPublicEvent', {
          detail,
        }),
      )
    } catch (error) {
      let errorMessage = error.message

      if (error instanceof Auth.CodeMismatchError) {
        errorMessage = texts.getCodeMismatchError()
      }
      if (error instanceof Auth.ExpiredCodeError) {
        errorMessage = texts.getExpiredCodeError()
      }

      setStatus((status) => ({
        ...status,
        submitStatus: 'failure',
        isSubmitting: false,
        formError: errorMessage,
      }))

      document.dispatchEvent(
        new CustomEvent('payperAnalyticsPublicEvent', {
          detail: {
            name: formId,
            status: 'error',
            form_error: errorMessage,
          },
        }),
      )
    }
  }

  const resetState = () => setStatus(DEFAULT_STATE)

  const updateFieldForm = (fieldName, fieldValue) => {
    setStatus((status) => ({
      ...status,
      formValues: {
        ...status.formValues,
        [fieldName]: fieldValue,
      },
    }))
  }

  return {
    status,
    isSubmitSuccess,
    isSubmitDone,
    submitForm,
    resetState,
    updateFieldForm,
  }
}

export default useFormV2
