const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 0.625,
    pl: 0.75,
    color: 'info.main',
  },
  icon: {
    display: 'flex',
    fontSize: (theme) => theme.spacing(1.25),
  },
  text: {
    fontSize: (theme) => ({
      xs: theme.spacing(0.75),
      lg: theme.spacing(0.75),
    }),
    fontWeight: 500,
    lineHeight: 1.42,
  },
}

export default styles
