import { getRedsysDSResponseMessageTranslation } from '../../../business/user'

export const obtain3DSBrowserInfo = () => {
  return {
    browserLanguage: navigator.language,
    browserColorDepth: '24',
    browserScreenHeight: '1440',
    browserScreenWidth: '2560',
    browserTZ: new Date().getTimezoneOffset().toString(),
    browserJavaEnabled: navigator.javaEnabled(),
  }
}

export const waitForIframeToPostMessage = async (source, timeout) => {
  const waitPromise = new Promise((resolve) => {
    const onMessage = (event) => {
      const isSuccess = event.data?.success
      const eventSource = event.data?.source
      const isTrustedOrigin = eventSource === source

      if (isTrustedOrigin && isSuccess) {
        window.removeEventListener('message', onMessage)
        resolve({ success: true })
      }

      if (isTrustedOrigin && !isSuccess) {
        window.removeEventListener('message', onMessage)
        console.log('iframeError', event.data)
        resolve({
          success: false,
          code: event.data?.code,
          message: getRedsysDSResponseMessageTranslation(event.data?.message),
        })
      }
    }

    window.addEventListener('message', onMessage)
  })

  if (!timeout) {
    return waitPromise
  }

  const timeoutPromise = new Promise((resolve) =>
    setTimeout(() => resolve({ success: true }), timeout),
  )

  return Promise.race([waitPromise, timeoutPromise])
}
