import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Session, User } from '../../../business'

const ChromeExtensionUninstalled = () => {
  const navigate = useNavigate()

  useEffect(() => {
    if (Session.isAuthenticated()) {
      const searchParams = new URLSearchParams(window.location.search)
      const version = searchParams.get('version')

      User.updateChromeExtInstallationStatus({ isInstalled: false, version })
    }
    navigate('/dashboard')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default ChromeExtensionUninstalled
