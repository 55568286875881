const MuiTypography = {
  defaultProps: {
    variantMapping: {
      subtitle1: 'h4',
      subtitle2: 'h5',
    },
  },
}

export default MuiTypography
