import { Outlet } from 'react-router-dom'
import { Box } from '@mui/material'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
import SearchIcon from '@mui/icons-material/Search'

import { useTexts } from '../../../texts'
import { Avatar, MobileBottomNavigation } from '../../components'
import ScrollRevisionOnPageChange from '../../ScrollRevisionOnPageChange'
import { User } from '../../../business'

const styles = {
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'neutral.200',
    '&:has([aria-labelledby])': {
      display: 'flex',
      flexDirection: 'column',
    },
    '--large-width': (theme) => `min(100%, ${theme.spacing(40)})`,
    '--medium-width': (theme) => `min(100%, ${theme.spacing(28.75)})`,
  },
}

const LayoutView = ({ children }) => {
  const texts = useTexts()

  const {
    state: { data: user },
  } = User.usePersonalData()

  const actions = [
    {
      icon: <SearchIcon />,
      label: texts.search(),
      path: '/v2/discovery',
    },
    {
      icon: <LibraryBooksIcon />,
      label: texts.library(),
      path: '/v2/library',
    },
    {
      icon: <Avatar user={user} size={25} />,
      label: user?.name ?? texts.myAccount(),
      path: '/v2/account',
    },
  ]

  return (
    <ScrollRevisionOnPageChange>
      <Box component='main' aria-label='payper-v2' sx={styles.root}>
        <Outlet />
        {children}
      </Box>
      <MobileBottomNavigation actions={actions} />
    </ScrollRevisionOnPageChange>
  )
}

const Layout = ({ children }) => {
  return (
    <User.PersonalDataProvider>
      <User.BalanceProvider>
        <User.BankCardProvider>
          <LayoutView>{children}</LayoutView>
        </User.BankCardProvider>
      </User.BalanceProvider>
    </User.PersonalDataProvider>
  )
}

export default Layout
