import { useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { BottomNavigation, BottomNavigationAction } from '@mui/material'

import styles from './mobileBottomNavigation.styles'

const getInitialActionIndex = (actions) => {
  const actionIndexFromUrl = actions.findIndex(
    (action) => action.path === window.location.pathname,
  )
  return Boolean(actionIndexFromUrl) ? actionIndexFromUrl : 0
}

const MobileBottomNavigationPropTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.element.isRequired,
      label: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    }),
  ),
}

const MobileBottomNavigation = ({ actions }) => {
  const navigate = useNavigate()

  const [navIndex, setNavIndex] = useState(getInitialActionIndex(actions))

  return (
    <BottomNavigation
      showLabels
      value={navIndex}
      onChange={(_, newNavIndex) => {
        setNavIndex(newNavIndex)
        navigate(actions[newNavIndex].path)
      }}
      sx={styles.root}
    >
      {actions.map((action, i) => (
        <BottomNavigationAction
          key={i}
          label={action.label}
          icon={action.icon}
        />
      ))}
    </BottomNavigation>
  )
}
MobileBottomNavigation.propTypes = MobileBottomNavigationPropTypes

export default MobileBottomNavigation
