import { useState } from 'react'

const ENTER_EMAIL_STEP_PROPS = {
  stepName: 'forgotten-password-enter-email',
  title: (texts) => texts.getForgottenPasswordEnterEmailTitleLabel(),
  description: (texts) =>
    texts.getForgottenPasswordEnterEmailDescriptionLabel(),
}

const ENTER_CODE_AND_NEW_PASSWORD_STEP_PROPS = {
  stepName: 'forgotten-password-enter-code-and-password',
  title: (texts) => texts.getForgottenPasswordEnterCodeTitleLabel(),
  description: (texts) => texts.getForgottenPasswordEnterCodeDescriptionLabel(),
}

const STEPS_PROPS = {
  0: ENTER_EMAIL_STEP_PROPS,
  1: ENTER_CODE_AND_NEW_PASSWORD_STEP_PROPS,
}

const LAYOUT_COMMON_PROPS = {
  caption: (texts) => texts.getForgottenPasswordCaptionLabel(),
  stepperStepsLenght: 2,
}

const useForgottenPasswordFlow = () => {
  const [stepIndex, setStepIndex] = useState(0)
  const [userEmail, setUserEmail] = useState()

  const handleContinueToSecondStep = (userEmail) => {
    setUserEmail(userEmail)
    setStepIndex(1)
  }

  const handleReturnToFirstStep = () => setStepIndex(0)

  return {
    stepIndex,
    userEmail,
    handleContinueToSecondStep,
    handleReturnToFirstStep,
    stepsLayoutProps: {
      ...STEPS_PROPS[stepIndex],
      ...LAYOUT_COMMON_PROPS,
      currentStep: stepIndex,
    },
  }
}

export default useForgottenPasswordFlow
