import theme from '../../../theme'

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',

    '&::after': {
      content: '""',
      display: 'block',
      width: '100vw',
      height: (theme) => theme.spacing(10.5),
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      zIndex: -1,
      backgroundColor: (theme) =>
        `var(--after-backgroundColor, ${theme.palette.neutral[200]})`,
    },
  },
  isOutdated: {
    '--after-backgroundColor': (theme) => theme.palette.error.lightest,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: {
      xs: 2,
      md: 2.5,
    },
    width: (theme) => `min(100%, ${theme.spacing(28.75)})`,
    pt: {
      xs: 2.5,
      md: 3.5,
    },
    px: {
      xs: 1.25,
      md: 2.5,
    },
    pb: {
      xs: 4,
      md: 5,
    },
  },
  wrap: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 2,
  },
  title: {
    ...theme.typography.h5,
    color: 'neutral.700',
  },
}

export default styles
