import { useEffect } from 'react'
import { Box } from '@mui/material'

import EVENTS_NAMES from '../../analytics/eventsNames.json'
import { useTexts } from '../../texts'
import { Banner } from '.'
import articleCardStyles from './ArticleCard/articleCard.styles'
import { browser, openExternalLink } from '../utils'
import {
  chromeExtension,
  nativeApps,
  payperMagic,
} from '../../thirdPartyIntegration'
const {
  CE_AVAILABILITY,
  DOWNLOAD_CHROME_URL,
  PAYPER_CWS_URL,
  useChromeExtensionAvailability,
} = chromeExtension
const { isEmbeddedInNativeApp } = nativeApps

const styles = {
  root: {
    mx: articleCardStyles.card.px,
  },
}

const ChromeExtBanner = () => {
  const texts = useTexts()
  const ceAvailability = useChromeExtensionAvailability()

  const BANNER_PROPS_BY_CE_AVAILABILITY_TYPE = {
    [CE_AVAILABILITY.NOT_DESKTOP]: {
      content: [texts.getCEBannerNotDesktopText()],
    },
    [CE_AVAILABILITY.DESKTOP_NOT_AVAILABLE]: {
      content: [
        texts.getCEBannerDesktopNotAvailableText1(browser.name()),
        texts.getCEBannerDesktopNotAvailableText2(),
      ],
      action: {
        label: texts.getCEBannerDesktopNotAvailableAction(),
        onClick: () => {
          openExternalLink(DOWNLOAD_CHROME_URL)

          document.dispatchEvent(
            new CustomEvent('payperAnalyticsPrivateEvent', {
              detail: {
                name: EVENTS_NAMES.PD_CHROME_EXT_INSTALL_BANNER,
                eventType: 'click',
                bannerType: CE_AVAILABILITY.DESKTOP_NOT_AVAILABLE,
                browser: browser.name,
              },
            }),
          )
        },
      },
    },
    [CE_AVAILABILITY.DESKTOP_AVAILABLE]: {
      content: [texts.getCEBannerDesktopAvailableText()],
      action: {
        label: texts.getCEBannerDesktopAvailableAction(),
        onClick: () => {
          openExternalLink(PAYPER_CWS_URL)

          document.dispatchEvent(
            new CustomEvent('payperAnalyticsPrivateEvent', {
              detail: {
                name: EVENTS_NAMES.PD_CHROME_EXT_INSTALL_BANNER,
                eventType: 'click',
                bannerType: CE_AVAILABILITY.DESKTOP_AVAILABLE,
                browser: browser.name,
              },
            }),
          )
        },
      },
    },
  }

  useEffect(() => {
    if (!!ceAvailability && ceAvailability !== CE_AVAILABILITY.ACTIVE) {
      document.dispatchEvent(
        new CustomEvent('payperAnalyticsPrivateEvent', {
          detail: {
            name: EVENTS_NAMES.PD_CHROME_EXT_INSTALL_BANNER,
            eventType: 'visibility',
            bannerType: ceAvailability,
            browser: browser.name,
          },
        }),
      )
    }
  }, [ceAvailability])

  if (
    !Boolean(ceAvailability) ||
    !Boolean(BANNER_PROPS_BY_CE_AVAILABILITY_TYPE[ceAvailability]) ||
    isEmbeddedInNativeApp() ||
    payperMagic.isActive()
  )
    return null
  return (
    <Box sx={styles.root}>
      <Banner {...BANNER_PROPS_BY_CE_AVAILABILITY_TYPE[ceAvailability]} />
    </Box>
  )
}

export default ChromeExtBanner
