import theme from '../../../../../theme'

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    pt: 1.25,
    px: 1.25,
    pb: 3,
    backgroundColor: 'neutral.700',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 2.5,
    width: (theme) => `min(100%, ${theme.spacing(22.5)})`,
    mt: {
      md: 3.75,
    },
    ml: 'auto',
    mr: 'auto',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: 1.25,
  },
  heading: {
    ...theme.typography.h4,
    color: 'neutral.100',
  },
  closeButton: {
    position: {
      xs: 'relative',
      md: 'absolute',
    },
    top: (theme) => ({
      xs: theme.spacing(-0.5),
      md: theme.spacing(1.25),
    }),
    right: (theme) => ({
      md: theme.spacing(2),
    }),

    '& .MuiIconButton-root': {
      p: 0.5,
      fontSize: (theme) => theme.spacing(1.5),
      color: 'neutral.100',
    },
  },
  body: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 2,
  },
  wrapFields: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 1,
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 1,
  },
  lastAction: {
    display: 'flex',
    justifyContent: 'center',
    mt: 1,
  },
}

export default styles
