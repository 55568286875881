import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import View from './View'

const Appbar = () => {
  const navigate = useNavigate()
  const navigateWithSearchParams = (to) => navigate(to + window.location.search)

  const [scrollPosition, setScrollPosition] = useState('-100%')

  useEffect(() => {
    const onScroll = () => {
      let currentPosition = Math.round(
        (window.pageYOffset * 100) /
          (window.document.body.getBoundingClientRect().height -
            window.innerHeight),
      )

      setScrollPosition(`${currentPosition - 100}%`)
    }

    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  return (
    <View
      onGoToAccess={() => navigateWithSearchParams('/access')}
      scrollPosition={scrollPosition}
    />
  )
}

export default Appbar
