const styles = {
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: {
      md: 0.5,
      lg: 1,
    },
    color: 'neutral.100',
  },
  menuButton: {
    backgroundColor: 'transparent',
    columnGap: 0.5,
    py: {
      xs: 0.75,
      md: 0.75,
      lg: 1,
    },
    px: {
      xs: 1,
      md: 1.5,
    },
    borderTopRightRadius: (theme) => ({
      xs: 0,
      md: theme.spacing(0.5),
    }),
    borderBottomRightRadius: (theme) => ({
      xs: 0,
      md: theme.spacing(0.5),
    }),

    '&:hover, &:focus-visible': {
      backgroundColor: 'transparent',
    },

    '& .MuiButton-endIcon': {
      ml: 0,
      '& :nth-of-type(1)': {
        fontSize: 16,
      },
    },
  },
  logout: {
    width: (theme) => theme.spacing(2.5),
    height: (theme) => theme.spacing(2.5),
    color: 'neutral.500',
    backgroundColor: 'transparent',

    '&:hover, &:focus-visible': {
      backgroundColor: 'neutral.200',
      '--circle-backgroundColor': (theme) => theme.palette.neutral[300],
    },
  },
}

export default styles
