import PropTypes from 'prop-types'

import { useTexts } from '../../../../texts'
import Response from './Response'
import imageAvif from './coffee-spilling-out-of-mug.avif'
import imageWebp from './coffee-spilling-out-of-mug.webp'
import imagePng from './coffee-spilling-out-of-mug.png'

const errorViewProptypes = {
  onClickRetry: PropTypes.func,
}

const ErrorView = ({ onClickRetry }) => {
  const texts = useTexts()

  return (
    <Response
      width={418}
      height={367}
      title={texts.getContactFormTitleError()}
      message={texts.getContactFormDescriptionError()}
      image={{
        imageAvif,
        imageWebp,
        imagePng,
      }}
      ctaText={texts.getContactFormSendSuccessAction()}
      onClickCta={onClickRetry}
    />
  )
}

ErrorView.propTypes = errorViewProptypes

export default ErrorView
