import theme from '../../../theme'

const styles = {
  root: {
    '& .MuiPaper-root': {
      alignSelf: {
        xs: 'flex-start',
        md: 'center',
      },
      rowGap: 1,
      width: '100vw',
      maxWidth: (theme) => ({
        xs: '100vw',
        md: theme.spacing(40),
      }),
      pt: { xs: 2, md: 3 },
      px: { xs: 2, md: 3 },
      pb: { xs: 2.5, md: 3 },
      m: 0,
      height: 'auto',
      borderTopLeftRadius: (theme) => ({ md: theme.spacing(2) }),
      borderTopRightRadius: (theme) => ({ md: theme.spacing(2) }),
      borderBottomLeftRadius: (theme) => theme.spacing(2),
      borderBottomRightRadius: (theme) => theme.spacing(2),
    },
    '& button': {
      fontWeight: 700,
    },
  },
  closeButton: {
    alignSelf: 'flex-end',
    mt: (theme) => ({
      xs: theme.spacing(-0.5),
      md: theme.spacing(-1.5),
    }),
    mr: (theme) => ({
      xs: theme.spacing(-0.5),
      md: theme.spacing(-1.5),
    }),

    '& .MuiIconButton-root': {
      p: 0.5,
      fontSize: (theme) => theme.spacing(1.5),
      color: 'var(--heading)',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 1.5,
  },
  title: {
    ...theme.typography.h4,
    color: 'neutral.700',
    mb: { xs: 1, md: 0 },
  },
  centered: {
    mx: 'auto',
  },
}

export default styles
