const styles = {
  root: {
    counterReset: 'step',
    my: 0,
    pl: 0,
  },
  panel: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'var(--panel-backgroundColor)',
  },
  amountPanel: {
    '--panel-backgroundColor': (theme) => theme.palette.neutral[200],
    boxShadow: 3,
    position: 'relative',

    '&::after': {
      content: {
        md: '""',
      },
      display: 'block',
      width: (theme) => theme.spacing(1.5),
      height: (theme) => theme.spacing(1.5),
      backgroundColor: 'inherit',
      position: 'absolute',
      left: '50%',
      bottom: (theme) => theme.spacing(-0.75),
      transform: 'translateX(-50%) rotate(45deg)',
      boxShadow:
        '0px 1px 0px rgb(156 156 156 / 20%), 1px 0px 0px rgb(156 156 156 / 20%)',
    },
  },
  cardPanel: {
    '--panel-backgroundColor': (theme) => theme.palette.neutral[100],
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 2,
    width: (theme) => `min(100%, ${theme.spacing(28.75)})`,
    pt: { xs: 1.5, md: 3.5 },
    px: 1.25,
    pb: { xs: 2, md: 3.5, lg: 4.5 },
  },
  heading: {
    display: 'flex',
    columnGap: 0.625,
    alignItems: 'center',

    '&::before': {
      counterIncrement: 'step',
      content: 'counter(step)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: (theme) => theme.spacing(1.5),
      height: (theme) => theme.spacing(1.5),
      fontSize: (theme) => theme.spacing(0.875),
      fontWeight: 500,
      lineHeight: 2,
      borderRadius: '50%',
      backgroundColor: 'primary.main',
    },
  },
  action: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 1.5,
  },
  logos: {
    display: 'flex',
    justifyContent: 'center',
    filter: 'grayscale(1)',
  },
}

export default styles
