import { useTexts } from '../../../../../texts'
import { useFormV2, composeValidators, validators } from '../../../../../forms'
import { Auth, User } from '../../../../../business'

const usePasswordForm = (formId) => {
  const texts = useTexts()
  const { isFilled } = validators

  const FORM_VALIDATION_CONFIG = {
    email: composeValidators([[isFilled, texts.getErrorRequired()]]),
    password: composeValidators([[isFilled, texts.getErrorRequired()]]),
  }

  const getIsNewUser = async (formValues) => {
    try {
      return await Auth.login(formValues.email, formValues.password)
    } catch (error) {
      if (error instanceof Auth.InvalidCredentialsError) {
        throw new Error(texts.getLoginInvalidCredentialsError())
      }
      if (error instanceof User.InvalidUserError) {
        throw new Error(texts.getLoginInvalidUserError())
      }
      throw new Error(texts.getUnexpectedError())
    }
  }

  return useFormV2(formId, getIsNewUser, FORM_VALIDATION_CONFIG)
}

export default usePasswordForm
