import baseTheme from '../base'

const MuiFilledInput = {
  defaultProps: {
    disableUnderline: true,
  },
  styleOverrides: {
    root: {
      borderRadius: baseTheme.shape.borderRadius,
      color: baseTheme.palette.neutral[100],
      backgroundColor: baseTheme.palette.neutral[500],
      border: `1px solid transparent`,
      boxShadow: baseTheme.shadows[0],
      transition: 'all 300ms',

      '&:hover': {
        boxShadow: baseTheme.shadows[3],
      },
      '&.MuiInputBase-multiline': {
        paddingTop: 8,

        '& .MuiInputAdornment-root': {
          transform: `translateY(${baseTheme.spacing(-3.875)})`,
        },
      },
    },
    input: {
      height: baseTheme.spacing(1.875),
      paddingTop: baseTheme.spacing(1.375),
      paddingBottom: baseTheme.spacing(0.75),
      fontWeight: 500,
    },
  },
}

export default MuiFilledInput
