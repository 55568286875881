import { createTheme } from '@mui/material/styles'

// NOTE: accessing to createBreakpoints is not recommended since it is a private module
// https://stackoverflow.com/questions/68554470/was-the-createbreakpoints-method-removed-in-material-version-5
const theme = createTheme()

const typography = {
  fontFamily: ['Inter', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
  h1: {
    fontFamily: 'ClashDisplay-Semibold',
    fontSize: theme.typography.pxToRem(42),
    fontWeight: 600,
    lineHeight: 1,
    letterSpacing: '0.02em',

    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(72),
    },
  },
  h2: {
    fontFamily: 'ClashDisplay-Semibold',
    fontSize: theme.typography.pxToRem(32),
    fontWeight: 600,
    lineHeight: 1.1,
    letterSpacing: '0.02em',

    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(52),
    },
  },
  h3: {
    fontFamily: 'ClashDisplay-Medium',
    fontSize: theme.typography.pxToRem(34),
    fontWeight: 500,
    lineHeight: 1.23,
  },
  h4: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    lineHeight: 1.2,

    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(24),
    },
  },
  h4_clash: {
    fontFamily: 'ClashDisplay-Medium',
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 700,
    lineHeight: 1.2,
  },
  h5: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,
    lineHeight: 1.2,

    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(20),
    },
  },
  h5b: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 600,
    lineHeight: 1.2,
  },
  h6: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 600,
    lineHeight: 1.5,

    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  body1: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    lineHeight: 1.5,
  },
  body2: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    lineHeight: 1.5,

    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  body3: {
    fontSize: theme.typography.pxToRem(10),
    fontWeight: 400,
    lineHeight: theme.typography.pxToRem(16),
    textTransform: 'uppercase',

    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(12),
    },
  },
  title1: {
    fontFamily: 'ClashDisplay-Medium',
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 500,
    lineHeight: theme.typography.pxToRem(32),
  },
  title2: {
    fontFamily: 'ClashDisplay-Medium',
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 500,
    lineHeight: theme.typography.pxToRem(28),
    letterSpacing: theme.typography.pxToRem(0.15),
  },
  title3: {
    fontFamily: 'ClashDisplay-Medium',
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 500,
    lineHeight: theme.typography.pxToRem(23.4),
    letterSpacing: theme.typography.pxToRem(0.15),
  },
  title4: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 400,
    lineHeight: theme.typography.pxToRem(22),
    letterSpacing: theme.typography.pxToRem(0.15),
  },
  title7: {
    fontFamily: 'ClashDisplay-Medium',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 500,
    lineHeight: theme.typography.pxToRem(16),
    letterSpacing: theme.typography.pxToRem(0.15),
  },
  subtitle1: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 400,
    lineHeight: 1.2,
  },
  subtitle2: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    lineHeight: theme.typography.pxToRem(18),
    margin: 'initial',
  },
  p: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 400,
    lineHeight: theme.typography.pxToRem(16.8),
  },
}

export default typography
