import theme from '../../../../../theme'

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: 1,
    width: (theme) => `min(100%, ${theme.spacing(28.5)})`,
    mx: 'auto',
    pt: { xs: 1.5, md: 3.5 },
    px: 1.25,
    pb: { xs: 2, md: 3.5, lg: 4.5 },
  },
  description: {
    ...theme.typography.h5,
    color: 'neutral.700',
  },
  wrapPicture: {
    mt: -2,
    position: 'relative',
  },
  pictureText: {
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: (theme) => ({
      xs: theme.spacing(9.375),
      sm: '55%',
    }),
    height: (theme) => ({
      xs: theme.spacing(6.875),
      sm: '55%',
    }),
    fontSize: (theme) => ({
      xs: theme.spacing(1.25),
      md: theme.spacing(1.25),
    }),
    fontWeight: 600,
    color: 'neutral.700',
    textShadow: '0.5px 0.5px 1px rgba(0, 0, 0, 0.08)',
    position: 'absolute',
    top: (theme) => `min(${theme.spacing(3.75)}, 24%)`,
    left: { xs: '50%', sm: '56%' },
    zIndex: 1,
    transform: {
      xs: 'translateX(-50%)',
      sm: 'translateX(-62.5%)',
    },

    '& > span': {
      display: 'block',
      fontSize: theme.spacing(0.875),
      fontWeight: 500,
      letterSpacing: '0.02em',
      textShadow: '0.5px 0.5px 1px rgba(255, 255, 255, 0.08)',
      color: 'neutral.500',
    },
  },
  action: {
    width: (theme) => `min(100%, ${theme.spacing(22.5)})`,
    mx: 'auto',

    '& .MuiButton-root': {
      width: '100%',
    },
  },
}

export default styles
