import { Typography, Box } from '@mui/material'

import { formatNumber, formatShortDate, formatHour } from '../../format'
import { useTexts } from '../../../texts'
import { Calendar } from '../../../styleguide/icons'

import styles from './bonus.styles'

const BonusCard = ({ bonusData, reducedVersion = false }) => {
  const texts = useTexts()

  return (
    <Box sx={styles.card}>
      <Box
        sx={{
          ...styles.info,
          ...(bonusData.type === 'free' && reducedVersion ? { pt: 2.25 } : {}),
          ...(reducedVersion
            ? {
                textAlign: {
                  xs: 'center',
                  md: 'left',
                },
                py: 1.25,
              }
            : {}),
        }}
      >
        {bonusData.type === 'balance' ? (
          <Typography sx={styles.amount}>
            {formatNumber(bonusData.detail.amount)} <span>€</span>
          </Typography>
        ) : null}
        <Typography
          sx={{
            ...styles.description,
            ...(reducedVersion
              ? {
                  fontWeight: 500,
                  fontSize: (theme) => theme.spacing(0.75),
                  pr: 0,
                }
              : {}),
          }}
        >
          {texts.getBonusCardLabel()}
        </Typography>
      </Box>
      <Box sx={styles.expiration}>
        {!reducedVersion && <Calendar />}
        <Typography
          sx={{
            ...styles.expirationText,
            ...(reducedVersion
              ? {
                  justifyContent: 'center',
                }
              : {}),
          }}
        >
          {texts.getExpirationLabel(
            formatShortDate(bonusData.expiration_date),
            formatHour(bonusData.expiration_date),
          )}
        </Typography>
      </Box>
    </Box>
  )
}

export default BonusCard
