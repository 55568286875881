import { useLocation, useNavigate } from 'react-router-dom'

import useSSOPartner from './useSSOPartner'
import useOnLoadSSO from './useOnLoadSSO'
import { SSOForgottenPasswordComponent } from '../../../components'

const SSOForgottenPasswordPartner = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const ssoPartner = useSSOPartner()
  const unauthenticationIsConfirmed = useOnLoadSSO(
    ssoPartner.getDataForAnalytics,
    ssoPartner.redirect,
    ssoPartner.getRedirectOnUnauthUri(),
  )

  const handleGoToLogin = async () => {
    navigate(`/sso-login-partner${location.search}`)
  }

  const cancel = () => {
    ssoPartner.redirectOnUnauth()
  }

  return (
    <SSOForgottenPasswordComponent
      loading={!unauthenticationIsConfirmed}
      logoUrl={ssoPartner.getPartnerLogoUrl()}
      customTitle={ssoPartner.getPartnerTitle()}
      customDescription={ssoPartner.getPartnerDescription()}
      onCancel={cancel}
      goToLogin={handleGoToLogin}
    />
  )
}

export default SSOForgottenPasswordPartner
