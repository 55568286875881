import { forwardRef, useRef, useEffect } from 'react'
import { Box, Button, Container, Typography } from '@mui/material'
import { Player } from '@lottiefiles/react-lottie-player'

import { useTexts } from '../../../../texts'
import useIntersectionObserver from '../../../useIntersectionObserver'
import { ReactComponent as ArrowRight } from './ArrowRight.svg'
import { ReactComponent as Check } from './Check.svg'
import animationSource from './logo-animation.json'
import styles from './hero.styles'

const HeroView = forwardRef(({ openRegister }, ref) => {
  const texts = useTexts()

  const ITEMS = [
    texts.getHomepageHeroItemOneLabel(),
    texts.getHomepageHeroItemTwoLabel(),
    texts.getHomepageHeroItemThreeLabel(),
  ]

  const targets = useRef(new Set())
  const [entries, setObservedNodes] = useIntersectionObserver({})

  useEffect(() => {
    setObservedNodes(() => [...targets.current])
  }, [setObservedNodes])

  useEffect(() => {
    for (const entry of entries) {
      if (entry.isIntersecting) {
        entry.target.classList.add('isVisible')

        setObservedNodes((observedNodes) =>
          observedNodes.filter((node) => node !== entry.target),
        )
      }
    }
  }, [entries, setObservedNodes])

  return (
    <Box component='section' sx={styles.root}>
      <Container>
        <Box sx={styles.content}>
          <Box sx={styles.info}>
            <Typography
              ref={(element) => targets.current.add(element)}
              variant='h1'
              sx={styles.heading}
              style={{ '--delay': '100ms' }}
            >
              {texts.getHomepageHeroTitleLabel()}
            </Typography>
            <Box
              component='ul'
              sx={styles.list}
              ref={(element) => targets.current.add(element)}
            >
              {ITEMS.map((item, idx) => (
                <Box
                  key={idx}
                  component='li'
                  sx={styles.item}
                  style={{ '--delay': `${90 * idx + 100}ms` }}
                >
                  <Box key={idx} sx={styles.icon} aria-hidden>
                    <Check />
                  </Box>
                  <Typography>{item}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
          <Box
            ref={(element) => targets.current.add(element)}
            sx={styles.wrapMedia}
            style={{ '--delay': '600ms' }}
          >
            <Box ref={ref} aria-hidden sx={styles.media}>
              <Player autoplay loop src={animationSource} />
            </Box>
          </Box>
          <Box
            ref={(element) => targets.current.add(element)}
            sx={styles.wrapCta}
            style={{ '--delay': '400ms' }}
          >
            <Button
              id='home-hero-register-btn'
              variant='contained'
              color='secondary'
              endIcon={<ArrowRight />}
              onClick={openRegister}
            >
              {texts.getHomepageRegisterAction()}
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  )
})

export default HeroView
