const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: 'primary.main',
    overflow: 'hidden',

    '& .isVisible': {
      opacity: 1,
      transform: 'none',

      '& > :is(li)': {
        opacity: 1,
        transform: 'none',
      },
    },
  },
  content: {
    width: (theme) => `min(100%, ${theme.spacing(90)})`,
    display: 'grid',
    gridTemplateColumns: {
      xs: '1fr',
      lg: '60% 1fr',
      xl: '65% 1fr',
    },
    gridTemplateAreas: {
      lg: '"a b" "c d"',
    },
    rowGap: 1.25,
    columnGap: {
      lg: '5%',
    },
    justifyItems: {
      xs: 'center',
      md: 'start',
    },
    px: {
      xl: 5.25,
    },
    pt: (theme) => ({
      xs: `min(${theme.spacing(8)}, 20vh)`,
      sm: `min(${theme.spacing(11)}, 20vh)`,
      md: 10,
      lg: 14,
    }),
    pb: {
      xs: 5,
      md: 8,
      lg: 12.5,
    },
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: {
      xs: 1.5,
      lg: 2,
    },
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 0.125,
    pl: 0,
    my: 0,
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 1,
    fontSize: (theme) => ({
      xs: theme.spacing(0.875),
      lg: theme.spacing(1.25),
    }),
    fontWeight: {
      xs: 500,
      lg: 400,
    },
    //
    transform: 'translateY(1rem)',
    opacity: 0,
    transition: 'transform 250ms ease-in, opacity 300ms ease-in',
    transitionDelay: 'var(--delay, 0)',
  },
  icon: {
    display: 'flex',
    fontSize: (theme) => ({
      xs: theme.spacing(1.5),
      lg: theme.spacing(2),
    }),
  },
  heading: {
    color: 'neutral.700',
    //
    transform: 'translateY(1rem)',
    opacity: 0,
    transition: 'transform 250ms ease-in, opacity 300ms ease-in',
    transitionDelay: 'var(--delay, 0)',
  },
  media: {
    order: {
      md: 1,
    },
    gridArea: {
      lg: 'b',
    },
    justifySelf: {
      md: 'center',
    },
    maxHeight: (theme) => theme.spacing(18.75),
    minWidth: (theme) => ({
      lg: theme.spacing(30),
    }),
    aspectRatio: '1920/1080',
    transform: {
      lg: 'translateX(-15%)',
    },
  },

  wrapMedia: {
    //
    opacity: 0,
    transition: 'opacity 300ms ease-in',
    transitionDelay: 'var(--delay, 0)',
  },

  wrapCta: {
    //
    transform: 'translateY(1rem)',
    opacity: 0,
    transition: 'transform 250ms ease-in, opacity 300ms ease-in',
    transitionDelay: 'var(--delay, 0)',
  },
}

export default styles
