import { createContext, useContext, useState, useEffect } from 'react'

import { fetchBankCard as fetchBankCardService } from './service'

const BankCardContext = createContext(null)

export const useBankCard = () => {
  const context = useContext(BankCardContext)
  if (context === null) {
    throw new Error('useBankCard must be used within a BankCardProvider')
  }
  return context
}

const initialState = {
  isLoading: true,
  data: undefined,
  error: false,
}

const BankCardProvider = ({ children }) => {
  const [state, setState] = useState(initialState)

  const fetchBankCard = async () => {
    try {
      const result = await fetchBankCardService()
      setState({
        isLoading: false,
        data: result,
        error: false,
      })
    } catch (error) {
      setState({ isLoading: false, data: null, error })
    }
  }

  useEffect(() => {
    ;(async () => await fetchBankCard())()
  }, [])

  return (
    <BankCardContext.Provider value={{ state, refetchBankCard: fetchBankCard }}>
      {children}
    </BankCardContext.Provider>
  )
}

export default BankCardProvider
