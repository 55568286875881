import { Box, Button } from '@mui/material'

import { useTexts } from '../../../../../texts'
import Picture from '../../../Picture'
import imageAvif from './coffee-spilling-out-of-mug.avif'
import imageWebp from './coffee-spilling-out-of-mug.webp'
import imagePng from './coffee-spilling-out-of-mug.png'
import styles from './errorStep.styles.js'

const ErrorStep = ({ onRetry, onCancelProcess }) => {
  const texts = useTexts()

  return (
    <Box sx={styles.root}>
      <Box sx={styles.wrapPicture}>
        <Picture
          width={418}
          height={367}
          avif={imageAvif}
          webp={imageWebp}
          fallback={imagePng}
        />
      </Box>
      <Box sx={styles.actions}>
        <Button fullWidth color='secondary' size='small' onClick={onRetry}>
          {texts.getPurchaseErrorRetryAction()}
        </Button>
        <Button fullWidth size='small' onClick={onCancelProcess}>
          {texts.getPurchaseErrorCancelProcessAction()}
        </Button>
      </Box>
    </Box>
  )
}

export default ErrorStep
