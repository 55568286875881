const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 2,
    color: 'neutral.700',
    position: 'relative',

    '&::before': {
      content: '""',
      display: 'block',
      width: '100vw',
      height: '100vh',
      position: 'absolute',
      top: (theme) => theme.spacing(3),
      left: 'calc(50% - 50vw)',
      zIndex: 0,
      backgroundColor: 'neutral.200',
    },
  },
  wrapPicture: {
    width: (theme) => `min(100%, ${theme.spacing(11.25)})`,
    alignSelf: 'center',
    position: 'relative',
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: 0.5,
  },
}

export default styles
