const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: (theme) => ({
      xs: theme.spacing(2),
      md: theme.spacing(3),
    }),
    aspectRatio: '1/1',
    position: 'fixed',
    right: (theme) => ({
      xs: 0,
      md: theme.spacing(1),
    }),
    bottom: (theme) => ({
      xs: 0,
      md: theme.spacing(1),
    }),
    zIndex: 1,

    '& > div': {
      display: 'flex',
      transform: 'scale(2)',
    },
  },
  visible: {
    visibility: 'visible',
    borderRadius: {
      md: '50%',
    },
    backgroundColor: 'primary.main',
  },
  nonVisible: {
    visibility: 'hidden',
  },
}

export default styles
