import { Link } from 'react-router-dom'
import { Box, Container, Typography, Link as MUILink } from '@mui/material'

import styles from '../PrivacyPolicy/privacyPolicy.styles'

const Bold = ({ children }) => (
  <Box component='strong' sx={{ display: 'inline', fontWeight: 'bold' }}>
    {children}
  </Box>
)
const ExternalLink = ({ children, url }) => (
  <MUILink href={url ?? children} target='_blank' rel='noopener'>
    {children}
  </MUILink>
)
const MailTo = ({ children }) => <a href={`mailto:${children}`}>{children}</a>

const LegalNotice = () => {
  window.location = 'https://www.bepayper.com/aviso-legal'

  return (
    <Box component='main'>
      <Container component='article' maxWidth='xl' sx={styles.container}>
        <Box component='header'>
          <Typography component='h1' variant='h3'>
            Aviso legal
          </Typography>
        </Box>
        <Box component='section'>
          <Typography component='h2' variant='h4'>
            Derecho de la información
          </Typography>
          <Typography>
            Le informamos que esta Plataforma{' '}
            <ExternalLink url='https://www.bepayper.com/'>
              www.bepayper.com
            </ExternalLink>{' '}
            (en adelante, la “<Bold>Plataforma</Bold>
            ”) es titularidad de MARTER 878, S.L. (en adelante, “
            <Bold>PAYPER</Bold>”), con C.I.F. B-09762998, y domicilio en Paseo
            Doctor Marañón, nº 7-1º D, 20009, San Sebastián (Guipúzcoa),
            inscrita en el Registro Mercantil de Gipuzkoa en el Tomo 3036, Folio
            38, Hoja SS-44.566.
          </Typography>
          <Typography>
            El acceso y/o uso de la Plataforma le atribuye la condición de
            usuario, y acepta, desde dicho acceso y/o uso, el presente Aviso
            Legal.
          </Typography>
          <Typography>
            El usuario (en adelante, el “<Bold>Usuario</Bold>”) puede ponerse en
            contacto con JINCANA a través de la siguiente dirección de correo
            electrónico: <MailTo>info@bepayper.com</MailTo>.
          </Typography>
        </Box>
        <Box component='section'>
          <Typography component='h2' variant='h4'>
            Uso de la Plataforma
          </Typography>
          <Typography>
            El Usuario asume la responsabilidad del uso de la Plataforma. La
            Plataforma puede proporcionar acceso a multitud de textos, gráficos,
            dibujos, diseños, fotografías, contenidos multimedia, e
            informaciones (en adelante, "<Bold>Contenidos</Bold>")
            pertenecientes a PAYPER o a terceros a los que el Usuario puede
            tener acceso.
          </Typography>
          <Typography>
            El Usuario se compromete a hacer un uso adecuado de los Contenidos y
            servicios ofrecidos a través de la Plataforma y con carácter
            enunciativo, pero no limitativo, a no emplearlos para:
          </Typography>
          <Box component='ol'>
            <Box component='li'>
              Incurrir en actividades ilícitas, ilegales o contrarias a la buena
              fe y al orden público.
            </Box>
            <Box component='li'>
              Provocar daños en los sistemas físicos y lógicos de la Plataforma
              de PAYPER, de sus proveedores o de terceras personas.
            </Box>
            <Box component='li'>
              Introducir o difundir en la red virus informáticos o cualesquiera
              otros sistemas físicos o lógicos que sean susceptibles de provocar
              los daños anteriormente mencionados.
            </Box>
            <Box component='li'>
              Intentar acceder, utilizar y/o manipular los datos de PAYPER,
              terceros proveedores y otros usuarios.
            </Box>
            <Box component='li'>
              Reproducir o copiar, distribuir, permitir el acceso del público a
              través de cualquier modalidad de comunicación pública, transformar
              o modificar los Contenidos, a menos que se cuente con la
              autorización de PAYPER.
            </Box>
            <Box component='li'>
              Suprimir, ocultar o manipular los Contenidos sujetos a derechos de
              propiedad intelectual o industrial y demás datos identificativos
              de dichos derechos de PAYPER o de terceros incorporados a los
              Contenidos, así como los dispositivos técnicos de protección o
              cualesquiera mecanismos de información que puedan insertarse en
              los Contenidos.
            </Box>
          </Box>
          <Typography>
            PAYPER advierte de que los materiales contenidos en esta Plataforma
            han sido incluidos a mero título informativo, por lo que resultan
            insuficientes para tomar decisiones o asumir posiciones en un caso
            concreto.
          </Typography>
          <Typography>
            El Usuario debe tener en cuenta que los materiales contenidos en
            esta Plataforma pudieran no reflejar el estado legislativo o
            jurisprudencial más reciente sobre las cuestiones analizadas.
            Asimismo, estos materiales pueden ser modificados, desarrollados o
            actualizados sin notificación previa.{' '}
          </Typography>
          <Typography>
            PAYPER tendrá derecho a investigar y denunciar cualquiera de las
            conductas mencionadas de acuerdo con la Ley, así como a colaborar
            con las autoridades en la investigación de dichas actuaciones.{' '}
          </Typography>
          <Typography>
            PAYPER podrá suspender temporalmente y sin previo aviso, la
            accesibilidad a la Plataforma con motivo de operaciones de
            mantenimiento, reparación, actualización o mejora. No obstante,
            siempre que las circunstancias lo permitan, PAYPER comunicará al
            Usuario, con antelación suficiente, la fecha prevista para la
            suspensión de los servicios. PAYPER no se responsabiliza del uso que
            los Usuarios puedan hacer de los Contenidos incluidos en la
            Plataforma.{' '}
          </Typography>
        </Box>
        <Box component='section'>
          <Typography component='h2' variant='h4'>
            Propiedad Intelectual e Industrial{' '}
          </Typography>
          <Typography>
            Todos y cada uno de los derechos de propiedad intelectual sobre el
            contenido y el diseño gráfico de esta Plataforma (a título
            enunciativo, imágenes, sonido, audio, vídeo, software o textos;
            marcas o logotipos, combinaciones de colores, estructura y diseño,
            selección de materiales usados, programas de ordenador necesarios
            para su funcionamiento, acceso y uso, etc.) son propiedad exclusiva
            de PAYPER o de un tercero que PAYPER haya autorizado su uso. Por
            tanto, PAYPER tiene el derecho exclusivo de explotación de dichos
            contenidos y diseño gráfico.{' '}
          </Typography>
          <Typography>
            Por ello, en virtud de lo dispuesto en el Real Decreto Legislativo
            1/1996, de 12 de abril, por el que se aprueba el Texto Refundido de
            la Ley de Propiedad Intelectual, y en la Ley 17/2001, de 7 de
            diciembre, de Marcas, así como cualquier otra legislación
            complementaria en materia de propiedad intelectual e industrial,
            queda prohibida la reproducción, transmisión, adaptación,
            traducción, distribución o comunicación pública, incluida la puesta
            a disposición o cualquier otra forma de explotación comercial y/o
            modificación de todo o parte del contenido de la Plataforma, salvo
            autorización previa y expresa de PAYPER.{' '}
          </Typography>
          <Typography>
            PAYPER no concede ninguna licencia o autorización para utilizar
            ninguno de sus derechos de propiedad intelectual e industrial o
            cualquier otra propiedad o derecho relacionado con la Plataforma y/o
            los servicios o contenidos del mismo.{' '}
          </Typography>
          <Typography>
            El Usuario se compromete a respetar los derechos de Propiedad
            Intelectual e Industrial titularidad de PAYPER. Se permite, en este
            sentido, la reproducción y almacenamiento temporal de los contenidos
            de la Plataforma en tanto ello sea estrictamente necesario para el
            uso y visualización de la Plataforma desde un ordenador/Tablet o
            teléfono personal.{' '}
          </Typography>
          <Typography>
            La legitimidad de los derechos de propiedad intelectual o industrial
            sobre los contenidos aportados por los Usuarios es de la exclusiva
            responsabilidad de los mismos, que responderán frente a cualquier
            reclamación de terceros derivada del uso ilícito de los contenidos
            de la Plataforma.{' '}
          </Typography>
        </Box>
        <Box component='section'>
          <Typography component='h2' variant='h4'>
            Protección de datos{' '}
          </Typography>
          <Typography>
            El uso de esta Plataforma puede requerir que los Usuarios
            proporcionen datos personales. PAYPER trata este tipo de información
            de acuerdo con la legislación aplicable, y la recoge en su{' '}
            <MUILink to='/privacy-policy' component={Link}>
              Política de Privacidad
            </MUILink>
            .
          </Typography>
        </Box>
        <Box component='section'>
          <Typography component='h2' variant='h4'>
            Responsabilidad y Garantías{' '}
          </Typography>
          <Typography>
            PAYPER declara que ha adoptado todas las medidas necesarias, dentro
            de sus posibilidades y teniendo en cuenta el estado actual de la
            tecnología, para permitir el correcto funcionamiento y la ausencia
            de virus y componentes dañinos en su Plataforma.{' '}
          </Typography>
          <Typography>
            Sin embargo, PAYPER no puede ser considerada responsable de:
          </Typography>
          <Box component='ol'>
            <Box component='li'>
              la falta de continuidad y disponibilidad de los Contenidos y
              Servicios;{' '}
            </Box>
            <Box component='li'>
              la presencia de errores en dicho Contenido o la falta de
              corrección de los defectos que pudieran existir;{' '}
            </Box>
            <Box component='li'>
              la veracidad, integridad o actualización de los datos facilitados
              por los Usuarios;{' '}
            </Box>
            <Box component='li'>
              la interrupción del funcionamiento de la Plataforma o fallos
              informáticos, averías telefónicas, desconexiones, retrasos o
              bloqueos causados por deficiencias o sobrecargas en las líneas
              telefónicas, centros de datos, en el sistema de Internet o en
              otros sistemas electrónicos, producidos en el curso de su
              funcionamiento;{' '}
            </Box>
            <Box component='li'>
              la presencia de virus u otros componentes dañinos;{' '}
            </Box>
            <Box component='li'>
              cualquier daño causado por terceros que infrinjan o vulneres los
              sistemas de seguridad de PAYPER.{' '}
            </Box>
          </Box>
          <Typography>
            PAYPER podrá suspender temporalmente y sin previo aviso, la
            accesibilidad a la Plataforma con motivo de operaciones de
            mantenimiento, reparación, actualización o mejora. No obstante,
            siempre que las circunstancias lo permitan, PAYPER comunicará al
            Usuario, con antelación suficiente, la fecha prevista para la
            suspensión de los Servicios.{' '}
          </Typography>
          <Typography>
            PAYPER no se responsabiliza del uso que los Usuarios puedan hacer de
            los Contenidos incluidos en la Plataforma.{' '}
          </Typography>
        </Box>
        <Box component='section'>
          <Typography component='h2' variant='h4'>
            Duración y modificación{' '}
          </Typography>
          <Typography>
            El presente Aviso Legal estará vigente con carácter indefinido,
            pudiendo PAYPER efectuar cambios sobre las condiciones especificadas
            en el mismo, y que entrarán en vigor desde el momento de su
            publicación.{' '}
          </Typography>
          <Typography>
            PAYPER podrá suprimir, añadir o cambiar tanto los Contenidos como
            los servicios que presta, así como también la forma en la que los
            mismos aparezcan localizados o presentados. Se entienden como
            vigentes, las condiciones que estén publicadas en el momento en el
            que el Usuario acceda a la Plataforma de PAYPER.{' '}
          </Typography>
          <Typography>
            El acceso y/o el uso de la Plataforma se entenderá como una
            aceptación por parte del Usuario del presente Aviso Legal y sus
            condiciones y, en su caso, los cambios efectuados en las mismas.{' '}
          </Typography>
        </Box>
        <Box component='section'>
          <Typography component='h2' variant='h4'>
            Hipervínculos{' '}
          </Typography>
          <Typography>
            PAYPER no asume ninguna responsabilidad sobre los enlaces a otras
            aplicaciones o sitios web que se encuentren en la Plataforma de
            PAYPER, pudiendo dirigir al Usuario a otras aplicaciones o sitios
            web sobre los que PAYPER no tiene ningún tipo de control.{' '}
          </Typography>
          <Typography>
            En este sentido, PAYPER no se hace responsable de la información
            contenida en estos enlaces de terceros ni de cualesquiera efectos
            que pudieran derivarse de dicha información.{' '}
          </Typography>
          <Typography>
            Por lo tanto, la inclusión de vínculos con otros sitios web o
            aplicaciones no implica la aprobación de sus contenidos por parte de
            PAYPER ni la existencia de ningún tipo de asociación entre PAYPER y
            los titulares de otros sitios web o aplicaciones. En consecuencia,
            el Usuario accede bajo su exclusiva responsabilidad al Contenido y
            en las condiciones de uso que rijan en los mismos.{' '}
          </Typography>
        </Box>
        <Box component='section'>
          <Typography component='h2' variant='h4'>
            Salvaguardia e interpretación{' '}
          </Typography>
          <Typography>
            Este Aviso Legal constituye un acuerdo entre cada uno de los
            Usuarios y PAYPER.{' '}
          </Typography>
          <Typography>
            Si la autoridad competente declara que una disposición es ilegal,
            inválida o inaplicable, dicha declaración con respecto a una o
            varias cláusulas se entenderá sin perjuicio de la validez de las
            demás cláusulas.{' '}
          </Typography>
          <Typography>
            validez de las demás cláusulas. El hecho de que PAYPER no exija el
            cumplimiento estricto de ninguna de las disposiciones del presente
            Aviso Legal no constituye ni puede interpretarse en modo alguno como
            una renuncia de su parte a exigir el cumplimiento estricto en el
            futuro.{' '}
          </Typography>
        </Box>
        <Box component='section'>
          <Typography component='h2' variant='h4'>
            Información General{' '}
          </Typography>
          <Typography>
            PAYPER perseguirá el incumplimiento del presente Aviso Legal, así
            como cualquier utilización indebida de su portal, ejerciendo todas
            las acciones civiles y penales que le puedan corresponder en
            derecho.{' '}
          </Typography>
        </Box>
        <Box component='section'>
          <Typography component='h2' variant='h4'>
            Notificaciones
          </Typography>
          <Typography>
            PAYPER podrá realizar las oportunas comunicaciones a través de la
            dirección de correo electrónico facilitada por los Usuarios en los
            formularios de registro o a través de cualquier otro medio
            facilitado en los datos de contacto del Usuario.{' '}
          </Typography>
        </Box>
      </Container>
    </Box>
  )
}
export default LegalNotice
