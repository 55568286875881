import { Box, Typography } from '@mui/material'

import styles from './discovery.styles'

const Discovery = () => {
  return (
    <Box sx={styles.root}>
      <Typography variant='h1' sx={{ color: 'primary.main' }}>
        Buscar
      </Typography>
    </Box>
  )
}

export default Discovery
