import { useNavigate } from 'react-router-dom'

import { CircularProgress } from '../../../components'
import useSearchParams from '../useSearchParams'
import useAccessLoad from '../useAccessLoad'
import useAccessFinish from '../useAccessFinish'
import Form from './Form'

const Login = () => {
  const navigate = useNavigate()

  const paramsData = useSearchParams()
  const accessLoad = useAccessLoad(paramsData)
  const accessFinish = useAccessFinish(paramsData)

  const navigateWithSearchParams = (to) => navigate(to + window.location.search)

  if (!Boolean(accessLoad.unauthenticationIsConfirmed))
    return <CircularProgress height='80vh' />
  return (
    <Form
      onGoToRegister={() => navigateWithSearchParams('/register')}
      onGoToForgottenPassword={() =>
        navigateWithSearchParams('/forgotten-password')
      }
      onFinish={() => accessFinish.handleFinishAuth({ isNewUser: false })}
      onCancel={() =>
        accessFinish.handleFinishUnauth('User cancelled the process')
      }
    />
  )
}

export default Login
