const styles = {
  root: {
    backgroundColor: 'neutral.100',
    px: { xs: 1, md: 1.5 },
    py: { xs: 1.5, md: 2 },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: { xs: 1, md: 1.5 },
    '& button': {
      fontWeight: 700,
    },
  },
  content: {
    color: 'neutral.700',
    display: 'flex',
    columnGap: { xs: 0.5, md: 1 },
  },
  iconWrapper: {
    fontSize: 30,
  },
  contentTexts: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 0.5,
  },
}

export default styles
