import { updateAccountBalance, validateApplePayMerchant } from './user/service'

const APPLE_PAY_VERSION = 1
// NOTE: In versions of Apple Pay JS prior to version 4, the amount of the total must be greater than zero.
// Check for version availability using supportsVersion before setting a zero amount.
// https://developer.apple.com/documentation/apple_pay_on_the_web/apple_pay_on_the_web_version_history

const MERCHANT_IDENTIFIER =
  process.env.REACT_APP_REDSYS_APPLE_PAY_MERCHANT_IDENTIFIER

const APPLE_PAY_STATUS = {
  LOADING: Symbol('loading'),
  NOT_AVAILABLE: Symbol('not_available'),
  NOT_SUPPORTED: Symbol('apple_pay_not_supported'),
  NO_ACTIVE_CARD: Symbol('no_active_card'),
  ACTIVE_CARD: Symbol('active_card'),
  ERROR: Symbol('error'),
}

const availabilityStatus = async () => {
  let status
  try {
    if (Boolean(window.ApplePaySession)) {
      const isApplePayVersionSupported =
        await window.ApplePaySession.supportsVersion(APPLE_PAY_VERSION)
      const canMakePaymentsResp = await window.ApplePaySession.canMakePayments(
        MERCHANT_IDENTIFIER,
      )
      const canMakePaymentsWithActiveCardResp =
        await window.ApplePaySession.canMakePaymentsWithActiveCard(
          MERCHANT_IDENTIFIER,
        )

      if (!isApplePayVersionSupported || !canMakePaymentsResp) {
        status = APPLE_PAY_STATUS.NOT_SUPPORTED
      } else if (!canMakePaymentsWithActiveCardResp) {
        status = APPLE_PAY_STATUS.NO_ACTIVE_CARD
      } else {
        status = APPLE_PAY_STATUS.ACTIVE_CARD
      }
    } else {
      status = APPLE_PAY_STATUS.NOT_AVAILABLE
    }
  } catch (error) {
    status = APPLE_PAY_STATUS.ERROR
  }
  return status
}

export const hasActiveCard = async () =>
  (await availabilityStatus()) === APPLE_PAY_STATUS.ACTIVE_CARD

const APPLE_PAY_PAYMENT_REQUEST_DATA = {
  total: {
    label: 'Payper',
  },
  countryCode: 'ES',
  currencyCode: 'EUR',
  merchantCapabilities: ['supports3DS'],
  supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'], // The payment networks the merchant supports.
}
export const handleTransaction = (paymentAmount, onSuccess, onError) => {
  const onTransactionError = (error) => {
    window.applePaySession = undefined
    onError(error)
  }

  try {
    const paymentRequestDictionary = APPLE_PAY_PAYMENT_REQUEST_DATA
    paymentRequestDictionary.total.amount = paymentAmount

    // create ApplePaySession
    const applePaySession = new window.ApplePaySession(
      APPLE_PAY_VERSION,
      paymentRequestDictionary,
    )
    applePaySession.begin()

    // This is the first event that Apple triggers. Here you need to validate the
    // Apple Pay Session from your back-end
    applePaySession.onvalidatemerchant = async (event) => {
      const validationUrl = event.validationURL
      const merchantSession = await validateApplePayMerchant(validationUrl)
      applePaySession.completeMerchantValidation(merchantSession)
    }

    // This triggers after the user has confirmed the transaction with the Touch Id or Face Id
    // This will contain the payment token
    applePaySession.onpaymentauthorized = async (event) => {
      const paymentData = event.payment.token.paymentData

      // We send the data returned by Apple server to Redsys through our backoffice
      // & give feedback of the result of the transaction through applePaySession.completePayment
      try {
        await updateAccountBalance(paymentAmount, paymentData)
        applePaySession.completePayment(window.ApplePaySession.STATUS_SUCCESS)
        onSuccess()
      } catch (topUpServiceError) {
        applePaySession.completePayment(window.ApplePaySession.STATUS_FAILURE)
        onTransactionError(topUpServiceError)
      }
    }
  } catch (error) {
    window.applePaySession = undefined

    console.error(
      `Error with the ApplePaySession: ${error.name}/ ${error.message}`,
    )
    onTransactionError(error)
  }
}
