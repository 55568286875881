const styles = {
  dark: {
    '--root-bgColor': (theme) => theme.palette.neutral[700],
    '--link-color': (theme) => theme.palette.neutral[100],
  },
  light: {
    '--root-bgColor': (theme) => theme.palette.neutral[300],
    '--link-color': (theme) => theme.palette.neutral[600],
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    py: 5,
    backgroundColor: 'var(--root-bgColor, transparent)',

    '& .isVisible': {
      opacity: 1,
      transform: 'none',
    },
  },
  content: {
    display: 'grid',
    gridTemplateColumns: (theme) => ({
      xs: '1fr',
      xl: '1fr 1fr',
      xxl: `${theme.spacing(33.75)} ${theme.spacing(35.3125)}`,
    }),
    justifyContent: 'center',
    rowGap: {
      xs: 5,
      md: 3.25,
      lg: 5.25,
      xl: 9,
    },
    columnGap: {
      lg: '5%',
      xxl: '10%',
    },
    width: (theme) => ({
      xs: `min(100%, ${theme.spacing(90)})`,
      lg: `min(100%, ${theme.spacing(90)})`,
    }),
    px: {
      md: 0.5,
      lg: 3,
    },
  },
  info: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      md: 'row',
      xl: 'column',
    },
    alignItems: {
      md: 'end',
      lg: 'start',
    },
    rowGap: {
      xs: 1,
      lg: 3.5,
    },
    columnGap: { md: 1.25 },
    position: {
      md: 'relative',
    },
  },
  wrapText: {
    width: (theme) => ({
      md: `min(100%, ${theme.spacing(28)})`,
    }),
    //
    transform: 'translateY(1rem)',
    opacity: 0,
    transition: 'transform 250ms ease-in, opacity 300ms ease-in',
    transitionDelay: 'var(--delay, 0)',
  },
  heading: {
    maxWidth: '13ch',
    mb: {
      xs: 1,
      md: 1.25,
    },
    fontSize: (theme) => ({
      xs: theme.spacing(2),
      md: theme.spacing(2.625),
      lg: theme.spacing(3.25),
    }),
    color: 'neutral.100',
  },
  description: {
    maxWidth: '40ch',
    color: 'neutral.400',
  },
  wrapPicture: {
    width: (theme) => ({
      xs: '185px',
      md: `clamp(${theme.spacing(9.375)}, 100%, ${theme.spacing(14.875)})`,
      lg: `min(${theme.spacing(18.75)}, 100%)`,
      xl: `clamp(${theme.spacing(20)}, 100%,  ${theme.spacing(35)})`,
    }),
    marginLeft: 'auto',
    marginBottom: {
      xs: -6.875,
      md: 0,
    },
    position: {
      xs: 'relative',
      md: 'absolute',
      xl: 'relative',
    },
    top: (theme) => ({
      md: theme.spacing(3),
      lg: theme.spacing(4.1875),
      xl: 0,
    }),
    right: (theme) => ({
      md: 0,
      lg: theme.spacing(0.625),
      xl: 'initial',
    }),
    zIndex: 1,
    //
    transform: 'translateY(1rem)',
    opacity: 0,
    transition: 'transform 250ms ease-in, opacity 300ms ease-in',
    transitionDelay: 'var(--delay, 0)',
  },
  slot: {
    position: {
      lg: 'relative',
    },
    //
    transform: 'translateY(1rem)',
    opacity: 0,
    transition: 'transform 250ms ease-in, opacity 300ms ease-in',
    transitionDelay: 'var(--delay, 0)',
  },
  wrapFields: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 1,
  },
  groupFields: {
    display: 'grid',
    gridTemplateRows: {
      xs: 'auto auto',
      md: '1fr',
    },
    gridTemplateColumns: {
      md: '220px 1fr',
    },
    rowGap: {
      xs: 0.0625,
      md: 0,
    },
    columnGap: {
      md: 0.0625,
    },

    '& > .MuiFormControl-root:nth-of-type(1)': {
      '& .MuiInputBase-root': {
        borderTopRightRadius: {
          md: 0,
        },
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: (theme) => ({
          xs: 0,
          md: theme.shape.borderRadius,
        }),
      },
    },
    '& > .MuiFormControl-root:nth-of-type(2)': {
      '& .MuiInputBase-root': {
        borderTopLeftRadius: 0,
        borderTopRightRadius: (theme) => ({
          xs: 0,
          md: theme.shape.borderRadius,
        }),
        borderBottomLeftRadius: {
          md: 0,
        },
      },
    },
  },
  groupErrors: {
    display: 'flex',
    alignItems: 'center',
    columnGap: (theme) => theme.spacing(0.625),
    marginTop: (theme) => theme.spacing(0.5),
    marginLeft: (theme) => theme.spacing(0.75),
    marginRight: (theme) => theme.spacing(0.75),
    fontSize: (theme) => theme.spacing(0.75),
    fontWeight: 500,
    color: 'error.main',

    '& > svg': {
      fontSize: (theme) => theme.spacing(1.25),
    },
  },
}

export default styles
