import { ProductFruits as ProductFruitsComponent } from 'react-product-fruits'

const getConfig = (userSub) => ({
  workspaceCode: process.env.REACT_APP_PRODUCT_FRUITS_WORKSPACE_CODE,
  language: 'es',
  user: { username: userSub },
})

const destroy = () => {
  if (Boolean(window?.productFruits)) {
    window.productFruits.services.destroy()
  }
}

const ProductFruits = {
  Component: ProductFruitsComponent,
  getConfig,
  destroy,
}

export default ProductFruits
