import theme from '../../../../theme'

const styles = {
  // MODAL
  dark: {
    '--background': (theme) => theme.palette.neutral[700],
    '--heading': (theme) => theme.palette.neutral[100],
    '--text': (theme) => theme.palette.neutral[100],
  },
  solid: {
    '--background': (theme) => theme.palette.neutral[100],
    '--heading': (theme) => theme.palette.neutral[700],
    '--text': (theme) => theme.palette.neutral[400],
  },
  gradient: {
    '--background': (theme) =>
      `linear-gradient(180deg, ${theme.palette.neutral[100]} 48%, ${theme.palette.neutral[200]} 48%)`,
    '--heading': (theme) => theme.palette.neutral[700],
    '--text': (theme) => theme.palette.neutral[400],
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 2.5,
    minHeight: '100vh',
    background: 'var(--background)',
    overflow: 'hidden',
    width: (theme) => `min(100%, ${theme.spacing(22.5)})`,
    pt: 1.25,
    px: 1.25,
    pb: 2,
    p: { md: 0 },
    mt: {
      md: 3.75,
    },
    ml: 'auto',
    mr: 'auto',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: 1.25,
  },
  headerContent: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 0.5,
    mb: {
      xs: 0.25,
      md: 1,
    },
  },
  text: {
    ...theme.typography.body2,
    color: 'var(--text)',
  },
  closeButton: {
    position: {
      xs: 'relative',
      md: 'absolute',
    },
    top: (theme) => ({
      xs: theme.spacing(-0.5),
      md: theme.spacing(1.25),
    }),
    right: (theme) => ({
      md: theme.spacing(2),
    }),

    '& .MuiIconButton-root': {
      p: 0.5,
      fontSize: (theme) => theme.spacing(1.5),
      color: 'var(--heading)',
    },
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    rowGap: 2,
    mt: -1.5,
  },
  //END MODAL

  //STEP
  heading: {
    ...theme.typography.h4,
    color: 'var(--heading)',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 1,
  },

  //END STEP

  //USER-DATA STEP
  groupFields: {
    display: 'grid',
    rowGap: 0.25,

    '& > .MuiFormControl-root:nth-of-type(1)': {
      '& .MuiInputBase-root': {
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
      },
    },
    '& > .MuiFormControl-root:nth-of-type(2)': {
      '& .MuiInputBase-root': {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      },
    },
  },
  groupErrors: {
    display: 'flex',
    alignItems: 'center',
    columnGap: (theme) => theme.spacing(0.625),
    marginTop: (theme) => theme.spacing(0.5),
    marginLeft: (theme) => theme.spacing(0.75),
    marginRight: (theme) => theme.spacing(0.75),
    fontSize: (theme) => theme.spacing(0.75),
    fontWeight: 500,
    color: 'error.main',

    '& > svg': {
      fontSize: (theme) => theme.spacing(1.25),
    },
  },

  collapsible: {
    display: 'flex',
    alignItems: 'center',
    columnGap: (theme) => theme.spacing(0.5),
  },
  collapsibleButton: {
    flex: (theme) => `0 0 ${theme.spacing(2.5)}`,
    height: (theme) => theme.spacing(2.5),
    color: 'neutral.700',
  },

  //END USER-DATA STEP
}

export default styles
