import { Dialog } from '@mui/material'

import EVENTS_NAMES from '../../../analytics/eventsNames.json'
import { Modal } from '../../components/Modal'
import { SuccessStepWithTimeout } from '../../components/Registration/Steps'
import useSearchParams from './useSearchParams'
import useAccessFinish from './useAccessFinish'

const styles = { justifyContent: 'flex-start', rowGap: 2, mt: -1.5 }

const RegisterSuccess = () => {
  const paramsData = useSearchParams()
  const accessFinish = useAccessFinish(paramsData)

  const handleFinish = () => {
    document.dispatchEvent(
      new CustomEvent('payperAnalyticsPublicEvent', {
        detail: {
          name: EVENTS_NAMES.PA_CLICK_SIGNUP_SUCCESS,
        },
      }),
    )

    accessFinish.handleFinishAuth({ isNewUser: false })
  }

  return (
    <Dialog aria-label='Registro terminado con éxito' open>
      <Modal
        onCancel={handleFinish}
        title={(texts) => texts.getRegisterSuccessTitleLabel()}
        sx={styles}
      >
        <SuccessStepWithTimeout onContinue={handleFinish} />
      </Modal>
    </Dialog>
  )
}

export default RegisterSuccess
