import { SponsoredModal } from '../Modal'

const ForgotenPasswordLayout = ({
  children,
  onCancel,
  stepsLayoutProps,
  logoUrl,
  customTitle,
  customDescription,
}) => {
  return (
    <SponsoredModal
      logoUrl={logoUrl}
      customTitle={customTitle}
      customDescription={customDescription}
      onCancel={onCancel}
      {...stepsLayoutProps}
    >
      {children}
    </SponsoredModal>
  )
}

export default ForgotenPasswordLayout
