import PropTypes from 'prop-types'
import { Box, IconButton, Typography, Dialog } from '@mui/material'

import { X } from '../../../../styleguide/icons'
import { useTexts } from '../../../../texts'
import { LogoPayper } from '../../../components'

import styles from './access.styles.js'

const modalPropTypes = {
  background: PropTypes.oneOf(['solid', 'gradient', 'dark']),
  onCancel: PropTypes.func,
  children: PropTypes.node,
}

const Modal = ({ background = 'solid', onCancel, children }) => {
  const texts = useTexts()

  return (
    <Dialog open>
      <div aria-label='accede-a-tu-cuenta-payper'>
        <Box
          sx={{ ...styles.root, ...styles[background] }}
          aria-labelledby='dialogTitle'
        >
          <Box sx={styles.header}>
            <Box sx={styles.headerContent}>
              <LogoPayper width={186} height={48} />
              <Typography component='h2' sx={styles.text}>
                {texts.getPayperSlogan()}
              </Typography>
            </Box>
            {Boolean(onCancel) && (
              <Box sx={styles.closeButton}>
                <IconButton
                  aria-label={texts.getCloseAction()}
                  onClick={onCancel}
                >
                  <X />
                </IconButton>
              </Box>
            )}
          </Box>
          <Box sx={styles.content}>{children}</Box>
        </Box>
      </div>
    </Dialog>
  )
}

Modal.propTypes = modalPropTypes

export default Modal
