import PropTypes from 'prop-types'

export const AssociatedMediaData = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  imageUrl: PropTypes.string,
  domain: PropTypes.string,
})

export const Article = PropTypes.shape({
  articleId: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  category: PropTypes.string,
  description: PropTypes.string,
  publishingDate: PropTypes.string,
  mediaProviderId: PropTypes.string,
  image: PropTypes.string,
  mediaName: PropTypes.string,
  mediaIconUrl: PropTypes.string,
})

export const ARTICLE_ORIENTATION = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal',
}

export const ArticlesSection = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  data: PropTypes.arrayOf(Article),
})

export const Partner = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  imageUrl: PropTypes.string,
})
