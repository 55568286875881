import { CircularProgress } from '../../../components'
import useAccess from '../Access/useAccess'
import View from '../Access/View'
import LayoutView from './LayoutView'

const OnboardingAccess = () => {
  const { unauthenticationIsConfirmedInLoad, ...viewProps } = useAccess()

  if (!Boolean(unauthenticationIsConfirmedInLoad))
    return <CircularProgress height='80vh' />

  return (
    <LayoutView>
      <View {...viewProps} />
    </LayoutView>
  )
}

export default OnboardingAccess
