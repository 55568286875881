import PropTypes from 'prop-types'

import { useTexts } from '../../../../texts'
import Response from './Response'
import imageAvif from './young-man-in-formalwear-sitting-on-the-floor-with-phone-and-a-dog.avif'
import imageWebp from './young-man-in-formalwear-sitting-on-the-floor-with-phone-and-a-dog.webp'
import imagePng from './young-man-in-formalwear-sitting-on-the-floor-with-phone-and-a-dog.png'

const successViewProptypes = {
  onClickNewContact: PropTypes.func,
}

const SuccessView = ({ onClickNewContact }) => {
  const texts = useTexts()

  return (
    <Response
      width={456}
      height={442}
      title={texts.getContactFormSuccessTitleLabel()}
      message={texts.getContactFormSuccessDescriptionLabel()}
      image={{
        imageAvif,
        imageWebp,
        imagePng,
      }}
      ctaText={texts.getContactFormSendSuccessAction()}
      onClickCta={onClickNewContact}
    />
  )
}

SuccessView.propTypes = successViewProptypes

export default SuccessView
