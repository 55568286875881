const styles = {
  root: {
    backgroundColor: 'black',
    flex: 1,
    alignContent: 'center',
    textAlign: 'center',
  },
}

export default styles
