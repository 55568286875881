import { useRef, useEffect, useState } from 'react'
import { Box } from '@mui/material'

import { BankCardOrder, Session } from '../../../business'
import CircularProgress from '../CircularProgress'

import {
  obtain3DSBrowserInfo,
  waitForIframeToPostMessage,
} from './redsysHelpers'

const ChallengeV2 = ({ threeDSServerTransID, onError, onSuccess }) => {
  const [form, setForm] = useState(null)
  const formRef = useRef(null)

  const isAuthenticated = Session.isAuthenticated()
  const dashboardHeaderHeight =
    (isAuthenticated &&
      document.getElementById('dashboard-header')?.getBoundingClientRect()
        ?.height) ??
    0

  useEffect(() => {
    if (form !== null) {
      formRef.current.submit()
      return
    }

    ;(async () => {
      const authorizationResult = await BankCardOrder.requestAuthorizationV2(
        threeDSServerTransID,
        obtain3DSBrowserInfo(),
      )

      if (!authorizationResult.required) {
        onSuccess()
        return
      }

      setForm(authorizationResult.form)

      const iframeResult = await waitForIframeToPostMessage('postchallenge-v2')

      if (iframeResult.success) {
        onSuccess()
        return
      }

      onError(iframeResult.message)
    })()
  })

  if (form === null) {
    return <CircularProgress />
  }

  return (
    <>
      <CircularProgress />
      <Box
        sx={{
          position: 'fixed',
          inset: 0,
          top: dashboardHeaderHeight,
          zIndex: 324,
        }}
      >
        <iframe
          name='challengeV2Iframe'
          title='challengeV2Iframe'
          style={{ width: '100%', height: '100%', border: 0 }}
        />
        <form
          ref={formRef}
          action={form.url}
          method='post'
          target='challengeV2Iframe'
        >
          <input value={form.body.creq} type='hidden' name='creq' />
        </form>
      </Box>
    </>
  )
}

export default ChallengeV2
