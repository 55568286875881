const styles = {
  cta: {
    '&.MuiButton-root': {
      display: 'inline-flex',
      alignItems: 'center',
      columnGap: 0.5,
      minHeight: (theme) => theme.spacing(1.75),
      py: 0,
      pl: 0.5,
      pr: 0.25,
      borderRadius: (theme) => theme.spacing(2.75),
      color: 'neutral.100',
      backgroundColor: 'neutral.700',

      '&:hover': {
        backgroundColor: 'neutral.600',

        '& > span': {
          backgroundColor: 'primary.dark',
        },
      },
    },
  },
  amount: {
    fontFamily: 'Inter',
    fontSize: (theme) => theme.spacing(1),

    '& > b': {
      fontFamily: 'Inter',
      fontSize: (theme) => theme.spacing(0.75),
      fontWeight: 500,
      color: 'primary.main',
      pl: 0.1,
      pr: {
        xs: 0.1,
        md: 0.25,
      },
    },
  },
  wrapPlus: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: (theme) => theme.spacing(1.25),
    height: (theme) => theme.spacing(1.25),
    p: 0,
    borderRadius: '50%',
    backgroundColor: 'primary.main',
    transition: 'inherit',
  },
}

export default styles
