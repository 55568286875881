const styles = {
  bankCard: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 1,
    position: 'relative',
    p: 1,
    mx: -1.25,
    borderRadius: (theme) => theme.spacing(1.25),
    boxShadow: 1,
    backgroundColor: 'neutral.100',
  },
  wrapInputs: {
    display: 'grid',
    gridTemplateColumns: (theme) => `auto ${theme.spacing(7.5)}`,
    gap: {
      xs: 1,
      md: 4,
    },
  },
  groupInputs: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    columnGap: 0.25,

    // NOTE: We add this style exceptions instead of creating more exceptions within the markup
    '& > div:nth-of-type(1) iframe': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: (theme) => theme.shape.borderRadius,
    },
    '& > div:nth-of-type(2) iframe': {
      borderTopLeftRadius: 0,
      borderTopRightRadius: (theme) => theme.shape.borderRadius,
      borderBottomLeftRadius: 0,
    },
    '& > div:nth-of-type(2) label': {
      left: (theme) => theme.spacing(1.5),
    },
  },
  logos: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    filter: 'grayscale(1)',
  },
  input: {
    height: (theme) => theme.spacing(4),
    position: 'relative',
  },
  inputIcon: {
    display: 'flex',
    fontSize: (theme) => theme.spacing(1.25),
    color: 'neutral.500',
    position: 'absolute',
    top: '50%',
    left: (theme) => theme.spacing(0.75),
    transform: 'translateY(-50%)',
  },
  inputLabel: {
    fontSize: (theme) => theme.spacing(0.75),
    fontWeight: 500,
    color: 'neutral.500',
    position: 'absolute',
    top: 12,
    left: 42,
  },
  inputStar: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: (theme) => theme.spacing(0.625),
    height: (theme) => theme.spacing(0.625),
    color: 'info.main',
    borderRadius: '50%',
    backgroundColor: 'neutral.300',
    position: 'relative',

    '&::before': {
      content: '"*"',
      transform: (theme) => `translateY(${theme.spacing(0.125)})`,
    },
  },
  inputIframe: {
    height: (theme) => theme.spacing(4),

    '& iframe': {
      borderRadius: (theme) => theme.spacing(0.5),
    },
  },
  submit: {
    height: (theme) => theme.spacing(4),
    position: 'relative',
  },
  submitIcon: {
    display: {
      xs: 'none',
      sm: 'flex',
    },
    fontSize: (theme) => theme.spacing(1.5),
    color: 'neutral.100',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 1,
  },
  submitIconDefaultPosition: {
    left: '30%',
  },
  submitIframe: {
    height: (theme) => theme.spacing(4),
    position: 'relative',
    transition: 'opacity 300ms',

    '&:hover': {
      opacity: 0.85,
    },
  },
  message: {
    position: 'relative',
    mt: 1,
  },
  safePayment: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    columnGap: 0.25,
    mt: -0.75,
    position: 'relative',
    color: 'neutral.400',

    '& > svg': {
      fontSize: (theme) => theme.spacing(1.25),
    },
  },
}

const iframedInputStyles =
  'display: flex; width: calc(100% + 16px); height: 52px; padding: 16px 0 0 44px; margin: -10px; border: 0; font-family: "Inter","Helvetica Neue",Arial,sans-serif; font-size: 14px; color: #121212; background: #F7F7F5;'

const iframedInputYearStyles =
  'display: flex; width: calc(100% + 16px); height: 52px; padding: 16px 0 0 24px; margin: -10px; border: 0; font-family: "Inter","Helvetica Neue",Arial,sans-serif; font-size: 14px; color: #121212; background: #F7F7F5;'

const iframedButtonStyles =
  'box-sizing:border-box;display:flex;justify-content:flex-end;align-items:center;width:calc(100% + 16px);border:0;padding:1.25rem 30% 1.25rem 1.5rem;margin:-8px;border-radius:8px;font-family:Inter,"Helvetica Neue",Arial,sans-serif;font-size:1rem;font-weight:500;text-transform:none;text-decoration:none;line-height:1.5;color:#FFFFFF;box-shadow:none;-webkit-tap-highlight-color:transparent;background-color:#121212;position:relative;transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;cursor:pointer;user-select:none;vertical-align:middle;-moz-appearance:none;-webkit-appearance:none;-webkit-text-decoration:none;'

const concatIframedButtonStyles = (styles) =>
  styles ? iframedButtonStyles.concat(styles) : iframedButtonStyles

export {
  styles,
  iframedInputStyles,
  iframedInputYearStyles,
  iframedButtonStyles,
  concatIframedButtonStyles,
}
