import { useTexts } from '../../../../texts'
import { Feedback, CircularProgress } from '../../../components'
import useSocialLoginCallback from '../SocialLoginCallback/useSocialLoginCallback'

const REDIRECTION_PATH_ON_SUCCESS = '/sso-success-social-login'

const SSOSocialLoginCallback = () => {
  const texts = useTexts()

  const { errorDescription } = useSocialLoginCallback(
    REDIRECTION_PATH_ON_SUCCESS,
  )

  if (!Boolean(errorDescription)) return <CircularProgress height='80vh' />
  return (
    <Feedback
      isAuthenticated={false}
      title={texts.getSomethingWentWrontErrorTitle()}
      content={[errorDescription]}
      button={{
        text: texts.getNotFoundErrorCTA(),
        to: '/',
      }}
    />
  )
}

export default SSOSocialLoginCallback
