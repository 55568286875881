import { useNavigate } from 'react-router-dom'
import { useTexts } from '../../../../texts'
import { Bag } from '../../../../styleguide/icons'

import { BankCardWithEditionButton } from '../../../components'
import CardWrapper from './CardWrapper'

const BankCard = ({ bankCardData }) => {
  const texts = useTexts()
  const navigate = useNavigate()

  return (
    <CardWrapper title={texts.getUserBankCardLabel()} icon={<Bag />}>
      <BankCardWithEditionButton
        bankCardData={bankCardData}
        onClickEditBankCard={() => {
          navigate('/dashboard/bank-card-edition')
        }}
        onClickAddBankCard={() => {
          navigate('/dashboard/bank-card-introduction')
        }}
      />
    </CardWrapper>
  )
}

export default BankCard
