import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  CircularProgress as MUICircularProgress,
  Typography,
} from '@mui/material'

const absoluteCenteredStyles = {
  inset: 0,
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

const circularProgressWithPercentagePropTypes = {
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  size: PropTypes.number,
  duration: PropTypes.number,
  interval: PropTypes.number,
  onFinishedCountdown: PropTypes.func,
}

const MIN_VALUE = 0
const MAX_VALUE = 100

const CircularProgressWithPercentage = ({
  height = 300,
  width = '100%',
  size = 70,
  duration = 5000, // in ms
  interval = 1,
  onFinishedCountdown,
}) => {
  const [progress, setProgress] = useState(MIN_VALUE)

  useEffect(() => {
    if (progress === MAX_VALUE) {
      onFinishedCountdown()
      return
    }

    const timer = setTimeout(() => {
      setProgress((prevProgress) => prevProgress + interval)
    }, duration / (MAX_VALUE / interval))

    return () => {
      clearTimeout(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress])

  return (
    <Box
      sx={{
        height,
        width,
        position: 'relative',
      }}
    >
      <Box sx={absoluteCenteredStyles}>
        <MUICircularProgress
          size={size}
          variant='determinate'
          value={progress}
        />
      </Box>
      <Box sx={absoluteCenteredStyles}>
        <Typography variant='caption' component='div' color='text.secondary'>
          {`${Math.floor(progress / 10) * 10} %`}
        </Typography>
      </Box>
    </Box>
  )
}

CircularProgressWithPercentage.propTypes =
  circularProgressWithPercentagePropTypes

export default CircularProgressWithPercentage
