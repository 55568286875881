import { Box } from '@mui/material'

import styles from './balanceData.styles'

const View = ({ children }) => {
  return (
    <Box sx={styles.root}>
      <Box sx={styles.content}>{children}</Box>
    </Box>
  )
}

export default View
