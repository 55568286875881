import View from './View'
import { User } from '../../../../business'
import useUpdatePersonalDataForm from './useUpdatePersonalDataForm'

const FORM_ID = 'update-personal-data-form'

const UpdatePersonalData = ({ userData }) => {
  const initialValues = {
    name: userData.name,
    familyName: userData.familyName,
    phone: userData.phone,
    communicationsAccepted: userData.communicationsAccepted,
  }

  const { status, isSubmitSuccess, submitForm, updateLegalCheckbox } =
    useUpdatePersonalDataForm(FORM_ID, initialValues)

  const handleSubmit = async (ev) => {
    ev.preventDefault()
    const formData = new FormData(ev.target)
    const formValues = Object.fromEntries(formData)
    submitForm(formValues)
  }

  return (
    <View
      formId={FORM_ID}
      onSubmit={handleSubmit}
      submitSucceed={isSubmitSuccess()}
      onChangeControlledLegalCheckbox={updateLegalCheckbox}
      {...status}
    />
  )
}

const UpdatePersonalDataWrapper = () => {
  const {
    state: { data: userData },
  } = User.usePersonalData()

  if (!userData) return null
  return <UpdatePersonalData userData={userData} />
}
export default UpdatePersonalDataWrapper
