import { useEffect } from 'react'
import PropTypes from 'prop-types'

import { Auth } from '../../../../../business'
import SocialLoginButtons from '../../SocialLoginButtons'

import View from './View'

const FORM_ID = 'login-form'

const loginFormProptypes = {
  onCancel: PropTypes.func,
  onFinish: PropTypes.func,
  onGoToRegister: PropTypes.func,
  onGoToForgottenPassword: PropTypes.func,
}

const LoginForm = ({ onFinish, ...restProps }) => {
  const { status, isSubmitSuccess, submitForm } = Auth.useLoginForm(FORM_ID)

  const handleSubmit = async (ev) => {
    ev.preventDefault()
    const formData = new FormData(ev.target)
    const formValues = Object.fromEntries(formData)
    await submitForm(formValues)
  }

  useEffect(() => {
    if (isSubmitSuccess()) {
      onFinish()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccess])

  return (
    <View
      {...restProps}
      formId={FORM_ID}
      onSubmit={handleSubmit}
      {...status}
      renderSocialLogin={() => <SocialLoginButtons skin='dark' />}
    />
  )
}

LoginForm.propTypes = loginFormProptypes

export default LoginForm
