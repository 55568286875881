import { useRef, useEffect, useState } from 'react'
import { Box } from '@mui/material'

import { BankCardOrder, Session } from '../../../business'
import CircularProgress from '../CircularProgress'

import { waitForIframeToPostMessage } from './redsysHelpers'

const ChallengeV1 = ({ operationId, orderId, onError, onSuccess }) => {
  const [form, setForm] = useState(null)
  const formRef = useRef(null)

  const isAuthenticated = Session.isAuthenticated()
  const dashboardHeaderHeight =
    (isAuthenticated &&
      document.getElementById('dashboard-header')?.getBoundingClientRect()
        ?.height) ??
    0

  useEffect(() => {
    if (form !== null) {
      formRef.current.submit()
      return
    }

    ;(async () => {
      const authorizationResult = await BankCardOrder.requestAuthorizationV1(
        operationId,
        orderId,
      )

      if (!authorizationResult.required) {
        onSuccess()
        return
      }

      setForm(authorizationResult.form)

      const iframeResult = await waitForIframeToPostMessage('postchallenge-v1')

      if (iframeResult.success) {
        onSuccess()
        return
      }

      onError(iframeResult.message)
    })()
  })

  if (form === null) {
    return <CircularProgress />
  }

  return (
    <>
      <CircularProgress />

      <Box
        sx={{
          position: 'fixed',
          inset: 0,
          top: dashboardHeaderHeight,
          zIndex: 324,
        }}
      >
        <iframe
          name='challengeV1Iframe'
          title='challengeV1Iframe'
          style={{ width: '100vw', height: '100%', border: 0 }}
        />
        <form
          ref={formRef}
          action={form.url}
          method='post'
          target='challengeV1Iframe'
        >
          <input value={form.body.MD} type='hidden' name='MD' />
          <input value={form.body.PaReq} type='hidden' name='PaReq' />
          <input value={form.body.TermUrl} type='hidden' name='TermUrl' />
        </form>
      </Box>
    </>
  )
}

export default ChallengeV1
