import { Box, Typography, Skeleton } from '@mui/material'

import { Picture } from '../index'
// import { formatShortTextDate } from '../../format'
import styles from './articleCard.styles'

const PublishingDetail = ({ article, isLoading }) => (
  <Box sx={styles.publishingDetail}>
    {isLoading ? (
      <Typography variant='p'>
        <Skeleton width={200} />
      </Typography>
    ) : (
      <>
        {Boolean(article?.mediaIconUrl) && (
          <Picture
            width={20}
            height={20}
            fallback={article.mediaIconUrl}
            alt='icono medio'
          />
        )}
        {Boolean(article?.mediaName) && (
          <Typography variant='p'>{article.mediaName}</Typography>
        )}
        {/* {Boolean(article?.mediaName) && Boolean(article?.publishingDate) && (
          <span className='separator'></span>
        )}
        {Boolean(article?.publishingDate) && (
          <Typography variant='p'>
            {formatShortTextDate(article.publishingDate)}
          </Typography>
        )} */}
      </>
    )}
  </Box>
)

export default PublishingDetail
