import { useState } from 'react'
import { Box, Button, IconButton } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Link } from 'react-router-dom'

import { User } from '../../../../../business'
import { useTexts } from '../../../../../texts'
import { Logout } from '../../../../../styleguide/icons'
import { Avatar } from '../../../../components'

import { MenuContainer, MenuItem } from './Menu'
import styles from './actions.styles'

const HeaderActions = ({ onClickLogout }) => {
  const texts = useTexts()
  const theme = useTheme()
  const widerThanTablet = useMediaQuery(theme.breakpoints.up('md'))

  const {
    state: { data: user },
  } = User.usePersonalData()

  const [anchorEl, setAnchorEl] = useState(null)
  const menuOpened = Boolean(anchorEl)
  const handleClickMenu = (ev) => setAnchorEl(ev.currentTarget)
  const handleCloseMenu = () => setAnchorEl(null)

  return (
    <Box sx={styles.actions}>
      {Boolean(user) && (
        <Button
          id='user-button'
          endIcon={<Avatar user={user} />}
          aria-label='user-button'
          aria-controls={menuOpened ? 'user-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={menuOpened ? 'true' : undefined}
          sx={styles.menuButton}
          onClick={handleClickMenu}
        >
          {widerThanTablet ? user?.name : ''}
        </Button>
      )}

      {widerThanTablet ? (
        <IconButton
          aria-label='logout-button'
          onClick={onClickLogout}
          sx={styles.logout}
        >
          <Logout />
        </IconButton>
      ) : null}

      <MenuContainer
        handleClose={handleCloseMenu}
        anchorEl={anchorEl}
        open={menuOpened}
      >
        <MenuItem
          onClick={handleCloseMenu}
          component={Link}
          to='/dashboard/account'
          text={texts.account()}
        />
        <MenuItem
          onClick={handleCloseMenu}
          component={Link}
          to={'/dashboard/purchases'}
          text={texts.getPurchasesLabel()}
        />

        {widerThanTablet ? null : (
          <MenuItem
            onClick={() => {
              handleCloseMenu()
              onClickLogout()
            }}
            icon={<Logout />}
            text={texts.getUserLogoutAction()}
            aria-label='logout-button'
          />
        )}
      </MenuContainer>
    </Box>
  )
}

export default HeaderActions
