import { useTexts } from '../../../../texts'
import { useFormV2, composeValidators, validators } from '../../../../forms'
import { User } from '../../../../business'

const CHECKBOX_FALSY_VALUE = undefined
const CHECKBOX_TRUTHY_VALUE = 'on'

const isCheckboxChecked = (value) => value === CHECKBOX_TRUTHY_VALUE

const parseCheckboxValue = (value) =>
  value ? CHECKBOX_TRUTHY_VALUE : CHECKBOX_FALSY_VALUE

const useUpdatePersonalDataForm = (formId, initialValues) => {
  const texts = useTexts()
  const {
    optimisticUpdate,
    state: { data: userData },
  } = User.usePersonalData()

  const { pipeValidators, isFilled, hasOnlyDigits, hasOnlyLetters } = validators

  const FORM_VALIDATION_CONFIG = {
    name: composeValidators([
      [isFilled, texts.getErrorRequired()],
      [hasOnlyLetters, texts.getErrorOnlyLetters()],
    ]),
    familyName: composeValidators([
      [isFilled, texts.getErrorRequired()],
      [hasOnlyLetters, texts.getErrorOnlyLetters()],
    ]),
    phone: composeValidators([
      [
        pipeValidators(
          (v) => !isFilled(v) || hasOnlyDigits(v),
          (v) => !isFilled(v) || v.length === 9,
        ),
        texts.getErrorPhone(),
      ],
    ]),
  }

  const filterEmptyStringValues = (formValues) => {
    let filteredValues = { ...formValues }
    for (const [key, value] of Object.entries(formValues)) {
      if (value === '') {
        delete filteredValues[key]
      }
    }
    return filteredValues
  }

  const formatFormValues = (formValues) => ({
    ...filterEmptyStringValues(formValues),
    communicationsAccepted: isCheckboxChecked(
      formValues?.communicationsAccepted,
    ),
  })

  const submitRegisterData = async (formValues) => {
    try {
      await User.updatePersonalData(formValues)
      optimisticUpdate({ ...userData, ...formValues })
      return
    } catch (error) {
      throw new Error(texts.getUnexpectedError())
    }
  }

  const submitFn = (formValues) =>
    submitRegisterData(formatFormValues(formValues))

  const useFormData = useFormV2(formId, submitFn, FORM_VALIDATION_CONFIG, {
    ...initialValues,
    communicationsAccepted: parseCheckboxValue(
      initialValues.communicationsAccepted,
    ),
  })

  const updateLegalCheckbox = (ev) => {
    useFormData.updateFieldForm(
      ev.target.name,
      parseCheckboxValue(ev.target.checked),
    )
  }

  return {
    ...useFormData,
    updateLegalCheckbox,
  }
}

export default useUpdatePersonalDataForm
