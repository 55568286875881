const mediumWidth = {
  width: 'var(--medium-width)',
  mx: 'auto',
}
// const fullWidth = {
//   width: 'var(--large-width)',
//   mx: 'auto',
// }

const styles = {
  root: {
    flex: 1,
    mb: 3,
  },
  mainContent: {
    flex: 1,
    p: 1.25,
  },
  header: {
    mt: {
      xs: 1,
      md: 1,
    },
    ...mediumWidth,
  },
}

export default styles
