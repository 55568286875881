const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    rowGap: {
      xs: 2.5,
      md: 3.5,
    },
    width: (theme) => `min(100%, ${theme.spacing(59.5)})`,
    mx: 'auto',
    pt: {
      xs: 2,
      md: 3,
    },
    pb: {
      xs: 4,
      lg: 6,
    },

    '& h5': {
      m: 0,
      py: (theme) => ({
        xs: theme.spacing(0.5),
        md: theme.spacing(1),
      }),
    },
  },
}

export default styles
