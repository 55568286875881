import { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'

import { useTexts } from '../../../texts'
import {
  chromeExtension,
  nativeApps,
  payperMagic,
} from '../../../thirdPartyIntegration'
import { AssociatedMediaData } from '../../propTypes'
import { MediaButton } from '..'
import ChromeExtDialog from './ChromeExtDialog'
import styles from './associatedMedia.styles'
const {
  useChromeExtensionAvailability,
  CE_AVAILABILITY,
  isTestingEnvWithNoChromeExtConfig,
} = chromeExtension
const { isEmbeddedInNativeApp } = nativeApps

const associatedMediaPropTypes = {
  medias: PropTypes.arrayOf(AssociatedMediaData),
  isPublicVersion: PropTypes.bool,
  onClickMedia: PropTypes.func,
}

const AssociatedMedia = ({ medias = [], isPublicVersion, onClickMedia }) => {
  const texts = useTexts()

  const [showCEDialog, setShowCEDialog] = useState(false)

  const ceAvailability = useChromeExtensionAvailability()

  const closeCEDialog = () => setShowCEDialog(false)
  const openCEDialog = () => setShowCEDialog(true)

  const handleClickMediaButton =
    ceAvailability === CE_AVAILABILITY.ACTIVE ||
    isEmbeddedInNativeApp() ||
    payperMagic.isActive() ||
    isTestingEnvWithNoChromeExtConfig()
      ? onClickMedia
      : openCEDialog

  return (
    <>
      <ChromeExtDialog
        isOpen={showCEDialog}
        onClose={closeCEDialog}
        ceAvailability={ceAvailability}
      />
      <Box sx={styles.root}>
        {isPublicVersion ? (
          <Typography component='h2' variant='h4' sx={styles.variableTextAlign}>
            {texts.getAssociatedMediaPublicTitle()}
          </Typography>
        ) : (
          <Typography variant='h6'>
            {texts.getAssociatedMediaTitle()}
          </Typography>
        )}
        {!isPublicVersion && (
          <Typography variant='subtitle2'>
            {texts.getAssociatedMediaSubtitle()}
          </Typography>
        )}
        {/* NOTE: Do not change the id 'associated_media'. It is referred from the Products-Fruits' onboarding. */}
        <Box sx={styles.logos} id='associated_media'>
          {medias.map((mediaData, i) => (
            <MediaButton
              key={mediaData?.id ?? i}
              onClick={handleClickMediaButton}
              data={mediaData}
            />
          ))}
        </Box>
      </Box>
    </>
  )
}

AssociatedMedia.propTypes = associatedMediaPropTypes

export default AssociatedMedia
