import { useState, useEffect } from 'react'
import { useMediaQuery } from '@mui/material'

import theme from '../../../../theme'
import { User } from '../../../../business'
import { formatLongDate } from '../../../format'
import { DEFAULT_PAGINATION } from './constants'

const initialState = {
  isLoading: true,
  data: undefined,
  error: false,
}

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  })
}

const useIsMobile = () => !useMediaQuery(theme.breakpoints.up('md'))

const usePaginatedPurchases = () => {
  const isMobile = useIsMobile()

  const [pagination, setPagination] = useState(DEFAULT_PAGINATION)
  const [totalCount, setTotalCount] = useState()
  const [purchases, setPurchases] = useState(initialState)

  const handleChangePage = (newOffset) => {
    setPagination((pagination) => ({ ...pagination, offset: newOffset }))
    if (isMobile) {
      scrollToTop()
    }
  }

  const handleChangeRowsPerPage = (newLimit) => {
    setPagination((pagination) => ({
      ...pagination,
      limit: newLimit,
      offset: 0,
    }))
    if (isMobile) {
      scrollToTop()
    }
  }

  const handleChangeOrderBy = (newOrderBy) => {
    setPagination((pagination) => ({
      ...pagination,
      orderBy: newOrderBy,
      orderDirection: 'DESC',
    }))
    if (isMobile) {
      scrollToTop()
    }
  }

  const handleChangeOrderDirection = () => {
    setPagination((pagination) => ({
      ...pagination,
      orderDirection: pagination.orderDirection === 'DESC' ? 'ASC' : 'DESC',
    }))
    if (isMobile) {
      scrollToTop()
    }
  }

  useEffect(() => {
    ;(async () => {
      try {
        const response = await User.fetchPurchases(pagination)
        const formattedPurchases = response.data.map((purchase) => ({
          ...purchase,
          userName: purchase.userFullName,
          date: formatLongDate(purchase.date),
        }))

        setPurchases({
          isLoading: false,
          data: formattedPurchases,
          error: false,
        })
        setTotalCount(response.totalCount)
      } catch (error) {
        setPurchases({ isLoading: false, data: null, error })
        setTotalCount()
      }
    })()
  }, [pagination])

  return {
    purchases,
    pagination,
    totalCount,
    handleChangePage,
    handleChangeRowsPerPage,
    handleChangeOrderBy,
    handleChangeOrderDirection,
  }
}

export default usePaginatedPurchases
