import theme from '../../../../theme'

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 1.5,
    width: (theme) => `min(100%, ${theme.spacing(73)})`,
    mx: 'auto',
    pt: {
      xs: 2.5,
      md: 3.5,
    },
    pb: {
      xs: 4,
      md: 5,
    },
  },
  sectionTitle: {
    px: {
      xs: 1.25,
      lg: 1.5,
    },
    ...theme.typography.h5,
    color: 'neutral.700',
  },
}

export default styles
