import PropTypes from 'prop-types'

import * as PT from '../propTypes'
import ArticleCard from './ArticleCard'

const articlesSkeletonPropTypes = {
  withCategory: PropTypes.bool,
  withDescription: PropTypes.bool,
  orientation: PropTypes.oneOf(Object.values(PT.ARTICLE_ORIENTATION)),
  isHighlighted: PropTypes.bool,
  numberOfArticles: PropTypes.number,
}

const ArticleSkeleton = (props) => <ArticleCard isSkeleton {...props} />

const ArticlesSkeleton = (props) => (
  <>
    {[...Array(props.numberOfArticles).keys()].map((n) => (
      <ArticleSkeleton key={n} {...props} />
    ))}
  </>
)

ArticlesSkeleton.propTypes = articlesSkeletonPropTypes

export default ArticlesSkeleton
