const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    boxShadow: 4,
    backgroundColor: 'neutral.100',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 2,
  },
  toolbar: {
    width: (theme) => `min(100%, ${theme.spacing(90)})`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    pl: {
      xs: 1,
      md: 1.5,
      lg: 2,
    },
    pr: {
      xs: 0,
      md: 1,
      lg: 2,
    },
  },
  wrapLogo: {
    display: 'flex',
    alignItems: 'center',
    columnGap: {
      xs: 1,
      md: 1.5,
      lg: 2,
    },
  },
  logo: {
    display: 'flex',
    width: (theme) => ({
      xs: theme.spacing(3.75),
      md: 'auto',
    }),
    backgroundColor: 'transparent',

    '&.MuiButton-root': {
      p: 0,

      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  wrapCta: {
    zIndex: 2,
  },
}

export default styles
