import { CircularProgress } from '../../../components'
import useAccess from './useAccess'
import Modal from './Modal'
import View from './View'

const Access = () => {
  const { unauthenticationIsConfirmedInLoad, handleCloseButton, ...viewProps } =
    useAccess()

  if (!Boolean(unauthenticationIsConfirmedInLoad))
    return <CircularProgress height='80vh' />

  return (
    <Modal onCancel={handleCloseButton}>
      <View {...viewProps} />
    </Modal>
  )
}

export default Access
