import { useNavigate, useLocation } from 'react-router-dom'
import { useState } from 'react'

import EVENTS_NAMES from '../../../../analytics/eventsNames.json'
import useSearchParams from '../useSearchParams'
import useAccessLoad from '../useAccessLoad'
import useAccessFinish from '../useAccessFinish'
import { Session } from '../../../../business'

const useAccess = () => {
  const { state } = useLocation()
  const navigate = useNavigate()
  const navigateWithSearchParams = (to, options) =>
    navigate(to + window.location.search, options)

  const paramsData = useSearchParams()
  const accessLoad = useAccessLoad(paramsData)
  const accessFinish = useAccessFinish(paramsData)

  const [loginEmail, setLoginEmail] = useState(state?.loginEmail)
  const [registerEmail, setRegisterEmail] = useState()

  const handleGoToForgottenPassword = () => {
    document.dispatchEvent(
      new CustomEvent('payperAnalyticsPublicEvent', {
        detail: {
          name: EVENTS_NAMES.PA_CLICK_GO_TO_FP,
        },
      }),
    )

    navigateWithSearchParams('/forgotten-password', {
      state: { from: 'access', loginEmail },
    })
  }

  const handleCloseButton = () => {
    document.dispatchEvent(
      new CustomEvent('payperAnalyticsPublicEvent', {
        detail: {
          name: EVENTS_NAMES.PA_CLICK_CANCEL,
        },
      }),
    )

    accessFinish.handleFinishUnauth('User cancelled the process')
  }

  const handleFinishAuth = ({ userData, isNewUser }) => {
    Session.storeSession(userData)
    accessFinish.handleFinishAuth({ isNewUser })
  }

  return {
    unauthenticationIsConfirmedInLoad: accessLoad.unauthenticationIsConfirmed,
    handleCloseButton,
    registerEmail,
    setRegisterEmail,
    loginEmail,
    setLoginEmail,
    handleFinishAuth,
    paramsData,
    handleGoToForgottenPassword,
  }
}

export default useAccess
