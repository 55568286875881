import { forwardRef } from 'react'
import { useNavigate } from 'react-router-dom'

import View from './View'

const Hero = forwardRef((_, ref) => {
  const navigate = useNavigate()
  const navigateWithSearchParams = (to) => navigate(to + window.location.search)

  return (
    <View ref={ref} openRegister={() => navigateWithSearchParams('/access')} />
  )
})

export default Hero
