import theme from '../../../../theme'

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    justifyContent: {
      xl: 'center',
    },
    columnGap: {
      xs: 1,
      md: 1.25,
    },
    width: (theme) => `min(100%, ${theme.spacing(79)})`,
    overflowX: 'auto',

    pt: {
      xs: 2.5,
      md: 3.5,
    },
    px: {
      xs: 1.25,
      md: 3,
    },
    pb: {
      xs: 4,
      md: 5,
    },
  },
  card: {
    flex: '0 0 auto',
    width: (theme) => theme.spacing(19.5),
    display: 'flex',
    flexDirection: 'column',
    rowGap: {
      xs: 1,
      md: 1.5,
    },
  },
  shrink: {
    flex: 1,
    minWidth: (theme) => ({
      xs: theme.spacing(13),
      md: theme.spacing(19.5),
    }),
    maxWidth: (theme) => ({
      xs: theme.spacing(13),
      md: theme.spacing(19.5),
    }),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 0.625,
    pl: 0.5,
  },
  title: {
    ...theme.typography.h5,
  },
  icon: {
    display: 'flex',
    fontSize: '1.25rem',
  },
}

export default styles
