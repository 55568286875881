import Modal from './Modal'
import SponsoredModalWrapper from './SponsoredModalWrapper'

const SponsoredModal = ({
  logoUrl,
  customTitle,
  customDescription,
  onCancel,
  children,
  ...restProps
}) => {
  const sponsorWrapperProps = {
    logoUrl,
    customTitle,
    customDescription,
    onCancel,
  }

  return (
    <SponsoredModalWrapper {...sponsorWrapperProps}>
      <Modal {...restProps}>{children}</Modal>
    </SponsoredModalWrapper>
  )
}

export default SponsoredModal
