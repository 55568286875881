const styles = {
  root: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'primary.main',
    color: 'neutral.700',
    '& svg, span': {
      color: 'neutral.700',
    },
    '& .Mui-selected': {
      fontWeight: 600,
    },
  },
}

export default styles
