import React from 'react'
import { Typography } from '@mui/material'

import { useTexts } from '../../../../texts'
import LogoPayper from '../../LogoPayper'
import styles from './poweredByPayper.styles.js'

const PoweredByPayper = () => {
  const texts = useTexts()
  return (
    <Typography component='small' sx={styles.root}>
      {texts.getPayperMarketingLabel()}
      <LogoPayper width={62} height={16} />
    </Typography>
  )
}

export default PoweredByPayper
