import { useState, useEffect } from 'react'

const initialState = {
  isLoading: true,
  data: undefined,
  error: false,
}

const useFetch = (fetchFn) => {
  const [state, setState] = useState(initialState)

  useEffect(() => {
    ;(async () => {
      try {
        const result = await fetchFn()

        setState({
          isLoading: false,
          data: result,
          error: false,
        })
      } catch (error) {
        setState({ isLoading: false, data: null, error })
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return state
}

export default useFetch
