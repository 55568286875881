import { User } from '../../../../business'
import View from './View'
import Balance from './Balance'
import BankCard from './BankCard'
import Bonus from './Bonus'

const BalanceData = () => {
  const { state: bankCard } = User.useBankCard()

  return (
    <View>
      <Bonus />
      <Balance withTopUp={Boolean(bankCard?.data)} />
      <BankCard bankCardData={bankCard.data} />
    </View>
  )
}

export default BalanceData
