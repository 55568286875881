import theme from '../../../../theme'

const styles = {
  root: {
    ...theme.typography.body3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: 0.375,
    color: 'neutral.400',
  },
}

export default styles
