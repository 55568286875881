import { Box, Link as MUILink, Typography } from '@mui/material'

import { useTexts } from '../../../texts'
import styles from './privacyPolicySummary.styles'

const PRIVACY_SUMMARY_CONTENT = [
  {
    getQuestion: (texts) => texts.getPrivacySummaryQuestion1(),
    getResponse: (texts) => texts.getPrivacySummaryResponse1(),
    link: 'https://www.bepayper.com/politica-de-privacidad#company',
  },
  {
    getQuestion: (texts) => texts.getPrivacySummaryQuestion2(),
    getResponse: (texts) => texts.getPrivacySummaryResponse2(),
    link: 'https://www.bepayper.com/politica-de-privacidad#purpose',
  },
  {
    getQuestion: (texts) => texts.getPrivacySummaryQuestion3(),
    getResponse: (texts) => texts.getPrivacySummaryResponse3(),
    link: 'https://www.bepayper.com/politica-de-privacidad#legitimacy',
  },
  {
    getQuestion: (texts) => texts.getPrivacySummaryQuestion4(),
    getResponse: (texts) => texts.getPrivacySummaryResponse4(),
    link: 'https://www.bepayper.com/politica-de-privacidad#transfer',
  },
  {
    getQuestion: (texts) => texts.getPrivacySummaryQuestion5(),
    getResponse: (texts) => texts.getPrivacySummaryResponse5(),
    getList: (texts) => texts.getPrivacySummaryResponseList5(),
    link: 'https://www.bepayper.com/politica-de-privacidad#rights',
  },
  {
    getQuestion: (texts) => texts.getPrivacySummaryQuestion6(),
    getResponse: (texts) => texts.getPrivacySummaryResponse6(),
    link: 'https://www.bepayper.com/politica-de-privacidad#profile',
  },
  {
    getQuestion: (texts) => texts.getPrivacySummaryQuestion7(),
    getResponse: (texts) => texts.getPrivacySummaryResponse7(),
    getLinkLabel: (texts) => texts.getPrivacySummaryLinkLabel7(),
    link: '/#public-contact-form',
  },
]

const TextBlock = ({
  getQuestion,
  getResponse,
  getList,
  getLinkLabel,
  link,
}) => {
  const texts = useTexts()

  return (
    <Box sx={styles.textBlock}>
      <Typography sx={styles.title}>{getQuestion(texts)}</Typography>
      <Typography sx={styles.text}>{getResponse(texts)}</Typography>
      {Boolean(getList) && (
        <Box component='ul' sx={styles.list}>
          {getList(texts).map((item, i) => (
            <Typography key={i} component='li' sx={styles.text}>
              {item}
            </Typography>
          ))}
        </Box>
      )}
      <MUILink href={link} target='_blank' rel='noreferrer' sx={styles.link}>
        {Boolean(getLinkLabel) ? getLinkLabel(texts) : texts.getInfoLinkLabel()}
      </MUILink>
    </Box>
  )
}

const PrivacyPolicySummary = () => {
  return (
    <Box sx={styles.root}>
      {PRIVACY_SUMMARY_CONTENT.map((textBlock, i) => (
        <TextBlock key={i} {...textBlock} />
      ))}
    </Box>
  )
}

export default PrivacyPolicySummary
