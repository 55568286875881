import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { BonusCard } from '../../../components'
import { useTexts } from '../../../../texts'
import { Present } from '../../../../styleguide/icons'
import { User } from '../../../../business'
import CardWrapper from './CardWrapper'

const Bonus = () => {
  const texts = useTexts()
  const bonus = User.useActiveBonus()
  const theme = useTheme()
  const widerThanTablet = useMediaQuery(theme.breakpoints.up('md'))

  if (bonus.isLoading || bonus.error || !Boolean(bonus.data)) return null
  return (
    <CardWrapper
      shrink
      title={
        widerThanTablet ? texts.getBonusLabelLongDesc() : texts.getBonusLabel()
      }
      icon={<Present />}
    >
      <BonusCard bonusData={bonus.data} reducedVersion={!widerThanTablet} />
    </CardWrapper>
  )
}

export default Bonus
