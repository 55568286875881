import { useEffect } from 'react'
import PropTypes from 'prop-types'

import EVENTS_NAMES from '../../../../../analytics/eventsNames.json'
import useUserDataForm from './useUserDataForm'
import View from './View'

const FORM_ID = EVENTS_NAMES.PA_FORM_SUBMIT_SIGNUP

const userDataStepPropTypes = {
  email: PropTypes.string.isRequired,
  onChangeEmail: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  attribution: PropTypes.object,
}

const UserDataStep = ({
  email,
  onContinue,
  onChangeEmail,
  attribution = {},
}) => {
  const { status, submitForm, updateLegalCheckbox, ctaLabel } = useUserDataForm(
    FORM_ID,
    attribution,
  )

  const handleSubmit = async (ev) => {
    ev.preventDefault()
    const formData = new FormData(ev.target)
    const formValues = Object.fromEntries(formData)
    submitForm(formValues)
  }

  useEffect(() => {
    if (status.result !== null) {
      onContinue(status.result)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  useEffect(() => {
    document.dispatchEvent(
      new CustomEvent('payperAnalyticsPublicEvent', {
        detail: {
          name: EVENTS_NAMES.PA_PAGE_VIEW_SIGNUP,
        },
      }),
    )
  }, [])

  return (
    <View
      email={email}
      formId={FORM_ID}
      onSubmit={handleSubmit}
      onChangeControlledLegalCheckbox={updateLegalCheckbox}
      ctaLabel={ctaLabel}
      {...status}
      onChangeEmail={onChangeEmail}
    />
  )
}

UserDataStep.propTypes = userDataStepPropTypes

export default UserDataStep
