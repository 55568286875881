/* eslint-disable no-useless-computed-key */
import { hasCognitoConfig, getCognitoConfig } from '../../config'

if (!hasCognitoConfig()) {
  throw new Error('Proper Cognito config needed')
}

export const AUTHORIZE_IDENTITY_PROVIDER = {
  GOOGLE: 'Google',
  APPLE: 'SignInWithApple',
}

const CognitoConfig = getCognitoConfig()

export const getThirdPartyIdpAuthorizedEndpoint = ({
  identityProvider,
  isSSO,
  stateParam,
}) => {
  if (!Object.values(AUTHORIZE_IDENTITY_PROVIDER).includes(identityProvider)) {
    throw Error(
      "Error on getThirdPartyIdpAuthorizedEndpoint method: The identity provider must be one of the following values: 'Google', 'SignInWithApple'",
    )
  }
  const baseUrl = `https://${CognitoConfig.poolDomain}/oauth2/authorize`

  const searchParams = {
    ['response_type']: CognitoConfig.authorizeRespType,
    ['identity_provider']: identityProvider,
    ['client_id']: CognitoConfig.appClientId,
    ['redirect_uri']: isSSO
      ? CognitoConfig.ssoRedirectUri
      : CognitoConfig.redirectUri,
  }
  if (Boolean(stateParam)) {
    searchParams.state = stateParam
  }

  return `${baseUrl}?${new URLSearchParams(searchParams).toString()}`
}

const getJWTsWithRedirectUri = async (code, redirectUri) => {
  if (!Boolean(code)) return null
  const requestParams = {
    grant_type: 'authorization_code',
    client_id: CognitoConfig.appClientId,
    code,
    redirect_uri: redirectUri,
  }

  const response = await fetch(
    `https://${CognitoConfig.poolDomain}/oauth2/token`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams(requestParams).toString(),
    },
  )
  const tokens = await response.json()

  const formattedTokens = {
    refreshToken: tokens.refresh_token,
    idToken: tokens.id_token,
    idTokenExpiresAt: Math.round(
      (new Date().getTime() + tokens.expires_in * 1000) / 1000,
    ),
    accessToken: tokens.access_token,
    accessTokenExpiresAt: Math.round(
      (new Date().getTime() + tokens.expires_in * 1000) / 1000,
    ),
  }
  return formattedTokens
}

export const getJWTs = async (code) =>
  await getJWTsWithRedirectUri(code, CognitoConfig.redirectUri)

export const getSSOJWTs = async (code) =>
  await getJWTsWithRedirectUri(code, CognitoConfig.ssoRedirectUri)

export const logout = async (refreshToken) => {
  const response = await fetch(
    `https://${CognitoConfig.poolDomain}/oauth2/revoke`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        token: refreshToken,
        client_id: CognitoConfig.appClientId,
      }).toString(),
    },
  )

  if (!(response.ok && response.status === 200)) {
    console.error('error on logout')
  }
}
