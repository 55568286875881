import theme from '../../../../../theme'

const styles = {
  description: {
    ...theme.body2,
    color: 'neutral.500',
  },
  bg: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 2,
    mt: 2,
    py: 2,
    position: 'relative',
    backgroundColor: 'neutral.200',

    '&::before': {
      content: '""',
      display: 'block',
      width: '100vw',
      height: '100vh',
      position: 'absolute',
      top: 0,
      left: 'calc(50% - 50vw)',
      zIndex: 0,
      backgroundColor: 'inherit',
    },
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 0.25,
    my: 0,
    pl: {
      xs: 0.5,
      md: 1.5,
    },
    color: 'neutral.400',
    position: 'relative', // NOTE: Needed for place the elemnt above the background
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    columnGap: 1,
  },
  icon: {
    display: 'flex',
    fontSize: (theme) => theme.spacing(1.25),
  },
  itemText: {
    fontSize: (theme) => ({
      xs: theme.spacing(0.875),
      lg: theme.spacing(0.875),
    }),
    fontWeight: 500,

    '& > span': {
      color: 'neutral.600',
    },
  },
}

export default styles
