import { Typography, Skeleton } from '@mui/material'

import styles from './articleCard.styles'

const Category = ({ category, isLoading }) => (
  <>
    {isLoading ? (
      <Typography variant='body3' component='p' sx={styles.category}>
        <Skeleton width={80} />
      </Typography>
    ) : Boolean(category) ? (
      <Typography variant='body3' component='p' sx={styles.category}>
        {category}
      </Typography>
    ) : (
      <Typography sx={{ ...styles.category, ...styles.invisible }}>
        x
      </Typography>
    )}
  </>
)

export default Category
