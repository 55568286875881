import { useRef, useEffect } from 'react'
import { Box, Button, Container, Typography } from '@mui/material'

import { useTexts } from '../../../../texts'
import useIntersectionObserver from '../../../useIntersectionObserver'
import { Picture } from '../../../components'
import { ReactComponent as ArrowRight } from './ArrowRight.svg'
import { ReactComponent as Check } from './Check.svg'
import imageAvif from './view-of-a-phone-with-PAYPER-app.avif'
import imageWebp from './view-of-a-phone-with-PAYPER-app.webp'
import imagePng from './view-of-a-phone-with-PAYPER-app.png'
import styles from './benefits.styles.js'

const BenefitsView = ({ openRegister }) => {
  const texts = useTexts()

  const ITEMS = [
    texts.getHomepageBenefitsItemOneLabel(),
    texts.getHomepageBenefitsItemTwoLabel(),
    texts.getHomepageBenefitsItemThreeLabel(),
  ]

  const targets = useRef(new Set())
  const [entries, setObservedNodes] = useIntersectionObserver({})

  useEffect(() => {
    setObservedNodes(() => [...targets.current])
  }, [setObservedNodes])

  useEffect(() => {
    for (const entry of entries) {
      if (entry.isIntersecting) {
        entry.target.classList.add('isVisible')

        setObservedNodes((observedNodes) =>
          observedNodes.filter((node) => node !== entry.target),
        )
      }
    }
  }, [entries, setObservedNodes])

  return (
    <Box component='section' sx={styles.root}>
      <Container>
        <Box sx={styles.content}>
          <Typography
            ref={(element) => targets.current.add(element)}
            variant='h2'
            sx={styles.heading}
            style={{ '--delay': '100ms' }}
          >
            {texts.getHomepageBenefitsTitleLabel()}
          </Typography>
          <Box
            ref={(element) => targets.current.add(element)}
            sx={styles.wrapPicture}
          >
            <Picture
              width={401}
              height={464}
              avif={imageAvif}
              webp={imageWebp}
              fallback={imagePng}
              loading='lazy'
            />
          </Box>
          <Box
            ref={(element) => targets.current.add(element)}
            component='ul'
            sx={styles.list}
          >
            {ITEMS.map((item, idx) => (
              <Box
                key={idx}
                component='li'
                sx={styles.item}
                style={{ '--delay': `${90 * idx + 100}ms` }}
              >
                <Box key={idx} sx={styles.icon} aria-hidden>
                  <Check />
                </Box>
                <Typography>{item}</Typography>
              </Box>
            ))}
          </Box>
          <Box
            ref={(element) => targets.current.add(element)}
            sx={styles.wrapCta}
            style={{ '--delay': '50ms' }}
          >
            <Button
              id='home-benefits-register-btn'
              color='secondary'
              endIcon={<ArrowRight />}
              onClick={openRegister}
            >
              {texts.getHomepageRegisterAction()}
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default BenefitsView
