import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Session } from '../../../business'
import EVENTS_NAMES from '../../../analytics/eventsNames.json'
import { ACCESS_PARAMS } from './useSearchParams'

const PAYPER_DEEP_LINK_URL_SEARCH_PARAMS = new URLSearchParams({
  payperAccessToken: '',
  p_visible: '',
})

const mergeSearchParams = (...searchParamsList) => {
  const mergedParams = new URLSearchParams()
  searchParamsList.forEach((params) => {
    params.forEach((value, key) => {
      mergedParams.append(key, value)
    })
  })
  return mergedParams
}

const useAccessLoad = (paramsData) => {
  const navigate = useNavigate()

  const [unauthenticationIsConfirmed, setUnauthenticationIsConfirmed] =
    useState(false)

  useEffect(() => {
    ;(async () => {
      if (!paramsData) return

      const getSessionAuthentication = async () => {
        let isAuthenticated = false
        if (Session.isAuthenticated()) {
          if (Session.hasValidAccessToken()) {
            isAuthenticated = true
          } else {
            const tokens = await Session.refreshSession()
            if (Boolean(tokens)) {
              isAuthenticated = true
            }
          }
        }
        return isAuthenticated
      }

      const hasRedirectWithDeepLinkFlag = () =>
        Boolean(
          paramsData.registrationParams?.[
            ACCESS_PARAMS.PAYPER_DEEP_LINK_ON_UNAUTH
          ],
        )

      const getRedirectWithDeepLinkUri = () => {
        const baseUri = paramsData.getRedirectOnUnauthBaseUri()
        const baseURL = new URL(baseUri)

        const redirectSearchParams = new URLSearchParams(baseURL?.search)
        const mergedParams = mergeSearchParams(
          redirectSearchParams,
          PAYPER_DEEP_LINK_URL_SEARCH_PARAMS,
        )
        return `${baseURL.origin}${
          baseURL.pathname ?? ''
        }?${mergedParams.toString()}`
      }

      const isUserAuthenticated = await getSessionAuthentication()

      if (isUserAuthenticated) {
        document.dispatchEvent(
          new CustomEvent('payperAnalyticsPublicEvent', {
            detail: {
              name: EVENTS_NAMES.PA_SSO,
              userSub: Session.getUserSub(),
            },
          }),
        )

        if (Boolean(window?.Android?.authorizationSuccessful)) {
          window.Android.authorizationSuccessful(
            JSON.stringify(Session.getAuthParams()),
          )
        }
        if (paramsData.doRedirectToActionsOnAuth) {
          window.location = paramsData.getActionsAppUri()
        } else if (paramsData.isRedirectUriSet) {
          window.location = paramsData.getRedirectAuthUri()
        } else {
          navigate('/dashboard')
        }
      } else {
        if (hasRedirectWithDeepLinkFlag()) {
          window.location = getRedirectWithDeepLinkUri()
        } else {
          setUnauthenticationIsConfirmed(true)
        }
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsData])

  return {
    unauthenticationIsConfirmed,
  }
}

export default useAccessLoad
