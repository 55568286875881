import { useTexts } from '../../../../../texts'
import { useFormV2, composeValidators, validators } from '../../../../../forms'
import { Auth } from '../../../../../business'

const useEmailForm = (formId) => {
  const texts = useTexts()
  const { isValidEmail, isFilled } = validators

  const FORM_VALIDATION_CONFIG = {
    email: composeValidators([
      [isFilled, texts.getErrorRequired()],
      [isValidEmail, texts.getErrorEmail()],
    ]),
  }

  const getIsNewUser = async (formValues) => {
    const registrationTypologyForEmail =
      await Auth.getRegistrationTypologyForEmail(formValues.email)
    if (registrationTypologyForEmail === Auth.REGISTRATION_TYPOLOGY.STANDARD) {
      return { isNewUser: false }
    } else if (
      registrationTypologyForEmail === Auth.REGISTRATION_TYPOLOGY.IDP
    ) {
      throw new Error(texts.getAccessWithSocialLoginEmailError())
    } else {
      return { isNewUser: true }
    }
  }

  return useFormV2(formId, getIsNewUser, FORM_VALIDATION_CONFIG)
}

export default useEmailForm
