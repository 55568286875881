import { Box } from '@mui/material'

import { styles } from './bankCardForm.styles'

const IframedInput = ({ id, label, Icon }) => {
  return (
    <Box sx={styles.input}>
      <Box aria-hidden sx={styles.inputIcon}>
        {Icon}
      </Box>
      <Box component='label' sx={styles.inputLabel}>
        {label}
      </Box>
      <Box id={id} sx={styles.inputIframe} />
    </Box>
  )
}

export default IframedInput
