import theme from '../../../../theme'

// NOTE: styles taken from
// - PrivatePages/UpdatePassword (layout) &
// - AccessPages/Access/UserDataStep (user-inputs)
// -> extract reusable components??

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: (theme) => `min(100%, ${theme.spacing(28.75)})`,
    mx: {
      xs: 1,
      md: 0,
    },
    my: {
      xs: 2,
      md: 2.5,
    },
    px: {
      xs: 1.25,
      md: 3,
    },
    py: {
      xs: 2,
      md: 3,
    },
  },
  title: {
    ...theme.typography.h5,
    mb: 2,
    color: 'neutral.700',
  },
  wrapFields: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 1,
  },

  groupFields: {
    display: 'grid',
    rowGap: 0.25,

    '& > .MuiFormControl-root:nth-of-type(1)': {
      '& .MuiInputBase-root': {
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
      },
    },
    '& > .MuiFormControl-root:nth-of-type(2)': {
      '& .MuiInputBase-root': {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      },
    },
  },
  groupErrors: {
    display: 'flex',
    alignItems: 'center',
    columnGap: (theme) => theme.spacing(0.625),
    marginTop: (theme) => theme.spacing(0.5),
    marginLeft: (theme) => theme.spacing(0.75),
    marginRight: (theme) => theme.spacing(0.75),
    fontSize: (theme) => theme.spacing(0.75),
    fontWeight: 500,
    color: 'error.main',

    '& > svg': {
      fontSize: (theme) => theme.spacing(1.25),
    },
  },

  collapsible: {
    display: 'flex',
    alignItems: 'center',
    columnGap: (theme) => theme.spacing(0.5),
  },
  collapsibleButton: {
    flex: (theme) => `0 0 ${theme.spacing(2.5)}`,
    height: (theme) => theme.spacing(2.5),
    color: 'neutral.700',
  },
  cta: {
    mt: 1,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 1,
  },
}

export default styles
