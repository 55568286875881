import { Dialog } from '@mui/material'

import { Modal } from '../Modal'

const ForgotenPasswordLayout = ({ children, onCancel, stepsLayoutProps }) => {
  return (
    <Dialog aria-labelledby='forgottenPassword' open>
      <Modal onCancel={onCancel} background='dark' {...stepsLayoutProps}>
        {children}
      </Modal>
    </Dialog>
  )
}

export default ForgotenPasswordLayout
