import { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'

import { useTexts } from '../../../texts'
import { User } from '../../../business'

import { BankCardWithEditionButton, LogoutDialog } from '../../components'
import styles from './account.styles'
import PersonalData from './PersonalData'
import Balance from './Balance'

const Section = ({ title, children }) => {
  return (
    <Box>
      <Typography component='h2' sx={styles.sectionTitle}>
        {title}
      </Typography>
      <Box sx={styles.sectionContent}>{children}</Box>
    </Box>
  )
}

const AccountPropTypes = {
  onClickTopUp: PropTypes.func,
  onClickEditBankCard: PropTypes.func,
  onClickAddBankCard: PropTypes.func,
  onClickUpdatePersonalData: PropTypes.func,
  onClickUpdatePassword: PropTypes.func,
}

const Account = ({
  onClickTopUp,
  onClickEditBankCard,
  onClickAddBankCard,
  onClickUpdatePersonalData,
  onClickUpdatePassword,
}) => {
  const texts = useTexts()

  const { state: bankCard } = User.useBankCard()
  const { state: balance } = User.useBalance()
  const {
    state: { data: userData },
  } = User.usePersonalData()
  const bonus = User.useActiveBonus()

  const [showLogoutDialog, setShowLogoutDialog] = useState(false)

  return (
    <>
      <LogoutDialog
        isOpen={showLogoutDialog}
        onClose={() => setShowLogoutDialog(false)}
      />
      <Box sx={styles.root}>
        <Section title={texts.balance()}>
          <Balance
            balance={balance}
            bonus={bonus}
            onClickTopUp={onClickTopUp}
          />
        </Section>

        {!bankCard.isLoading && (
          <Section title={texts.bankCard()}>
            <BankCardWithEditionButton
              bankCardData={bankCard.data}
              onClickEditBankCard={onClickEditBankCard}
              onClickAddBankCard={onClickAddBankCard}
            />
          </Section>
        )}

        {Boolean(userData) && (
          <Section title={texts.personalData()}>
            <PersonalData
              userData={userData}
              onClickUpdatePassword={onClickUpdatePassword}
              onClickUpdatePersonalData={onClickUpdatePersonalData}
              onClickLogout={() => setShowLogoutDialog(true)}
            />
          </Section>
        )}
      </Box>
    </>
  )
}

Account.propTypes = AccountPropTypes

export default Account
