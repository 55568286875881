import { useNavigate } from 'react-router-dom'

import { Session } from '../../../business'
import { nativeApps } from '../../../thirdPartyIntegration'

const useAccessFinish = (paramsData) => {
  const navigate = useNavigate()

  const handleFinishUnauth = () => {
    nativeApps.handleFinishUnauth()

    if (paramsData.isRedirectOnUnauthUriSet) {
      window.location = paramsData.getRedirectOnUnauthBaseUri()
    } else {
      navigate('/' + window.location.search)
    }
  }

  const handleFinishAuth = ({ isNewUser }) => {
    nativeApps.handleFinishAuth(JSON.stringify(Session.getAuthParams()))

    if (paramsData.doRedirectToActionsOnAuth) {
      window.location = paramsData.getActionsAppUri(isNewUser)
    } else if (isNewUser) {
      navigate('/register-success' + window.location.search)
    } else if (paramsData.isRedirectUriSet) {
      window.location = paramsData.getRedirectAuthUri()
    } else {
      navigate('/dashboard')
    }
  }

  return {
    handleFinishUnauth,
    handleFinishAuth,
  }
}

export default useAccessFinish
