import { useParams } from 'react-router-dom'

import EVENTS_NAMES from '../../../../analytics/eventsNames.json'
import { Public } from '../../../../business'
import { openMediaLink } from '../../../utils'
import useSectionArticles from './useSectionArticles'
import View from './View'

const SectionPage = () => {
  const { sectionId } = useParams()

  const sections = Public.useArticlesSections()
  const sectionTitle = sections?.data?.[sectionId] ?? ''

  const articlesData = useSectionArticles(sectionId)

  const handleOpenArticle = (article) => {
    document.dispatchEvent(
      new CustomEvent('payperAnalyticsPrivateEvent', {
        detail: {
          name: EVENTS_NAMES.PD_CLICK_ARTICLE,
          source: 'topic',
          articleId: article.articleId,
          mediaId: article.mediaProviderId,
        },
      }),
    )

    openMediaLink(article.url)
  }

  return (
    <View
      sectionId={sectionId}
      sectionTitle={sectionTitle}
      onClickArticle={handleOpenArticle}
      {...articlesData}
    />
  )
}

export default SectionPage
