import { Box } from '@mui/material'

import styles from './decorator.styles.js'

const Decorator = ({ fullWidth = false }) => {
  return (
    <Box sx={styles.root}>
      <svg
        width={fullWidth ? '100%' : '28'}
        height='1'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <line
          x1='0.5'
          y1='0.5'
          x2='100%'
          y2='0.5'
          stroke='#969594'
          strokeDasharray='2 2'
        />
      </svg>
    </Box>
  )
}

export default Decorator
