import { backofficeEndpoint } from '../../config'

export const requestOrderData = async (sub) => {
  const response = await fetch(`${backofficeEndpoint}/bank-card-order`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ sub }),
  })

  if (!response.ok) {
    throw new Error('Order request data failed')
  }

  return await response.json()
}

export const requestPreauthorization = async (operationId, orderId) => {
  const preauthResponse = await fetch(
    `${backofficeEndpoint}/bank-card-order/preauth`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ operationId, orderId }),
    },
  )

  const parsedBodyResp = await preauthResponse.json()

  if (!preauthResponse.ok) {
    throw new Error(parsedBodyResp.error)
  }

  return parsedBodyResp
}

export const requestAuthorizationV1 = async (operationId, orderId) => {
  const authBody = { orderId, operationId }

  const authResponse = await fetch(
    `${backofficeEndpoint}/bank-card-order/authorizeV1`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(authBody),
    },
  )

  if (!authResponse.ok) {
    throw new Error('Auth failed')
  }

  return await authResponse.json()
}

export const requestAuthorizationV2 = async (
  threeDSServerTransID,
  clientInfo,
) => {
  const authBody = { threeDSServerTransID, clientInfo }

  const authResponse = await fetch(
    `${backofficeEndpoint}/bank-card-order/authorizeV2`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(authBody),
    },
  )

  if (!authResponse.ok) {
    throw new Error('Auth failed')
  }

  return await authResponse.json()
}
