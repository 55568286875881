import { useState } from 'react'
import PropTypes from 'prop-types'

import { CircularProgress } from '../index'
import { SponsoredModal } from '../Modal'
import getConvertionParams from '../../Pages/AccessPages/sso/getConvertionParams'
import {
  UserStep,
  ConfirmationCodeStep,
  BankCardStepWidget,
  TopUpStepWidget,
  SuccessStepWithTimeout,
  ErrorStep,
} from './Steps'

import registrationStyles from './registration.styles'

const REGISTRATION_STEPS_NAMES = {
  USER_DATA: 'register-user-data',
  CONFIRMATION_CODE: 'register-confirm-user-email',
  BANK_CARD: 'register-insert-bank-card',
  TOP_UP: 'register-top-up',
  SUCCESS: 'register-success',
  ERROR: 'register-error',
}

const STEP_WRAPPER_PROPS = [
  {
    currentStep: 0,
    stepName: REGISTRATION_STEPS_NAMES.USER_DATA,
    caption: (texts) => texts.getRegisterTitleLabel(),
    title: (texts) => texts.getRegisterSubtitleLabel(),
    background: 'solid',
    sx: registrationStyles.successStep,
  },
  {
    currentStep: 0,
    stepName: REGISTRATION_STEPS_NAMES.CONFIRMATION_CODE,
    caption: (texts) => texts.getRegisterCodeVerificationLabel(),
    title: (texts) => texts.getRegisterCodeVerificationSubtitleLabel(),
    background: 'solid',
    sx: registrationStyles.confirmationCodeStep,
  },
  {
    currentStep: 1,
    stepName: REGISTRATION_STEPS_NAMES.BANK_CARD,
    title: (texts) => texts.getIntroBankCardTitle(),
    background: 'gradient',
    sx: {},
  },
  {
    currentStep: 2,
    stepName: REGISTRATION_STEPS_NAMES.TOP_UP,
    title: (texts) => texts.getBalanceTopUpAfterBankCardTitle(),
    description: (texts) => texts.getBalanceTopUpDescription(),
    background: 'gradient',
    sx: {},
  },
  {
    currentStep: undefined, // for not showing Stepper
    stepName: REGISTRATION_STEPS_NAMES.SUCCESS,
    title: (texts) => texts.getRegisterSuccessTitleLabel(),
    background: 'gradient',
    sx: {},
  },
  {
    currentStep: undefined, // for not showing Stepper
    stepName: REGISTRATION_STEPS_NAMES.ERROR,
    title: (texts) => texts.getPurchaseErrorTitle2(),
    background: 'gradient',
    sx: {},
  },
]

const STEP_ERROR_INDEX = STEP_WRAPPER_PROPS.findIndex(
  (step) => step.stepName === REGISTRATION_STEPS_NAMES.ERROR,
)

const NO_USER_REGISTRATION_STEPS_NAMES = [
  REGISTRATION_STEPS_NAMES.BANK_CARD,
  REGISTRATION_STEPS_NAMES.TOP_UP,
]

const filterUserRegistrationStepsFromWrapperProps = (allWrapperProps) =>
  allWrapperProps.filter(
    (stepProps) =>
      !NO_USER_REGISTRATION_STEPS_NAMES.includes(stepProps.stepName),
  )

const getWrapperProps = (isJustUserRegistration) =>
  isJustUserRegistration
    ? filterUserRegistrationStepsFromWrapperProps(STEP_WRAPPER_PROPS)
    : STEP_WRAPPER_PROPS

const ssoRegistrationProptypes = {
  loading: PropTypes.bool,
  initialStepData: PropTypes.shape({
    idx: PropTypes.number.isRequired,
    data: PropTypes.shape({
      sub: PropTypes.string,
    }),
  }),
  logoUrl: PropTypes.string,
  customTitle: PropTypes.string,
  customDescription: PropTypes.string,
  articlePrice: PropTypes.string,
  onClickLogin: PropTypes.func,
  onFinish: PropTypes.func.isRequired,
  onFinishWithError: PropTypes.func.isRequired,
  renderSocialLogin: PropTypes.func,
  askJustForUserRegistration: PropTypes.bool,
}

const SSORegistration = ({
  loading = false,
  initialStepData,
  logoUrl,
  customTitle,
  customDescription,
  articlePrice,
  onClickLogin,
  onFinish,
  onFinishWithError,
  renderSocialLogin,
  askJustForUserRegistration = false,
}) => {
  const stepWrapperProps = getWrapperProps(askJustForUserRegistration)

  const [step, setStep] = useState({
    ...initialStepData,
    wrapperProps: stepWrapperProps[initialStepData.idx],
  })

  const [hasCurrentStepError, setHasCurrentStepError] = useState(false)
  const [stepOriginatingError, setStepOriginatingError] = useState()

  const goToNextStep = (data = {}) => {
    setStep((step) => ({
      idx: step.idx + 1,
      data: { ...step.data, ...data },
      wrapperProps: stepWrapperProps[step.idx + 1],
    }))
    setHasCurrentStepError(false)
  }

  const handleError = (
    { isErrorShowedOnView } = { isErrorShowedOnView: false },
  ) => {
    setHasCurrentStepError(true)

    if (!isErrorShowedOnView) {
      setStepOriginatingError(step.idx)

      setStep((step) => ({
        ...step,
        idx: STEP_ERROR_INDEX,
        wrapperProps: stepWrapperProps[STEP_ERROR_INDEX],
      }))
    }
  }

  const retryAfterError = () => {
    setStepOriginatingError()
    setHasCurrentStepError(false)

    setStep((step) => ({
      ...step,
      idx: stepOriginatingError,
      wrapperProps: stepWrapperProps[stepOriginatingError],
    }))
  }

  const handleCloseModal = () => {
    if (step.idx === STEP_ERROR_INDEX || hasCurrentStepError) {
      onFinishWithError()
    } else {
      onFinish(step.idx)
    }
  }

  const onCloseModal = step.idx !== 4 ? handleCloseModal : undefined

  if (loading) return <CircularProgress height='80vh' />
  return (
    <div aria-label='crea-tu-cuenta-payper'>
      <SponsoredModal
        logoUrl={logoUrl}
        customTitle={customTitle}
        customDescription={customDescription}
        onCancel={onCloseModal}
        stepperStepsLenght={askJustForUserRegistration ? 2 : 3}
        {...step.wrapperProps}
      >
        {step.wrapperProps.stepName === REGISTRATION_STEPS_NAMES.USER_DATA ? (
          <UserStep
            attribution={getConvertionParams()}
            onContinue={goToNextStep}
            onGoToLogin={onClickLogin}
            renderSocialLogin={renderSocialLogin}
          />
        ) : null}
        {step.wrapperProps.stepName ===
        REGISTRATION_STEPS_NAMES.CONFIRMATION_CODE ? (
          <ConfirmationCodeStep onContinue={goToNextStep} {...step.data} />
        ) : null}
        {step.wrapperProps.stepName === REGISTRATION_STEPS_NAMES.BANK_CARD ? (
          <BankCardStepWidget
            sub={step.data.sub}
            articlePrice={articlePrice}
            onContinue={goToNextStep}
            onError={handleError}
          />
        ) : null}
        {step.wrapperProps.stepName === REGISTRATION_STEPS_NAMES.TOP_UP ? (
          <TopUpStepWidget
            onContinue={goToNextStep}
            onError={handleError}
            articlePrice={articlePrice}
          />
        ) : null}
        {step.wrapperProps.stepName === REGISTRATION_STEPS_NAMES.SUCCESS ? (
          <SuccessStepWithTimeout onContinue={() => onFinish(step.idx)} />
        ) : null}
        {step.wrapperProps.stepName === REGISTRATION_STEPS_NAMES.ERROR ? (
          <ErrorStep
            onRetry={retryAfterError}
            onCancelProcess={onFinishWithError}
          />
        ) : null}
      </SponsoredModal>
    </div>
  )
}

SSORegistration.propTypes = ssoRegistrationProptypes

export const SSOFullRegistration = (props) => (
  <SSORegistration initialStepData={{ idx: 0 }} {...props} />
)

export const SSOThirdPartyIdpRegistration = ({ userSub, ...restProps }) => (
  <SSORegistration
    initialStepData={{ idx: 2, data: { sub: userSub } }}
    {...restProps}
  />
)

export const SSOUserRegistration = (props) => (
  <SSORegistration
    askJustForUserRegistration
    initialStepData={{ idx: 0 }}
    {...props}
  />
)
