import { Box, Container, Typography, Link as MUILink } from '@mui/material'

import styles from '../PrivacyPolicy/privacyPolicy.styles'

const Bold = ({ children }) => (
  <Box component='strong' sx={{ display: 'inline', fontWeight: 'bold' }}>
    {children}
  </Box>
)
const Link = ({ children, url }) => (
  <MUILink href={url ?? children} target='_blank' rel='noopener'>
    {children}
  </MUILink>
)
const MailTo = ({ children }) => <a href={`mailto:${children}`}>{children}</a>

const TermsPolicy = () => {
  window.location = 'https://www.bepayper.com/terminos-y-condiciones'

  return (
    <Box component='main'>
      <Container component='article' maxWidth='xl' sx={styles.container}>
        <Box component='header'>
          <Typography component='h1' variant='h3'>
            Términos y condiciones
          </Typography>
        </Box>
        <Box component='section'>
          <Typography component='h2' variant='h4'>
            Información previa
          </Typography>
          <Typography>
            Los presentes Términos y Condiciones (en adelante, los “
            <Bold>Términos y Condiciones</Bold>
            ”) regulan el acceso, navegación y uso de la plataforma{' '}
            <Link url='https://www.bepayper.com/'>www.bepayper.com</Link> (en
            adelante, la “<Bold>Plataforma</Bold>
            ”), con teléfono +34 699493307 y correo electrónico{' '}
            <MailTo>info@bepayper.com</MailTo>, el cual es titularidad de{' '}
            <Bold>MARTER 878, S.L.</Bold>, con domicilio social en Paseo Doctor
            Marañón, nº 7-1º D, 20009, San Sebastián (Guipúzcoa) y provista de
            C.I.F. B09762998 (en adelante, “<Bold>PAYPER</Bold>”).
          </Typography>
          <Typography>
            Los presentes Términos y Condiciones están dirigidos exclusivamente
            a aquellas personas (en adelante, “<Bold>Usuario</Bold>” o “
            <Bold>Usuarios</Bold>”) que se registren en la Plataforma como
            Usuarios para el uso personal del servicio de PAYPER aquí definido
            (en adelante, el “<Bold>Servicio</Bold>”).
          </Typography>
          <Typography>
            El Servicio consiste en ofrecer al Usuario la posibilidad de acceder
            a contenidos premium de su interés en medios de comunicación de
            manera rápida y sencilla.
          </Typography>
        </Box>
        <Box component='section'>
          <Typography component='h2' variant='h4'>
            Aceptación de los términos y condiciones y acceso a la plataforma
          </Typography>
          <Typography>
            Al utilizar los servicios de la Plataforma, el Usuario acepta los
            presentes Términos y Condiciones, los cuales definen los derechos y
            obligaciones de PAYPER y el Usuario a propósito de la relación
            contractual que se deriva de la utilización de los Servicios
            accesibles a través de la Plataforma. Estos son los únicos Términos
            y Condiciones aplicables al uso de la Plataforma y al acceso a
            contenidos a través de la Plataforma, y sustituyen cualesquiera
            otras condiciones.
          </Typography>
        </Box>
        <Box component='section'>
          <Typography component='h2' variant='h4'>
            Objeto y descripción del servicio
          </Typography>
          <Typography>
            La presente Plataforma ha sido desarrollada por PAYPER con la
            finalidad de vender bonos de acceso a contenidos periodísticos
            premium a los Usuarios, y que podrá ascender a la cantidad que el
            Usuario quiera (por ejemplo, bonos de 1, 5, 10 euros, etc.). En
            relación con lo anterior, los Usuarios podrán utilizar el saldo de
            su bono para la compra de noticias sin necesidad de suscripción al
            medio de comunicación en cuestión, sino mediante el pago de una
            cantidad unitaria por el acceso a cada noticia y que será reflejada
            en el momento del desbloqueo para la confirmación del Usuario.
          </Typography>
        </Box>
        <Box component='section'>
          <Typography component='h2' variant='h4'>
            Uso de la plataforma
          </Typography>
          <Typography>
            Los Usuarios se comprometen a hacer un uso lícito, diligente,
            honesto y correcto de cuanta información o contenidos tengan acceso
            a través de la Plataforma y todo ello bajo los principios de la
            buena fe y respetando en todo momento la legalidad vigente y los
            presentes Términos y Condiciones.
          </Typography>
          <Typography>
            Asimismo, para poder proceder al pago de los Servicios y
            materializar la compra de los mismos, los Usuarios deben ser mayores
            de 18 años de edad y/o tener capacidad legal suficiente para
            suscribir el presente contrato con PAYPER, así como aportar la
            información requerida en cada momento, lo que garantizan a PAYPER.
          </Typography>
          <Typography>
            PAYPER se reserva el derecho a dar de baja a aquellos Usuarios que
            estén haciendo un mal uso de su cuenta, realizando actos
            fraudulentos o que perjudiquen o puedan perjudicar a PAYPER.
          </Typography>
          <Typography>
            En caso de que PAYPER detecte cualquier anomalía o existan motivos
            fundados de que un Usuario esté llevando a cabo cualesquiera actos
            fraudulentos que contravengan la buena fe y los presentes Términos y
            Condiciones, PAYPER se reserva el derecho de dar de baja a dicho
            Usuario, incluso a todos aquellos Usuarios que se hubieran
            beneficiado de forma directa o indirecta de este tipo de
            actuaciones, pudiendo además ejercer todas las acciones legales que
            pudieran corresponder.
          </Typography>
          <Typography>
            En este sentido, PAYPER declara que ha habilitado los mecanismos
            idóneos para detectar cualquier posible actuación fraudulenta,
            anómala o dolosa que pretenda alterar las condiciones generales del
            uso de la cuenta por parte de los Usuarios.
          </Typography>
          <Typography>
            PAYPER se reserva el derecho a no conceder una cuenta cuando tenga
            motivos razonables y fundados para creer que un Usuario ha
            infringido los presentes Términos y Condiciones.
          </Typography>
        </Box>
        <Box component='section'>
          <Typography component='h2' variant='h4'>
            Registro en la plataforma de PAYPER y proceso de compra
          </Typography>
          <Typography>
            Para la realización de una compra, los Usuarios deberán registrarse
            en su Plataforma, completando un formulario de registro en el que le
            será solicitados los datos o información relativa a:
          </Typography>
          <Box component='ol'>
            <Box component='li'>
              Datos de identificación: nombre y apellidos del usuario.
            </Box>
            <Box component='li'>Datos de contacto: email y teléfono.</Box>
          </Box>
          <Typography>
            En este sentido, es importante de cara a la correcta prestación del
            servicio que los Usuarios indiquen sus datos de manera exacta,
            verídica, actual y completa, tal y como se solicita en la sección
            del formulario de registro de la Plataforma.
          </Typography>
          <Typography>
            Una vez los Usuarios se hayan registrado en la Plataforma de PAYPER,
            estos podrán comprar bonos para el consumo de noticias de los sitios
            web de los medios periodísticos que correspondan, para poder acceder
            a contenidos premium que resulten de su interés.
          </Typography>
        </Box>
        <Box component='section'>
          <Typography component='h2' variant='h4'>
            Precio y forma de pago
          </Typography>
          <Typography>
            El precio y forma de pago por el acceso a los Servicios por el
            Usuario será el descrito en los presentes Términos y Condiciones y/o
            en la Plataforma, que deberá ser aceptado de forma expresa por el
            Usuario.
          </Typography>
          <Typography>
            Todos los precios de los Servicios se expresan en euros e incluyen
            el IVA y los demás impuestos que pudieran corresponder.
          </Typography>
          <Typography>
            En cuanto a la forma de pago, el Usuario podrá pagar el importe
            correspondiente a su compra mediante los medios de pago aceptados a
            través de la propia Plataforma de PAYPER.
          </Typography>
          <Typography>
            PAYPER enviará siempre un mensaje al Usuario, al perfil de la cuenta
            que tenga dada de alta en PAYPER, confirmando los detalles de la
            compra realizada y la factura o justificante correspondiente una vez
            se hayan adquirido o comprado los bonos, es decir, tras la
            aceptación de los presentes Términos y Condiciones, la realización
            del pago que corresponda y el acceso del Usuario de la Plataforma.
          </Typography>
          <Typography>
            En este sentido, se informa a los Usuarios de que PAYPER el proceso
            de pago se realiza con absoluta seguridad, de acuerdo con los
            protocolos y servicios de seguridad exigidos, introduciendo los
            datos de pago en una página segura.
          </Typography>
          <Typography>
            El Usuario deberá notificar a PAYPER cualquier cargo indebido o
            fraudulento en la tarjeta utilizada para las compras, a través del
            envío de un correo electrónico a la dirección de correo electrónico{' '}
            <MailTo>info@bepayper.com</MailTo> en el menor plazo de tiempo
            posible para que PAYPER pueda realizar las gestiones oportunas en
            relación con el asunto concreto.
          </Typography>
          <Typography>
            El pago de la compra se hará efectivo una vez el Usuario haya
            comprado los bonos escogidos, aceptando de esta forma expresamente
            el Usuario los presentes Términos y Condiciones de PAYPER.
          </Typography>
          <Typography>
            El pago con tarjeta a través de la Plataforma está asegurado por el
            proveedor de pagos REDSYS, de acuerdo con las medidas de seguridad
            interbancarias españolas e internacionales.
          </Typography>
        </Box>
        <Box component='section'>
          <Typography component='h2' variant='h4'>
            Información sobre los servicios de PAYPER
          </Typography>
          <Typography>
            Los datos incluidos en la Plataforma y en las descripciones de los
            Servicios ofrecidos en medios de comunicación tienen una función
            meramente informativa.
          </Typography>
          <Typography>
            Por tanto, PAYPER declina cualquier responsabilidad por la aparición
            de errores en dicha información, si bien se compromete a tomar todas
            las medidas a su alcance para corregir en la mayor brevedad posible
            los referidos errores u omisiones tras haber sido informada de los
            mismos.
          </Typography>
        </Box>
        <Box component='section'>
          <Typography component='h2' variant='h4'>
            Duración de la relación entre PAYPER y el usuario
          </Typography>
          <Typography>
            Los Usuarios podrán acceder a los Servicios siempre y cuando estén
            registrados y dados de alta como Usuarios en la Plataforma de
            PAYPER.
          </Typography>
          <Typography>
            Los Usuarios podrán darse de baja de los servicios prestados por
            PAYPER con un preaviso mínimo de un mes. Sin perjuicio de lo
            anterior, las obligaciones, derechos y responsabilidades asumidas
            durante la vigencia de la relación entre PAYPER y los Usuarios se
            mantendrán vigentes una vez ocurrida la baja solicitada por los
            usuarios, y hasta la total consecución de las mismas.
          </Typography>
        </Box>
        <Box component='section'>
          <Typography component='h2' variant='h4'>
            Propiedad intelectual
          </Typography>
          <Typography>
            Todos los elementos que estén integrados, o puedan estar integrados
            en el futuro, en cualquier lugar de la Plataforma, así como, con
            carácter meramente enunciativo, pero no limitativo, derechos de
            autor, marcas patentes, diseños, gráficos, textos, iconos, nombres
            de dominio, logos, nombres comerciales, secreto comercial, código
            fuente y/u objeto o cualquier otro signo o elemento similar, son de
            titularidad exclusiva de PAYPER, o PAYPER tiene las correspondientes
            autorizaciones para su explotación y fijación en la Plataforma, y
            conserva todos los derechos de propiedad intelectual, industrial o
            de cualquier otro tipo sobre dichos elementos, los cuales no podrán
            ser objeto de modificación ulterior, copia, alteración, reproducción
            o adaptación por el Usuario.
          </Typography>
        </Box>
        <Box component='section'>
          <Typography component='h2' variant='h4'>
            Obligaciones y responsabilidades del usuario
          </Typography>
          <Typography>
            El Usuario podrá únicamente utilizar la Plataforma conforme a los
            fines previstos dentro de los presentes Términos y Condiciones.
          </Typography>
          <Typography>
            El Usuario se compromete a utilizar la Plataforma conforme a las
            leyes y reglamentos en vigor, así como al orden público y a las
            buenas costumbres. A este respecto, el Usuario se compromete
            fundamentalmente a respetar la reglamentación relativa al derecho de
            la propiedad intelectual y a la protección de datos de carácter
            personal. El Usuario garantiza fundamentalmente que su utilización
            de la Plataforma: (i) no viola las disposiciones de un Contrato
            previamente cerrado; (ii) no atenta a los derechos de propiedad
            intelectual de terceros ni contra la protección de menores y la
            dignidad humana; (iii) es conforme a las reglamentaciones
            específicas de su actividad (iv) respeta las exigencias de las leyes
            y los reglamentos aplicables; (v) no contiene ninguna información o
            declaración inexacta, falsa o de naturaleza alguna que pueda inducir
            a error; (vi) no contiene ninguna información o dato de contenido
            considerado ilícito, ofensivo, calumnioso, injurioso, difamatorio,
            racista, xenófobo, obsceno, insultante, amenazador o discriminatorio
            (vii) no es susceptible de comprometer la responsabilidad de PAYPER
            o de dañar su imagen.
          </Typography>
          <Typography>
            PAYPER no será responsable de ningún dato, información, archivo,
            comentario o de cualquier otro aspecto publicado por los Usuarios,
            ni de cualquier uso incorrecto o fraudulento de la Plataforma. En
            concreto, se vigilará y supervisará que los Usuarios con acceso a la
            Plataforma en relación con los Términos y Condiciones no infrinja
            ninguna normativa ni tenga, con carácter meramente enunciativo, pero
            no limitativo, carácter vejatorio ni lesione ningún derecho de
            ningún tercero, manteniendo por ello indemne a PAYPER frente a
            cualquier práctica relacionada con ello. El Usuario se compromete a
            notificar a PAYPER cualquier situación similar de la que tenga
            conocimiento a la mayor brevedad, sin que ello implique en ningún
            caso responsabilidad alguna por parte de PAYPER. PAYPER se reserva
            el derecho de interrumpir inmediatamente, sin previo aviso ni
            indemnización, el acceso del Usuario a la Plataforma en caso de uso
            no conforme de la misma con los presentes Términos y Condiciones y
            con cualquier otro documento legal que vincule a PAYPER y a los
            Usuarios, y más generalmente en caso de incumplimiento de las
            limitaciones y/o prohibiciones estipuladas.
          </Typography>
        </Box>
        <Box component='section'>
          <Typography component='h2' variant='h4'>
            Protección de datos personales
          </Typography>
          <Typography>
            Las partes manifiestan que conocen y cumplen con la legislación
            española y europea en materia de Protección de Datos de Carácter
            Personal, y, en concreto, con el RGPD, LOPGDD, y LSSI.
          </Typography>
          <Typography>
            PAYPER cumplirá con la normativa europea y española correspondiente
            y, en concreto, el Reglamento (UE) 2016/679 del Parlamento Europeo y
            del Consejo de 27 de abril de 2016 relativo a la protección de las
            personas físicas en lo que respecta al tratamiento de datos
            personales y a la libre circulación de estos datos y por el que se
            deroga la Directiva 95/46/CE (en adelante, “<Bold>RGPD</Bold>”) con
            la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos
            Personales Y garantía de los derechos digitales (en adelante, “
            <Bold>LOPDGDD</Bold>”) y la Ley 34/2002 de 11 de julio, de Servicios
            de la Sociedad de la Información y de Comercio Electrónico (en
            adelante, “<Bold>LSSI</Bold>”), y almacenará los datos personales
            del Usuario en una base de datos titularidad de PAYPER.
          </Typography>
        </Box>
        <Box component='section'>
          <Typography component='h2' variant='h4'>
            Derecho de desistimiento
          </Typography>
          <Typography>
            El contenido digital facilitado por PAYPER al Usuario será accesible
            tras el pago de las cantidades debidas para el registro del Usuario.
            Si no se procede a dicho pago, los contenidos digitales no serán
            facilitados al Usuario por PAYPER.
          </Typography>
          <Typography>
            Mediante la aceptación de los presentes Términos y Condiciones y el
            pago indicado en los mismos, y en virtud del artículo 103 m) del
            Real Decreto Legislativo 1/2007, de 16 de noviembre, por el que se
            aprueba el texto refundido de la Ley General para la Defensa de los
            Consumidores y Usuarios y otras leyes complementarias (en adelante,
            “<Bold>LGDCU</Bold>”):
          </Typography>
          <Box component='ul'>
            <Box component='li'>
              El Usuario otorga su consentimiento previo para el inicio de la
              ejecución de los servicios por PAYPER en los 14 días naturales
              siguientes a la formalización del registro del Usuario.
            </Box>
            <Box component='li'>
              El Usuario manifiesta, de forma clara y expresa, su conocimiento
              de que, a partir de lo anterior, y especialmente de la
              facilitación por parte de PAYPER de cualesquiera contenidos
              ofrecidos por los medios de comunicación, el Usuario pierde su
              derecho de desistimiento, no siendo posible reembolso alguno a
              partir de ese momento.
            </Box>
          </Box>
          <Typography>
            En base a todo lo anterior, PAYPER informa al Usuario, que consiente
            expresamente, que el Usuario no podrá ejercitar este derecho de
            desistimiento cuando PAYPER le facilite cualquiera de los Servicios
            que conforman la Plataforma.
          </Typography>
        </Box>
        <Box component='section'>
          <Typography component='h2' variant='h4'>
            Ley aplicable y sometimiento a fuero
          </Typography>
          <Typography>
            Los presentes Términos y Condiciones se regirán por la legislación
            española. En caso de litigio sobre la interpretación, ejecución o
            validez de estos Términos y Condiciones, serán competentes los
            Juzgados y Tribunales del territorio de residencia del Usuario.
          </Typography>
          <Typography>
            En virtud de lo dispuesto en el Reglamento (UE) nº 524/2013 del
            Parlamento Europeo y del Consejo, de 21 de mayo de 2013, relativo a
            la resolución de litigios en línea en materia de consumo, PAYPER
            también informa al Usuario de que, en caso de controversia, el
            Usuario residente de la Unión Europea podrá acudir a la “Plataforma
            Online de Resolución de Conflictos” que ha desarrollado la Comisión
            Europea, con el fin de intentar resolver extrajudicialmente
            cualquier controversia que se derive de la prestación de servicios
            por parte de PAYPER.
          </Typography>
          <Typography>
            Para a acceder a la “Plataforma Online de Resolución de Conflictos”
            puede hacerlo a través del siguiente enlace:{' '}
            <Link>http://ec.europa.eu/consumers/odr/</Link>.
          </Typography>
        </Box>
      </Container>
    </Box>
  )
}
export default TermsPolicy
