const styles = {
  root: {
    '--root-bgColor': (theme) => theme.palette.neutral[300],
    '--link-color': (theme) => theme.palette.neutral[600],
    display: 'flex',
    justifyContent: 'center',
    py: 5,
    backgroundColor: 'var(--root-bgColor, transparent)',

    '& .isVisible': {
      opacity: 1,
      transform: 'none',
    },
  },
  wrapMessage: {
    position: {
      lg: 'relative',
    },
  },
  message: {
    width: (theme) => `min(100%, ${theme.spacing(31)})`,
    mx: {
      xs: 'auto',
      xl: '7vw',
      xxl: '12%',
    },
    my: {
      xl: 12,
    },
  },
  messageHeader: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 1.25,
    mb: 3.5,
  },
  messageTitle: {
    color: 'neutral.700',
  },
  messageText: {
    width: (theme) => `min(100%, ${theme.spacing(25)})`,
    color: 'neutral.600',
  },
  messageWrapPicture: {
    width: (theme) => ({
      xs: `min(100%, ${theme.spacing(16)})`,
      md: `min(100%, ${theme.spacing(21)})`,
      xl: 'auto',
    }),
    mt: {
      xs: 3.5,
      xl: 0,
    },
    mx: 'auto',
    mb: {
      xs: 1.25,
      md: -1,
      xl: 0,
    },
    position: {
      xl: 'absolute',
    },
    top: {
      xl: '50%',
    },
    left: {
      xl: '50vw',
      xxl: 'initial',
    },
    right: {
      xxl: '9%',
    },
    transform: {
      xl: 'translateY(-50%)',
    },
  },
  messageWrapActions: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 1,
    justifyContent: {
      lg: 'end',
    },
    columnGap: (theme) => theme.spacing(0.75),
    width: (theme) => ({
      xs: `min(100%, ${theme.spacing(24)})`,
      md: `min(75%, ${theme.spacing(28)})`,
      xl: `min(100%, ${theme.spacing(28)})`,
    }),
    mr: 'auto',
    ml: {
      xs: 'auto',
      xl: 0,
    },
  },
}

export default styles
