import { Box } from '@mui/material'

import EVENTS_NAMES from '../../../../analytics/eventsNames.json'
import { ChromeExtBanner, AssociatedMedia } from '../../../components'
import { openMediaLink } from '../../../utils'
import { ASSOCIATED_MEDIAS_DATA } from '../../../constants'
import Sections from './Sections'
import styles from './dashboard.styles'

const Dashboard = () => {
  const openMediaPage = (mediaData) => {
    document.dispatchEvent(
      new CustomEvent('payperAnalyticsPrivateEvent', {
        detail: {
          name: EVENTS_NAMES.PD_CLICK_MEDIA,
          mediaId: mediaData.id,
        },
      }),
    )

    openMediaLink(mediaData.domain)
  }

  return (
    <Box sx={styles.root}>
      <ChromeExtBanner />
      <AssociatedMedia
        medias={ASSOCIATED_MEDIAS_DATA}
        onClickMedia={openMediaPage}
        isPublicVersion
      />
      <Sections />
    </Box>
  )
}

export default Dashboard
