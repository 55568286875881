import { login } from '../session'
import { useTexts } from '../../texts'
import { useForm, composeValidators, validators } from '../../forms'
import { InvalidUserError } from '../user'
import { InvalidCredentialsError } from './errors'

const useLoginForm = (formId) => {
  const texts = useTexts()
  const { isValidEmail, isFilled } = validators

  const FORM_VALIDATION_CONFIG = {
    user: composeValidators([
      [isFilled, texts.getErrorRequired()],
      [isValidEmail, texts.getErrorEmail()],
    ]),
    password: composeValidators([[isFilled, texts.getErrorRequired()]]),
  }

  const submitLoginData = async (formValues) => {
    try {
      await login(formValues.user, formValues.password)
    } catch (error) {
      if (error instanceof InvalidCredentialsError) {
        throw new Error(texts.getLoginInvalidCredentialsError())
      }
      if (error instanceof InvalidUserError) {
        throw new Error(texts.getLoginInvalidUserError())
      }
      throw new Error(texts.getUnexpectedError())
    }
  }

  return useForm(formId, submitLoginData, FORM_VALIDATION_CONFIG)
}

export default useLoginForm
