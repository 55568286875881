import { useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog } from '@mui/material'

import SocialLoginButtons from '../SocialLoginButtons'
import { Modal } from '../../../components/Modal'
import {
  UserStep,
  ConfirmationCodeStep,
} from '../../../components/Registration/Steps'
import styles from './registration.styles'

const STEP_MODAL_PROPS = [
  {
    stepName: 'register-user-data',
    caption: (texts) => texts.getRegisterTitleLabel(),
    title: (texts) => texts.getRegisterSubtitleLabel(),
    background: 'solid',
    sx: styles.successStep,
  },
  {
    stepName: 'register-confirm-user-email',
    caption: (texts) => texts.getRegisterCodeVerificationLabel(),
    title: (texts) => texts.getRegisterCodeVerificationSubtitleLabel(),
    description: (texts) => texts.getRegisterCodeVerificationTextLabel(),
    background: 'solid',
    sx: styles.confirmationCodeStep,
  },
]

const registrationFlowProptypes = {
  attribution: PropTypes.object,
  onCancel: PropTypes.func,
  onGoToLogin: PropTypes.func,
  onFinish: PropTypes.func,
}

const INITIAL_STEP_INDEX = 0

const RegistrationFlow = ({ attribution, onCancel, onGoToLogin, onFinish }) => {
  const initialStep = {
    idx: INITIAL_STEP_INDEX,
    modalProps: STEP_MODAL_PROPS[INITIAL_STEP_INDEX],
  }
  const [step, setStep] = useState(initialStep)

  const goToNextStep = (data = {}) =>
    setStep((step) => ({
      idx: step.idx + 1,
      data: { ...step.data, ...data },
      modalProps: STEP_MODAL_PROPS[step.idx + 1],
    }))

  const handleCloseButton = () => {
    document.dispatchEvent(
      new CustomEvent('leaveRegister', { detail: step.modalProps.stepName }),
    )

    onCancel()
  }

  return (
    <Dialog aria-labelledby='registerButton' open>
      <div aria-label='crea-tu-cuenta-payper'>
        <Modal onCancel={handleCloseButton} {...step.modalProps}>
          {step.idx === 0 ? (
            <UserStep
              attribution={attribution}
              onContinue={goToNextStep}
              onGoToLogin={onGoToLogin}
              renderSocialLogin={() => <SocialLoginButtons />}
            />
          ) : step.idx === 1 ? (
            <ConfirmationCodeStep
              onContinue={() => onFinish()}
              {...step.data}
            />
          ) : null}
        </Modal>
      </div>
    </Dialog>
  )
}

RegistrationFlow.propTypes = registrationFlowProptypes

export default RegistrationFlow
