const styles = {
  root: {},
  container: {
    counterReset: 'heading',
    width: (theme) => `min(100%, ${theme.spacing(61)})`,
    py: {
      xs: 3,
      md: 4.5,
    },
    color: 'neutral.700',

    '& h1': {
      mb: {
        xs: 2.5,
        md: 4.5,
      },
    },
    '& h2': {
      mb: {
        xs: 1.5,
        md: 2.5,
      },
      mt: {
        xs: 2,
        md: 3.5,
      },

      '&::before': {
        counterIncrement: 'heading',
        content: 'counter(heading) "."',
        display: 'inline-flex',
        mr: 0.25,
      },
    },
    '& ul, ol': {
      px: {
        xs: '5%',
        md: '12.5%',
      },
      mb: {
        xs: 1.5,
        md: 2.5,
      },

      '& li': {
        '&:not(:last-child)': {
          mb: {
            xs: 1,
            md: 2,
          },
        },
      },
    },
    '& p': {
      mb: {
        xs: 1,
        md: 2,
      },
    },
  },
}

export default styles
