import baseTheme from '../base'

const MuiLink = {
  styleOverrides: {
    root: {
      color: 'inherit',
      textDecorationColor: 'currentColor',
      fontWeight: 500,

      '&:hover': {
        color: baseTheme.palette.neutral[400],
        cursor: 'pointer',
      },
      '&:focus-visible': {
        outline: `max(2px, 0.08em) solid ${baseTheme.palette.primary.main}`,
      },
    },
  },
}

export default MuiLink
