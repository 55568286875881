const styles = {
  root0: {
    '--bg-position': '-200px',
  },
  root1: {
    '--bg-position': '-104px',
  },
  root2: {
    '--bg-position': '-66px',
  },
  root: {
    display: 'inline-flex',
    columnGap: 1,
    p: 0,
    mt: 0,
    mx: 0,
    mb: 0.75,
    listStyleType: 'none',
    position: 'relative',
    overflow: 'hidden',
    alignSelf: 'flex-start',

    '&::before': {
      content: '""',
      display: 'block',
      width: '300%',
      height: (theme) => theme.spacing(0.125),
      background:
        'linear-gradient(90deg, rgba(140,123,242,1) 42%, rgba(255,204,0,1) 50%, rgba(239,239,237,1) 62%)',
      backgroundPosition: 'var(--bg-position)',
      position: 'absolute',
      left: 0,
      right: 0,
      top: '50%',
      zIndex: 0,
      transform: 'translateY(-50%)',
      transition: 'all 400ms',
    },
  },
  step: {
    '--scale': 'scale(1)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: (theme) => theme.spacing(1.5),
    height: (theme) => theme.spacing(1.5),
    fontSize: (theme) => theme.spacing(0.75),
    fontWeight: 500,
    lineHeight: 1.5,
    borderRadius: '50%',
    color: 'neutral.400',
    backgroundColor: 'neutral.300',
    position: 'relative',
    transition: 'all 300ms',

    '& > span': {
      transition: 'all 100ms',
      transform: 'var(--scale)',
    },
  },
  active: {
    '--scale': 'scale(1.15)',
    color: 'neutral.700',
    backgroundColor: 'primary.main',
  },
  previous: {
    color: 'neutral.100',
    backgroundColor: 'info.main',
  },
}

export default styles
