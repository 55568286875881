import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Session } from '../../../../business'
import { encode } from '../../../format'
import { CircularProgress } from '../../../components'
import useSearchParams, { STATE_PARAMS, ACCESS_PARAMS } from './useSearchParams'
import PaymentRegistrationAndTopUpFlow from './PaymentRegistrationAndTopUpFlow'

const WidgetPurchaseWithBankCardRegistration = () => {
  const navigate = useNavigate()
  const [isValidatingData, setIsValidatingData] = useState(true)
  const { state, isValidState, mediaData, accessData, isValidAccess } =
    useSearchParams()

  const handleRedirectOnSuccess = () =>
    (window.location = state[STATE_PARAMS.SUCCESS_REDIRECT_URI])

  const handleRedirectOnError = () =>
    (window.location = state[STATE_PARAMS.ERROR_REDIRECT_URI])

  const handleLoadError = (errorMessage) => {
    alert(errorMessage)

    if (Boolean(state?.[STATE_PARAMS.ERROR_REDIRECT_URI])) {
      handleRedirectOnError()
    } else {
      navigate('/')
    }
  }

  useEffect(() => {
    ;(async () => {
      if (!isValidState) {
        handleLoadError('url inválida')
      }

      let authTokens
      if (isValidAccess) {
        authTokens = await Session.refreshSessionFromExternalData(
          accessData[ACCESS_PARAMS.EMAIL],
          accessData[ACCESS_PARAMS.REFRESH_TOKEN],
        )
      } else if (!Session.hasValidAccessToken()) {
        authTokens = await Session.refreshSession()
      } else if (!Session.isAuthenticated()) {
        authTokens = null
      }

      if (!Boolean(authTokens)) {
        const article = encode({
          id: mediaData.articleId,
          headline: mediaData.articleHeadline,
          url: mediaData.articleUrl,
        })
        navigate(
          `/access?media_id=${mediaData.mediaProviderId}&article=${article}&redirect_uri=${state.error_redirect_uri}`,
        )
      }

      setIsValidatingData(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isValidatingData) return <CircularProgress height='80vh' />

  return (
    <PaymentRegistrationAndTopUpFlow
      onFinishWithSuccess={handleRedirectOnSuccess}
      onFinishWithError={handleRedirectOnError}
      articlePrice={state[STATE_PARAMS.ARTICLE_PRICE]}
      userSub={Session.getUserSub()}
    />
  )
}

export default WidgetPurchaseWithBankCardRegistration
