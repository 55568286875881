import { Box } from '@mui/material'
import PropTypes from 'prop-types'

import styles from './stepper.styles'

const consecutiveNumbersArrayOfLength = (arrayLength) =>
  Array.from({ length: arrayLength }, (_, index) => index + 1)

const stepperPropTypes = {
  currentStepIndex: PropTypes.number,
  stepsLength: PropTypes.number,
}

const Stepper = ({ currentStepIndex, stepsLength }) => {
  const steps = consecutiveNumbersArrayOfLength(stepsLength)
  return (
    <Box
      component='ol'
      sx={{
        ...styles.root,
        ...styles[`root${currentStepIndex}`],
      }}
    >
      {steps.map((step, idx) => (
        <Box
          key={step}
          component='li'
          sx={{
            ...styles.step,
            ...(currentStepIndex === idx ? styles.active : null),
            ...(currentStepIndex > idx ? styles.previous : null),
          }}
        >
          <span>{step}</span>
        </Box>
      ))}
    </Box>
  )
}

Stepper.propTypes = stepperPropTypes

export default Stepper
