import theme from '../../../../../theme'

const styles = {
  description: {
    ...theme.typography.body2,
    color: 'neutral.500',
  },
  wrapPicture: {
    position: 'relative',
  },
  pictureText: {
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: theme.spacing(9.375),
    height: theme.spacing(6.875),
    fontSize: (theme) => ({
      xs: theme.spacing(1.25),
      md: theme.spacing(1.25),
    }),
    fontWeight: 600,
    color: 'neutral.700',
    textShadow: '0.5px 0.5px 1px rgba(0, 0, 0, 0.08)',
    position: 'absolute',
    top: (theme) => `min(${theme.spacing(3.75)}, 24%)`,
    left: '50%',
    zIndex: 1,
    transform: {
      xs: 'translateX(-50%)',
      sm: 'translateX(-62.5%)',
    },

    '& > span': {
      display: 'block',
      fontSize: theme.spacing(0.625),
      fontWeight: 500,
      letterSpacing: '0.02em',
      textShadow: '0.5px 0.5px 1px rgba(255, 255, 255, 0.08)',
      color: 'neutral.500',
    },
  },
  action: {
    width: (theme) => `min(100%, ${theme.spacing(22.5)})`,
    mx: 'auto',

    '& .MuiButton-root': {
      width: '100%',
    },
  },
}

export default styles
