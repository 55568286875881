import { useEffect } from 'react'
import { Box, Button, Dialog, Typography, IconButton } from '@mui/material'

import EVENTS_NAMES from '../../../analytics/eventsNames.json'
import { X } from '../../../styleguide/icons'
import { useTexts } from '../../../texts'
import { openExternalLink, browser } from '../../utils'
import { chromeExtension } from '../../../thirdPartyIntegration'
import styles from './chromeExtDialog.styles'

const { CE_AVAILABILITY, DOWNLOAD_CHROME_URL, PAYPER_CWS_URL } = chromeExtension

const ChromeExtDialog = ({ isOpen, onClose, ceAvailability }) => {
  const texts = useTexts()

  const DIALOG_CONFIG = {
    [CE_AVAILABILITY.NOT_DESKTOP]: {
      title: texts.getCEDialogNotDesktopTitle(),
      description: [
        texts.getCEDialogNotDesktopText1(),
        texts.getCEDialogNotDesktopText2(),
        texts.getCEDialogNotDesktopText3(),
      ],
      action: {
        label: texts.getCEDialogNotDesktopAction(),
        onClick: onClose,
      },
    },
    [CE_AVAILABILITY.DESKTOP_NOT_AVAILABLE]: {
      title: texts.getCEBannerDesktopNotAvailableText1(),
      description: [texts.getCEBannerDesktopNotAvailableText2()],
      action: {
        label: texts.getCEBannerDesktopNotAvailableAction(),
        onClick: () => {
          openExternalLink(DOWNLOAD_CHROME_URL)
          document.dispatchEvent(
            new CustomEvent('payperAnalyticsPrivateEvent', {
              detail: {
                name: EVENTS_NAMES.PD_CHROME_EXT_INSTALL_DIALOG,
                eventType: 'click',
                dialogType: CE_AVAILABILITY.DESKTOP_NOT_AVAILABLE,
                browser: browser.name,
              },
            }),
          )
          onClose()
        },
      },
    },
    [CE_AVAILABILITY.DESKTOP_AVAILABLE]: {
      title: texts.getCEDialogDesktopAvailableTitle(),
      description: [texts.getCEBannerDesktopAvailableText()],
      action: {
        label: texts.getCEBannerDesktopAvailableAction(),
        onClick: () => {
          openExternalLink(PAYPER_CWS_URL)
          document.dispatchEvent(
            new CustomEvent('payperAnalyticsPrivateEvent', {
              detail: {
                name: EVENTS_NAMES.PD_CHROME_EXT_INSTALL_DIALOG,
                eventType: 'click',
                dialogType: CE_AVAILABILITY.DESKTOP_AVAILABLE,
                browser: browser.name,
              },
            }),
          )
          onClose()
        },
      },
    },
  }

  useEffect(() => {
    if (isOpen) {
      document.dispatchEvent(
        new CustomEvent('payperAnalyticsPrivateEvent', {
          detail: {
            name: EVENTS_NAMES.PD_CHROME_EXT_INSTALL_DIALOG,
            eventType: 'visibility',
            dialogType: ceAvailability,
            browser: browser.name,
          },
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  if (!Boolean(ceAvailability) || !Boolean(DIALOG_CONFIG[ceAvailability])) {
    return null
  }

  const { title, description, action } = DIALOG_CONFIG[ceAvailability]
  return (
    <Dialog
      id='ce-installation-dialog'
      open={isOpen}
      onClose={onClose}
      fullWidth={false}
      sx={styles.root}
    >
      <IconButton
        aria-label={texts.getCloseAction()}
        onClick={onClose}
        sx={styles.closeButton}
      >
        <X />
      </IconButton>
      <Box sx={styles.content}>
        <Typography sx={styles.title}>{title}</Typography>
        {description.map((text, i) => (
          <Typography
            key={i}
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
        ))}
        <Box sx={styles.centered}>
          <Button color='primary' onClick={action.onClick}>
            {action.label}
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}

export default ChromeExtDialog
