import articleCardStyles from '../../../components/ArticleCard/articleCard.styles'

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    rowGap: {
      xs: 1,
      md: 1.5,
    },
    width: (theme) => `min(100%, ${theme.spacing(59.5)})`,
    mx: 'auto',
    pt: {
      xs: 2,
      md: 3,
    },
    pb: {
      xs: 4,
      lg: 6,
    },
  },
  title: {
    px: articleCardStyles.card.px,
  },
  button: {
    mx: articleCardStyles.card.px,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& button': {
      width: (theme) => `min(100%, ${theme.spacing(19.5)})`,
    },
  },
}

export default styles
