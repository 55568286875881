import { backofficeEndpoint } from '../../config'
import fetchWithAuthorization, {
  InvalidTokenError,
} from '../fetchWithAuthorization'
import * as Session from '../session'

export const fetchLayoutPublic = async () => {
  const response = await fetch(`${backofficeEndpoint}/dashboard-content/layout`)
  if (response.ok) {
    return await response.json()
  }
  return null
}

export const fetchLayoutPrivate = fetchWithAuthorization(
  async (authHeaders) => {
    const userSub = Session.getUserSub()
    const response = await fetch(
      `${backofficeEndpoint}/dashboard-content/layout/user/${userSub}`,
      {
        method: 'GET',
        headers: authHeaders,
      },
    )
    if (response.ok) {
      return await response.json()
    }
    if (!response.ok && response.status === 401) {
      throw new InvalidTokenError(response.statusText)
    }
  },
)

export const fetchArticlesBySection = async (sectionId, config) => {
  const userSub = Session.getUserSub()
  if (Boolean(userSub)) {
    config = { ...config, id: { ...config.id, userSub } }
  }

  const response = await fetch(
    `${backofficeEndpoint}/dashboard-content/articles/section/${sectionId}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(config),
    },
  )
  if (response.ok) {
    return await response.json()
  }
}
