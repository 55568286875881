const styles = {
  userStep: {
    rowGap: 2,
  },
  confirmationCodeStep: {
    justifyContent: 'flex-start',
    rowGap: 1,
    mt: -1.5,
  },
  bankCardStep: {
    justifyContent: 'flex-start',
    rowGap: 2,
    mt: -1.5,
  },
  topUpStep: {
    rowGap: 1,
  },
  successStep: {
    justifyContent: 'flex-start',
    rowGap: 2,
    mt: -1.5,
  },
}

export default styles
