import { useForm, composeValidators, validators } from '../../forms'
import { useTexts } from '../../texts'

import { sendEmail } from './service'

const useContactForm = (formId) => {
  const texts = useTexts()

  const FORM_VALIDATION_CONFIG = {
    message: composeValidators([
      [validators.isFilled, texts.getErrorRequired()],
    ]),
  }

  return useForm(formId, sendEmail, FORM_VALIDATION_CONFIG)
}

export default useContactForm
