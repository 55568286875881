import theme from '../../../theme'

const styles = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'justify',
    '& p': {
      mb: 0.5,
    },
    '& a': {
      color: theme.palette.neutral[700],
      fontWeight: 500,
      '&:hover': {
        color: theme.palette.neutral[400],
      },
    },
    '& button:hover': {
      backgroundColor: 'transparent',
    },
  },
  clampedText: {
    '& p:last-of-type': {
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 2,
      overflow: 'hidden',
    },
  },
}

export default styles
