import { Box } from '@mui/material'

import { useTexts } from '../../../texts'
import { Alert } from '../index'
import styles from './topUpSelectorCards.styles'

const DEFAULT_SELECTED_INDEX = 0

const TopUpSelectorCards = ({
  options,
  popularOption,
  selected,
  setSelected,
  sx,
}) => {
  const texts = useTexts()

  return (
    <Box sx={{ ...styles.topUp, ...sx }}>
      <Box component='fieldset' sx={styles.fieldset}>
        {options.map((amount, idx) => (
          <Box
            key={amount}
            sx={{
              ...styles.wrapOption,
              ...(selected === amount ? styles.optionSelected : null),
            }}
          >
            {amount === popularOption ? (
              <Box component='span' sx={styles.label}>
                {texts.getBalanceTopUpPopularLabel()}
              </Box>
            ) : null}
            <Box component='label' sx={styles.option}>
              <input
                type='radio'
                name='topUpAmount'
                value={amount}
                defaultChecked={idx === DEFAULT_SELECTED_INDEX ? true : false}
                onChange={() => setSelected(amount)}
              />
              <span>{amount}€</span>
            </Box>
          </Box>
        ))}
      </Box>

      <Alert
        title={texts.getBalanceTopUpAlertTitleLabel()}
        text={texts.getBalanceTopUpAlertTextLabel()}
      />
    </Box>
  )
}

export default TopUpSelectorCards
