import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { useTexts } from '../../../texts'
import { BalanceCard, BonusCard } from '../../components'
import styles from './account.styles'

const Description = () => {
  const texts = useTexts()

  return (
    <Box sx={styles.balanceDescription}>
      <Typography
        dangerouslySetInnerHTML={{
          __html: texts.balancePayperPolicy(),
        }}
      />
      <Typography
        dangerouslySetInnerHTML={{
          __html: texts.balancePayperBonusPolicy(),
        }}
      />
    </Box>
  )
}

const Balance = ({ balance, bonus, onClickTopUp }) => {
  const theme = useTheme()
  const widerThanTablet = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <>
      {!widerThanTablet && <Description />}
      {!balance.isLoading && (
        <BalanceCard
          withTopUp={!balance.isLoading && Boolean(balance.data)}
          balanceData={balance.data}
          onClickTopUp={onClickTopUp}
        />
      )}

      {!(bonus.isLoading || bonus.error || !Boolean(bonus.data)) && (
        <BonusCard bonusData={bonus.data} />
      )}
      {widerThanTablet && <Description />}
    </>
  )
}

export default Balance
