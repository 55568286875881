import EmailStep from './EmailStep'
import PasswordStep from './PasswordStep'
import UserDataStep from './UserDataStep'

const AccessView = ({
  registerEmail,
  setRegisterEmail,
  loginEmail,
  setLoginEmail,
  handleFinishAuth,
  paramsData,
  handleGoToForgottenPassword,
}) => (
  <>
    {Boolean(registerEmail) ? (
      <UserDataStep
        email={registerEmail}
        onChangeEmail={() => setRegisterEmail()}
        onContinue={(userData) =>
          handleFinishAuth({ userData, isNewUser: true })
        }
        attribution={paramsData?.attribution}
      />
    ) : Boolean(loginEmail) ? (
      <PasswordStep
        email={loginEmail}
        onGoToForgottenPassword={handleGoToForgottenPassword}
        onContinue={(userData) =>
          handleFinishAuth({ userData, isNewUser: false })
        }
      />
    ) : (
      <EmailStep
        onContinueLogin={setLoginEmail}
        onContinueRegister={setRegisterEmail}
      />
    )}
  </>
)

export default AccessView
