import { useTexts } from '../../../../../texts'
import { Feedback } from '../../../../components'
import ScrollToTop from '../../../useScrollToTop'
import imageAvif from './young-woman.avif'
import imageWebp from './young-woman.webp'
import imagePng from './young-woman.png'

const SuccessView = () => {
  const texts = useTexts()

  const viewProps = {
    title: texts.getUpdatePersonalDataSuccessTitleLabel(),
    content: [texts.getUpdatePersonalDataSuccessTextLabel()],
    button: {
      text: texts.getUpdatePersonalDataSuccessContinueAction(),
      to: '/dashboard',
    },
    picture: {
      width: 292,
      height: 210,
      source: { avif: imageAvif, webp: imageWebp, fallback: imagePng },
      styles: {
        marginTop: 'auto',
        marginBottom: '-3rem',
      },
    },
  }

  return (
    <ScrollToTop>
      <Feedback {...viewProps} />
    </ScrollToTop>
  )
}

export default SuccessView
