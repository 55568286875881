import PropTypes from 'prop-types'
import { Paper, Button, Box, Typography } from '@mui/material'

import { ReactComponent as WarningCircle } from '../../../styleguide/icons/WarningCircle.svg'
import styles from './banner.styles'

const bannerPropTypes = {
  content: PropTypes.arrayOf(PropTypes.string).isRequired,
  action: PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }),
}

const Banner = ({ content = [], action }) => {
  return (
    <Paper elevation={2} sx={styles.root}>
      <Box sx={styles.content}>
        <Box sx={styles.iconWrapper}>
          <WarningCircle />
        </Box>
        <Box sx={styles.contentTexts}>
          {content.map((text, i) => (
            <Typography key={i} variant='body2'>
              {text}
            </Typography>
          ))}
        </Box>
      </Box>
      {Boolean(action) && (
        <Button color='primary' onClick={action.onClick}>
          {action.label}
        </Button>
      )}
    </Paper>
  )
}

Banner.propTypes = bannerPropTypes

export default Banner
