import { DocumentCookies } from '../App/utils'

const EMBEDDED_IN_NATIVE_APP_COOKIE = {
  key: 'payper_in_app',
  value: '1',
}

export const isEmbeddedInNativeApp = () =>
  new DocumentCookies().get(EMBEDDED_IN_NATIVE_APP_COOKIE.key) ===
  EMBEDDED_IN_NATIVE_APP_COOKIE.value

export const handleFinishUnauth = (error = 'Something went wrong') => {
  if (window?.Android?.authorizationUnsuccessful) {
    window.Android.authorizationUnsuccessful(error)
  }

  if (window?.webkit?.messageHandlers?.authorizationHandler) {
    window.webkit.messageHandlers.authorizationHandler.postMessage(
      JSON.stringify({
        status: 'failure',
        error,
      }),
    )
  }
}

export const handleFinishAuth = (authData) => {
  if (window?.Android?.authorizationSuccessful) {
    window.Android.authorizationSuccessful(authData)
  }

  if (window?.webkit?.messageHandlers?.authorizationHandler) {
    window.webkit.messageHandlers.authorizationHandler.postMessage(
      JSON.stringify({
        status: 'success',
        data: authData,
      }),
    )
  }
}
