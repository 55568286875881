export const formatNumber = (figure) => {
  const format = (number) =>
    new Intl.NumberFormat('es-ES', {
      minimumFractionDigits: 2,
      signDisplay: 'never',
    }).format(number.toFixed(2))

  return typeof figure === 'number'
    ? format(figure)
    : typeof figure === 'string'
    ? format(Number(figure))
    : figure
}

export const formatPrice = (number) =>
  new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(
    number,
  )

const utf8_to_b64 = (str) => {
  return window.btoa(unescape(encodeURIComponent(str)))
}
export const encode = (value) => utf8_to_b64(JSON.stringify(value))

const b64_to_utf8 = (str) => {
  return decodeURIComponent(escape(window.atob(str)))
}
export const decode = (value) => JSON.parse(b64_to_utf8(value))

// returned format: dd/MM/yy
export const formatShortDate = (dateStr) =>
  new Date(dateStr).toLocaleString('es-ES', {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
  })

// returned format: dd/MM/yyyy
export const formatLongDate = (dateStr) =>
  new Date(dateStr).toLocaleString('es-ES', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  })

// returned format: 'dd/MM/yyyy HH:mm'
export const formatFullLongDate = (dateStr) =>
  new Date(dateStr)
    .toLocaleString('es-ES', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    })
    .replace(',', '')

// returned format: d mon (1 ene)
export const formatShortTextDate = (dateStr) =>
  new Date(dateStr).toLocaleString('es-ES', {
    month: 'short',
    day: 'numeric',
  })

const capitalize = (str) => str.charAt(0).toUpperCase().concat(str.slice(1))
// returned format: month year (Enero 2024)
export const formatMonthTextDate = (dateStr) => {
  const month = capitalize(
    new Date(dateStr).toLocaleString('es-ES', {
      month: 'long',
    }),
  )
  const year = new Date(dateStr).getFullYear()
  return `${month} ${year}`
}

export const formatHour = (dateStr) =>
  new Date(dateStr).toLocaleString('es-ES', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  })

// returned format: MM/yyyy
export const formatMonthDate = (dateStr) =>
  new Date(dateStr).toLocaleString('es-ES', {
    month: '2-digit',
    year: 'numeric',
  })
