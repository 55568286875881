import PropTypes from 'prop-types'
import { Card, Box, Typography, Skeleton } from '@mui/material'

import * as PT from '../../propTypes'
import styles from './articleCard.styles'
import PublishingDetail from './PublishingDetail'
import Category from './Category'
import GoToArticleFakeButton from './GoToArticleFakeButton'

const horizontalCardWithDescriptionPropTypes = {
  isHighlighted: PropTypes.bool,
  withoutImage: PropTypes.bool,
  withCategory: PropTypes.bool,
  article: PT.Article,
  isSkeleton: PropTypes.bool,
  isLoadingDetail: PropTypes.bool,
  onClick: PropTypes.func,
}
const HorizontalCardWithDescription = ({
  isHighlighted,
  withoutImage,
  withCategory,
  isSkeleton,
  isLoadingDetail,
  article,
  onClick,
}) => {
  return (
    <Card
      variant='plain'
      sx={{
        ...styles.card,
        ...styles.cardHorizontalAxis,
        py: 1.25,
        ...(isHighlighted ? styles.highlighted : styles.regular),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: 1,
        }}
      >
        <Box>
          {withCategory && (
            <Category
              category={article?.category}
              isLoading={isSkeleton || isLoadingDetail}
            />
          )}
          {isSkeleton ? (
            <Skeleton height={110} />
          ) : (
            <Typography
              variant={isHighlighted ? 'title1' : 'title3'}
              component='h6'
              sx={styles.title}
            >
              {article?.title}
            </Typography>
          )}
          {isSkeleton || isLoadingDetail ? (
            <Skeleton height={50} />
          ) : article?.description ? (
            <Typography variant='title4' component='p' sx={styles.description}>
              {article?.description}
            </Typography>
          ) : null}
        </Box>
        <Box sx={styles.cardHorizontalAxis}>
          <PublishingDetail
            article={article}
            isLoading={isSkeleton || isLoadingDetail}
          />
          {!isSkeleton && Boolean(onClick) && <GoToArticleFakeButton />}
        </Box>
      </Box>
      {withoutImage || (!Boolean(article?.image) && !isSkeleton) ? null : (
        <Box sx={styles.regularImg}>
          {isSkeleton || isLoadingDetail ? (
            <Skeleton
              variant='rectangular'
              height={'var(--image-size)'}
              width={'var(--image-size)'}
            />
          ) : (
            <img src={article?.image} alt='Imagen del artículo' />
          )}
        </Box>
      )}
    </Card>
  )
}
HorizontalCardWithDescription.propTypes = horizontalCardWithDescriptionPropTypes

const horizontalCardWithoutDescriptionPropTypes = {
  withoutImage: PropTypes.bool,
  withCategory: PropTypes.bool,
  article: PT.Article,
  isSkeleton: PropTypes.bool,
  isLoadingDetail: PropTypes.bool,
  onClick: PropTypes.func,
}
const HorizontalCardWithoutDescription = ({
  withoutImage,
  withCategory,
  isSkeleton,
  isLoadingDetail,
  article,
  onClick,
}) => {
  return (
    <Card variant='plain' sx={styles.card}>
      <Box sx={styles.regularArticleCardContent}>
        <Box sx={styles.cardHorizontalAxis}>
          <Box>
            {withCategory && (
              <Category
                category={article?.category}
                isLoading={isSkeleton || isLoadingDetail}
              />
            )}
            {isSkeleton ? (
              <Skeleton height={60} />
            ) : (
              <Typography
                variant='title4'
                component='h6'
                sx={{ ...styles.title, ...styles.clampedTitle }}
              >
                {article?.title}
              </Typography>
            )}
          </Box>
          {withoutImage || (!Boolean(article?.image) && !isSkeleton) ? null : (
            <Box sx={styles.regularImg}>
              {isSkeleton || isLoadingDetail ? (
                <Skeleton variant='rectangular' height={70} width={70} />
              ) : (
                <img src={article?.image} alt='Imagen del artículo' />
              )}
            </Box>
          )}
        </Box>
        {!isSkeleton && (
          <Box sx={styles.cardHorizontalAxis}>
            <PublishingDetail article={article} isLoading={isLoadingDetail} />
            {Boolean(onClick) && <GoToArticleFakeButton />}
          </Box>
        )}
      </Box>
    </Card>
  )
}
HorizontalCardWithoutDescription.propTypes =
  horizontalCardWithoutDescriptionPropTypes

const horizontalCardPropTypes = {
  isHighlighted: PropTypes.bool,
  withoutImage: PropTypes.bool,
  withCategory: PropTypes.bool,
  withDescription: PropTypes.bool,
  article: PT.Article,
  isSkeleton: PropTypes.bool,
  isLoadingDetail: PropTypes.bool,
  onClick: PropTypes.func,
}

const HorizontalCard = (props) => {
  const { withDescription, ...restProps } = props

  const Component = withDescription
    ? HorizontalCardWithDescription
    : HorizontalCardWithoutDescription
  return <Component {...restProps} />
}

HorizontalCard.propTypes = horizontalCardPropTypes

export default HorizontalCard
