import articleCardStyles from '../ArticleCard/articleCard.styles'

const styles = {
  variableTextAlign: {
    textAlign: {
      md: 'center',
    },
  },
  root: {
    px: articleCardStyles.card.px,
  },
  logos: {
    width: (theme) => `min(100%, ${theme.spacing(40)})`,
    mx: 'auto',
    px: (theme) => ({
      xs: theme.spacing(1),
      md: theme.spacing(3),
      lg: theme.spacing(8, 4, 9),
      xl: theme.spacing(8, 8.75, 9),
    }),
    pt: (theme) => ({
      xs: theme.spacing(1),
      md: theme.spacing(1.5),
    }),
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: (theme) => ({
      xs: theme.spacing(1),
      md: theme.spacing(2),
    }),
    rowGap: 1,
  },
}

export default styles
