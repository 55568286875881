import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'

import styles from './balanceData.styles.js'

const CardWrapperPropTypes = {
  shrink: PropTypes.bool,
  title: PropTypes.string,
  icon: PropTypes.node,
  children: PropTypes.node,
}

const CardWrapper = ({ shrink, title, icon, children }) => {
  return (
    <Box sx={{ ...styles.card, ...(shrink ? styles.shrink : null) }}>
      <Typography component='h2' sx={styles.header}>
        <Box sx={styles.icon}>{icon}</Box>
        <Typography component='span' sx={styles.title}>
          {title}
        </Typography>
      </Typography>
      <Box>{children}</Box>
    </Box>
  )
}

CardWrapper.propTypes = CardWrapperPropTypes

export default CardWrapper
