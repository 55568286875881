import ClashDisplayMedium from './fonts/ClashDisplay-Medium.woff2'
import ClashDisplaySemibold from './fonts/ClashDisplay-Semibold.woff2'
import InterBold from './fonts/Inter-Bold.woff2'
import InterRegular from './fonts/Inter-Regular.woff2'
import InterLight from './fonts/Inter-Light.woff2'
import InterMedium from './fonts/Inter-Medium.woff2'

const MuiCssBaseline = {
  styleOverrides: (theme) => `
    @font-face {
      font-family: 'ClashDisplay-Medium';
      src: local('Clash Display Medium'), local('Clash-Display-Medium'),
        url(${ClashDisplayMedium}) format('woff2');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }
    @font-face {
      font-family: 'ClashDisplay-Semibold';
      src: local('Clash Display Semibold'), local('Clash-Display-Semibold'),
        url(${ClashDisplaySemibold}) format('woff2');
      font-weight: 600;
      font-style: normal;
      font-display: swap;
    }
    @font-face {
      font-family: 'Inter';
      src: local('Inter Bold'), local('Inter-Bold'),
          url(${InterBold}) format('woff2');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
    }
    @font-face {
      font-family: 'Inter';
      src: local('Inter Regular'), local('Inter-Regular'),
        url(${InterRegular}) format('woff2');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }
    @font-face {
      font-family: 'Inter';
      src: local('Inter Light'), local('Inter-Light'),
        url(${InterLight}) format('woff2');
      font-weight: 300;
      font-style: normal;
      font-display: swap;
    }
    @font-face {
      font-family: 'Inter';
      src: local('Inter Medium'), local('Inter-Medium'),
        url(${InterMedium}) format('woff2');
      font-weight: 500;
      font-style: normal;
      font-display: swap;
    }
    #root {display: flex; flex-direction: column; min-height: 100vh}
    a:-webkit-any-link:focus-visible {outline-offset:0}
  `,
}

export default MuiCssBaseline
