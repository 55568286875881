import { createContext, useContext, useState, useEffect } from 'react'

import { getMediaProviders as getMediaProvidersService } from './service'

const MediaProvidersContext = createContext(null)

export const useMediaProviders = () => {
  const context = useContext(MediaProvidersContext)
  if (context === null) {
    throw new Error(
      'useMediaProviders must be used within a MediaProvidersProvider',
    )
  }
  return context
}

const initialState = {
  isLoading: true,
  data: undefined,
  error: false,
}

const MediaProvsProvider = ({ children }) => {
  const [state, setState] = useState(initialState)

  const fetchMediaProviders = async () => {
    try {
      const data = await getMediaProvidersService()
      setState({
        isLoading: false,
        data,
        error: false,
      })
    } catch (error) {
      setState({ isLoading: false, data: null, error })
    }
  }

  useEffect(() => {
    ;(async () => await fetchMediaProviders())()
  }, [])

  return (
    <MediaProvidersContext.Provider value={state}>
      {children}
    </MediaProvidersContext.Provider>
  )
}

export default MediaProvsProvider
