import { Box, Typography } from '@mui/material'

import { useTexts } from '../../../../texts/index.js'
import styles from './purchases.styles'

const View = ({ children }) => {
  const texts = useTexts()
  return (
    <Box sx={styles.root}>
      <Typography component='h2' sx={styles.sectionTitle}>
        {texts.getPurchasesLabel()}
      </Typography>
      {children}
    </Box>
  )
}

export default View
