import theme from '../../../theme'

const styles = {
  regular: {
    '--image-size': theme.spacing(5.875),
    '--title-mb': theme.spacing(0.25),
  },
  highlighted: {
    '--image-size': theme.spacing(13.25),
    '--title-mb': theme.spacing(0.5),
  },

  invisible: {
    display: {
      xs: 'none',
      md: 'inherit',
    },
    color: 'transparent',
  },

  cardActionArea: {
    '&:hover, &:focus, &:active, &:focus-visible, &:focus-within, &:visited, &:target':
      {
        backgroundColor: 'neutral.100',
        color: 'neutral.100',
        '& .fakeButton': {
          color: 'neutral.400',
        },
      },
  },
  fakeButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    '& > p': {
      mr: 0.3,
      mt: '2px',
      textDecoration: 'underline',
    },
  },

  card: {
    borderRadius: 0,
    width: '100%',

    // WARNING: This padding is synced with articles sections' titles padding.
    // Keep in mind that any updates here will affect these paddings (search for 'articleCardStyles.card.px' occurrencies)
    px: {
      xs: 1,
      md: 1.5,
    },

    backgroundColor: 'inherit',
    '&:hover': {
      backgroundColor: 'neutral.100',
    },
  },

  verticalCard: {
    flexDirection: 'column',
    py: (theme) => ({
      xs: theme.spacing(1),
      md: theme.spacing(2),
    }),
  },

  regularArticleCardContent: {
    py: 1.25,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 1,
  },

  cta: {
    my: (theme) => theme.spacing(1),
  },
  smallCta: {
    // p: (theme) => theme.spacing(0.5), -> this is not working in desktop due to size='small' overrides
    p: '8px !important',
    width: 130,
  },

  displayInRow: {
    display: 'flex',
    '& > div:first-of-type': {
      flexGrow: 2,
    },
  },

  cardHorizontalAxis: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 1,

    '& > div:first-of-type': {
      flexGrow: 2,
    },
  },

  highlightedImg: {
    height: 246,
    mb: (theme) => theme.spacing(1),

    '& > img': {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
    },
  },

  regularImg: {
    '& > img': {
      height: {
        xs: 70,
        md: 'var(--image-size)',
      },
      width: {
        xs: 70,
        md: 'var(--image-size)',
      },
      objectFit: 'cover',
    },
  },

  category: {
    pb: (theme) => theme.spacing(0.4),
  },

  title: {
    mb: (theme) => ({
      xs: theme.spacing(0.375),
      md: 'var(--title-mb)',
    }),
  },

  description: {
    mb: (theme) => theme.spacing(0.3125),
  },

  clampedTitle: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
  },

  publishingDetail: {
    color: 'neutral.400',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 0.5,

    '& > .separator': {
      width: 2,
      height: 2,
      borderRadius: '50%',
      backgroundColor: 'neutral.400',
    },
  },

  clampedText: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    overflow: 'hidden',
  },
}

export default styles
