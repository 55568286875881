import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Box, Button } from '@mui/material'

import { PayperLogo } from '../../../../styleguide/icons'
import styles from './header.styles'
export const PRIVATE_HEADER_NODE_ID = 'dashboard-header'

const ViewPropTypes = {
  renderActions: PropTypes.func.isRequired,
  renderBalanceCta: PropTypes.func.isRequired,
}

const HeaderContainer = ({ renderActions, renderBalanceCta }) => {
  return (
    <Box component='header' sx={styles.root} id={PRIVATE_HEADER_NODE_ID}>
      <Box sx={styles.toolbar}>
        <Box sx={styles.wrapLogo}>
          <Button sx={styles.logo} component={Link} to='/dashboard'>
            <PayperLogo aria-label='Payper logo' color='#121212' />
          </Button>
          {/* NOTE: Do not change the id 'header-top-up-btn'. It is referred from the Products-Fruits' onboarding. */}
          <Box id='header-top-up-btn' sx={styles.wrapCta}>
            {renderBalanceCta()}
          </Box>
        </Box>
        {renderActions()}
      </Box>
    </Box>
  )
}

HeaderContainer.propTypes = ViewPropTypes

export default HeaderContainer
