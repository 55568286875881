import { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  Button,
  Link as MUILink,
  Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

import { useTexts } from '../../../../../texts'

import {
  Alert,
  Checkbox,
  Decorator,
  FormSkinWrapper,
  HelperText,
  TextField,
} from '../../../index'

import ContinueButton from '../ContinueButton'

import PrivacyPolicySummary from '../../../PrivacyPolicySummary'
import { ReactComponent as EnvelopeSimple } from './EnvelopeSimple.svg'
import { ReactComponent as Eye } from './Eye.svg'
import { ReactComponent as EyeClosed } from './EyeClosed.svg'
import { ReactComponent as LockSimple } from './LockSimple.svg'
import { ReactComponent as UserCircle } from './UserCircle.svg'
import { ReactComponent as Warning } from './Warning.svg'
import styles from './userData.styles'

const registerViewProptypes = {
  formId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  showsAcceptAllLegalRequirementsCTA: PropTypes.bool,
  onClickAcceptAllLegalRequirements: PropTypes.func,
  ctaLabel: PropTypes.string,
  onChangeControlledLegalCheckbox: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  formValues: PropTypes.shape({
    name: PropTypes.string,
    familyName: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    conditionsAccepted: PropTypes.string,
    communicationsAccepted: PropTypes.string,
  }),
  formError: PropTypes.string,
  fieldErrors: PropTypes.shape({
    name: PropTypes.string,
    familyName: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    conditionsAccepted: PropTypes.string,
  }),
  onGoToLogin: PropTypes.func,
  renderSocialLogin: PropTypes.func,
}

const UserDataView = ({
  formId,
  showsAcceptAllLegalRequirementsCTA,
  onClickAcceptAllLegalRequirements,
  ctaLabel,
  onChangeControlledLegalCheckbox,
  onSubmit,
  isSubmitting,
  formValues,
  formError,
  fieldErrors,
  onGoToLogin,
  renderSocialLogin,
}) => {
  const texts = useTexts()

  const [showPassword, setShowPassword] = useState(false)
  const [showPrivacyPolicySummary, setShowPrivacyPolicySummary] =
    useState(false)

  return (
    <>
      <Typography>
        {texts.getGoToRegisterFromLogin1()}
        <MUILink onClick={onGoToLogin}>
          {texts.getGoToRegisterFromLogin2()}
        </MUILink>
      </Typography>

      <form id={formId} onSubmit={onSubmit} noValidate>
        <FormSkinWrapper skin='light' sx={styles.wrapFields}>
          <Box sx={styles.groupFields}>
            {/*
              NOTE: According to UI specs, we handle both errors in one
              place, so get rid of the built-in prop `helperText` the `TextField`
              component provides.
            */}
            <TextField
              id='name'
              name='name'
              label={texts.getNameLabel()}
              autoComplete='given-name'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <UserCircle />
                  </InputAdornment>
                ),
              }}
              error={Boolean(fieldErrors.name)}
              required
            />
            <TextField
              id='familyName'
              name='familyName'
              label={texts.getFamilyNameLabel()}
              autoComplete='family-name'
              error={Boolean(fieldErrors.familyName)}
              required
            />
            {fieldErrors.name || fieldErrors.familyName ? (
              <Box sx={styles.groupErrors}>
                <Warning />
                {fieldErrors.name || fieldErrors.familyName}
              </Box>
            ) : null}
          </Box>

          <TextField
            id='phone'
            name='phone'
            label={texts.getPhoneLabel()}
            autoComplete='tel'
            type='tel'
            helperText={fieldErrors.phone}
            error={Boolean(fieldErrors.phone)}
          />

          <TextField
            id='email'
            name='email'
            label={texts.getEmailLabel()}
            type='email'
            autoComplete='email'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <EnvelopeSimple />
                </InputAdornment>
              ),
            }}
            helperText={fieldErrors.email}
            error={Boolean(fieldErrors.email)}
            inputProps={{ inputMode: 'email' }}
            required
          />
          <TextField
            id='password'
            name='password'
            label={texts.getPasswordLabel()}
            type={showPassword ? 'text' : 'password'}
            autoComplete='current-password'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <LockSimple />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment
                  position='end'
                  onClick={() =>
                    setShowPassword((showPassword) => !showPassword)
                  }
                >
                  <IconButton aria-label={texts.getTogglePasswordAction()}>
                    {showPassword ? <Eye /> : <EyeClosed />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            helperText={fieldErrors.password}
            error={Boolean(fieldErrors.password)}
            required
          />

          <Box my={-0.5}>
            <HelperText text={texts.getRegisterPasswordRequirementsLabel()} />
          </Box>
          {Boolean(formError) && <Alert variant='error' text={formError} />}
          {showsAcceptAllLegalRequirementsCTA ? (
            <Button
              color='secondary'
              onClick={onClickAcceptAllLegalRequirements}
              sx={styles.cta}
            >
              {texts.getRegisterAcceptLegalAction()}
            </Button>
          ) : (
            <ContinueButton
              type='submit'
              loading={isSubmitting}
              disabled={isSubmitting}
              loadingIndicator={<CircularProgress size={16} />}
              sx={styles.cta}
            >
              {ctaLabel ?? texts.getRegisterContinueAction()}
            </ContinueButton>
          )}
          <Box id='legal-checks'>
            <Box sx={styles.collapsible}>
              <Checkbox
                skin='light'
                label={
                  <>
                    {texts.getLegalAgreementLabel1()}
                    <MUILink
                      href='https://www.bepayper.com/terminos-y-condiciones'
                      target='_blank'
                      rel='noreferrer'
                    >
                      {texts.getLegalAgreementTermsLabel()}
                    </MUILink>
                    {texts.getAndLabel()}
                    <MUILink
                      href='https://www.bepayper.com/politica-de-privacidad'
                      target='_blank'
                      rel='noreferrer'
                    >
                      {texts.getLegalAgreementPrivacyPolicyLabel()}
                    </MUILink>
                    {texts.getLegalAgreementLabel2()}
                  </>
                }
                name='conditionsAccepted'
                checked={Boolean(formValues.conditionsAccepted)}
                onChange={onChangeControlledLegalCheckbox}
                error={
                  fieldErrors.conditionsAccepted &&
                  texts.getErrorConditionsAccepted()
                }
                required
              />
              <IconButton
                aria-label={texts.getCloseAction()}
                onClick={() => setShowPrivacyPolicySummary((value) => !value)}
                sx={styles.collapsibleButton}
              >
                {showPrivacyPolicySummary ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </IconButton>
            </Box>
            {showPrivacyPolicySummary && <PrivacyPolicySummary />}
            <Checkbox
              sx={{ mt: 1 }}
              label={texts.getLegalAgreementCommunicationsLabel()}
              name='communicationsAccepted'
              checked={Boolean(formValues.communicationsAccepted)}
              onChange={onChangeControlledLegalCheckbox}
            />
          </Box>
        </FormSkinWrapper>
      </form>
      <Decorator />
      {renderSocialLogin()}
    </>
  )
}

UserDataView.propTypes = registerViewProptypes

export default UserDataView
