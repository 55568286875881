import { useState, useEffect } from 'react'

import { Session, URL_SEARCH_PARAMS_AUTH_KEYS } from '../../../../business'

const hasRedirectOnUnauthFlag = () =>
  typeof new URLSearchParams(window.location.search).get(
    URL_SEARCH_PARAMS_AUTH_KEYS.REDIRECT_ON_UNAUTH,
  ) === 'string'

const getInheritedSearchParams = () => {
  const currentSearchParams = new URLSearchParams(window.location.search)
  for (const key of Object.values(URL_SEARCH_PARAMS_AUTH_KEYS)) {
    currentSearchParams.delete(key)
  }
  return currentSearchParams
}

const mergeSearchParams = (...searchParamsList) => {
  const mergedParams = new URLSearchParams()
  searchParamsList.forEach((params) => {
    params.forEach((value, key) => {
      mergedParams.append(key, value)
    })
  })
  return mergedParams
}

export const autoRedirectWithInheritedSearchParamsCreator =
  (redirectOnUnauthUri) => () => {
    const redirectURL = new URL(redirectOnUnauthUri)
    const redirectUriSearchParams = new URLSearchParams(redirectURL.search)
    const inheritedSearchParams = getInheritedSearchParams()
    const mergedParams = mergeSearchParams(
      inheritedSearchParams,
      redirectUriSearchParams,
    )
    const newLocation = `${redirectURL.origin}${
      redirectURL.pathname ?? ''
    }?${mergedParams.toString()}`
    window.location = newLocation
  }

const useOnLoadSSO = (getDataForAnalytics, redirect, redirectOnUnauthUri) => {
  const [unauthenticationIsConfirmed, setUnauthenticationIsConfirmed] =
    useState(false)

  useEffect(() => {
    ;(async () => {
      const initializeDataForAnalytics = () =>
        document.dispatchEvent(
          new CustomEvent('ssoLoad', { detail: getDataForAnalytics() }),
        )

      let isAuthenticated = false
      if (Session.isAuthenticated()) {
        if (Session.hasValidAccessToken()) {
          isAuthenticated = true
        } else {
          const tokens = await Session.refreshSession()
          if (Boolean(tokens)) {
            isAuthenticated = true
          }
        }
      }

      if (isAuthenticated) {
        redirect()
      } else if (hasRedirectOnUnauthFlag()) {
        autoRedirectWithInheritedSearchParamsCreator(redirectOnUnauthUri)()
      } else {
        initializeDataForAnalytics()
        setUnauthenticationIsConfirmed(true)
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return unauthenticationIsConfirmed
}

export default useOnLoadSSO
