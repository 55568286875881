import { landscapeLayout } from '../../components/OnboardingLayout/onboardingLayout.styles'

const centeredContent = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

const styles = {
  leftSection_text: {
    mt: 1,
  },
  leftSection_actions: {
    mt: 3,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: (theme) => theme.spacing(11),
  },

  rightSection_content: {
    ...landscapeLayout,
    maxWidth: 'fit-content',

    pt: '128px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  pinExtensionGuide: {
    position: 'relative',
  },
  roundIndicator: {
    width: 32,
    height: 32,
    borderRadius: '50%',
    backgroundColor: 'primary.main',
    ...centeredContent,
    '& p': {
      fontWeight: 700,
    },
  },
  indicatorBorder: {
    width: 32,
    height: 32,
    border: 1,
    borderWidth: 4,
    borderColor: 'primary.main',
  },
  indicatorNumOne: {
    position: 'absolute',
    top: { sm: -45, xxxl: -36 },
    left: { sm: 191, xxxl: 244 },
    '& > div': {
      ...centeredContent,
      flexDirection: 'column',
    },
  },
  indicatorNumTwo: {
    position: 'absolute',
    top: { sm: 114, xxxl: 147 },
    left: { sm: 151, xxxl: 193 },
    '& > div': {
      ...centeredContent,
    },
  },
  fadeInElement: {
    opacity: 0,
    transition: 'opacity 600ms ease-in',
    transitionDelay: '500ms',
  },
  visible: {
    opacity: 1,
  },
}

export default styles
