import { useState } from 'react'
import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  Typography,
  Paper,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

import { useTexts } from '../../../../texts/index.js'
import {
  Alert,
  FormSkinWrapper,
  HelperText,
  TextField,
} from '../../../components/index.js'
import { ReactComponent as Eye } from '../../../../styleguide/icons/Eye.svg'
import { ReactComponent as EyeClosed } from '../../../../styleguide/icons/EyeClosed.svg'
import { ReactComponent as LockSimple } from '../../../../styleguide/icons/LockSimple.svg'
import styles from './updatePassword.styles.js'

const View = ({ formId, onSubmit, isSubmitting, formError, fieldErrors }) => {
  const texts = useTexts()
  const [showOldPassword, setShowOldPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)

  return (
    <Box sx={styles.root}>
      <Paper sx={styles.content}>
        <Typography component='h1' sx={styles.title}>
          {texts.getUpdatePasswordLabel()}
        </Typography>
        <form id={formId} onSubmit={onSubmit} noValidate>
          <FormSkinWrapper skin='light' sx={styles.wrapFields}>
            <TextField
              id='oldPassword'
              name='oldPassword'
              autoComplete='current-password'
              label={texts.getUpdatePersonalDataCurrentPasswordLabel()}
              type={showOldPassword ? 'text' : 'password'}
              helperText={fieldErrors.oldPassword}
              error={Boolean(fieldErrors.oldPassword)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <LockSimple />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    position='end'
                    onClick={() =>
                      setShowOldPassword((showOldPassword) => !showOldPassword)
                    }
                  >
                    <IconButton aria-label={texts.getTogglePasswordAction()}>
                      {showOldPassword ? <Eye /> : <EyeClosed />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              required
            />
            <TextField
              id='newPassword'
              name='newPassword'
              label={texts.getUpdatePersonalDataNewPasswordLabel()}
              type={showNewPassword ? 'text' : 'password'}
              helperText={fieldErrors.newPassword}
              error={Boolean(fieldErrors.newPassword)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <LockSimple />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    position='end'
                    onClick={() =>
                      setShowNewPassword((showPassword) => !showPassword)
                    }
                  >
                    <IconButton aria-label={texts.getTogglePasswordAction()}>
                      {showNewPassword ? <Eye /> : <EyeClosed />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              required
            />

            <Box mt={-0.5}>
              <HelperText text={texts.getRegisterPasswordRequirementsLabel()} />
            </Box>

            {Boolean(formError) ? (
              <Alert variant='error' text={formError} />
            ) : null}

            <Box mt={1}>
              <LoadingButton
                fullWidth
                color='secondary'
                type='submit'
                loading={isSubmitting}
                disabled={isSubmitting}
                loadingIndicator={<CircularProgress size={16} />}
              >
                {texts.getUpdatePasswordAction()}
              </LoadingButton>
            </Box>
          </FormSkinWrapper>
        </form>
      </Paper>
    </Box>
  )
}

export default View
